import React, { useEffect, useState } from 'react';
import { DefrostIcon, Freeze } from '../../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import ThemeButton from '../common/ThemeButton';
import CryptoWalletItem from './CryptoWalletItem';
import FreezeConfirmationModal from '../modals/FreezeConfirmationModal';
import apiRequest from '../../utils/api';
import { getCryptoBalances } from '../../utils/common';
import Loading from '../common/Loading';

const CryptoWalletsTab = () => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientWalletsBalances = useSelector(state => state.account.cryptoBalancesData);
    const clientData = useSelector(state => state.account.clientData);
    const email = localStorage.getItem('email');
    const dispatch = useDispatch();

    const [isFrozen, setIsFrozen] = useState(false);
    const [freezeModalOpen, setFreezeModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(false);

    useEffect(() => {
        setIsFrozen(clientWalletsBalances.every(balance => balance.isFreeze));
    }, [clientWalletsBalances]);

    const handleFreezeCurrency = async (currency, isFreeze) => {
        try {
            setBalanceLoading(true);
            const response = await apiRequest('/freeze-client-balances', 'POST', {
                clientId: clientData.clientId,
                currency,
                isFreeze,
                email,
                currencyType: 'crypto',
            });
            if (response.success) {
                await getCryptoBalances(dispatch, clientData?.clientId, email, setBalanceLoading);
            } else {
                message.error(response.error);
            }
            setBalanceLoading(false);
        } catch (error) {
            setBalanceLoading(false);
            console.error('Error while freezing currency:', error);
        }
    };

    const handleFreezeAllCurrencies = async () => {
        try {
            setLoading(true);
            const response = await apiRequest('/freeze-client-balances', 'POST', {
                clientId: clientData.clientId,
                isFreeze: !isFrozen,
                email,
                freezeAll: true,
                currencyType: 'crypto',
            });
            if (response.success) {
                await getCryptoBalances(dispatch, clientData?.clientId, email, setBalanceLoading);
            } else {
                message.error(response.error);
            }
            setFreezeModalOpen(false);
            setLoading(false);
        } catch (error) {
            setFreezeModalOpen(false);
            setLoading(false);
            console.error('Error while freezing currency:', error);
        }
    };

    const handleOpenFreezeModal = () => {
        setFreezeModalOpen(true);
    };

    return (
        <div className="lg:pt-8 bg-white">
            <div className="crypto-wallets mb-[24px]">
                <h1 className="text-lg font-semibold wallet-heading">
                    The accounts wallet balances
                </h1>

                <ThemeButton
                    action={handleOpenFreezeModal}
                    text={isFrozen ? 'Unfreeze All' : 'Freeze All'}
                    style="mr-3"
                    autoWidth={true}
                    icon={
                        <img
                            src={`${imageBaseUrl}${isFrozen ? DefrostIcon : Freeze}`}
                            alt={isFrozen ? 'Unfreeze' : 'Freeze'}
                            text={isFrozen ? 'Unfreeze' : 'Freeze'}
                            className="w-4 h-4"
                        />
                    }
                />
            </div>
            {balanceLoading ? (
                <div className="flex flex-col gap-5">
                    <Loading />
                </div>
            ) : (
                clientWalletsBalances.map(wallet => (
                    <CryptoWalletItem
                        key={wallet.currencyShortName}
                        wallet={wallet}
                        handleFreezeCurrency={handleFreezeCurrency}
                    />
                ))
            )}
            {freezeModalOpen && (
                <FreezeConfirmationModal
                    open={freezeModalOpen}
                    isFrozen={isFrozen}
                    handleClose={() => setFreezeModalOpen(false)}
                    freezeAllCurrencies={handleFreezeAllCurrencies}
                    loading={loading}
                />
            )}
        </div>
    );
};

export default CryptoWalletsTab;
