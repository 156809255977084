import React, { useMemo } from 'react';
import { Tabs, Tooltip } from 'antd';
import { cryptoDetailsNav } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { useCommonFunctions } from '../../utils/common';

const CryptoTab = () => {
    const { getUserOnboardingStatus } = useCommonFunctions();
    const isUserOnboarded = useSelector(state => getUserOnboardingStatus(state.account.clientData));
    const disabledKeys = useMemo(() => {
        return isUserOnboarded ? ["Fee's", 'Balances', 'Wallets'] : [];
    }, [isUserOnboarded]);

    const renderLabel = (key, label) =>
        disabledKeys.includes(label) ? (
            <Tooltip title={isUserOnboarded} placement="top">
                <span>{label}</span>
            </Tooltip>
        ) : (
            label
        );

    const tabItems = useMemo(
        () =>
            cryptoDetailsNav.map(({ key, label, children }) => ({
                key,
                label: renderLabel(key, label),
                children,
                disabled: disabledKeys.includes(label),
            })),
        [disabledKeys],
    );

    return (
        <div className="col-span-2 bg-white">
            <div className="pt-7 crypto-tab-tabs">
                <Tabs
                    defaultActiveKey="0"
                    items={tabItems}
                    className="transaction-detail crypto-tab"
                />
            </div>
        </div>
    );
};

export default CryptoTab;
