import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Edit } from '../../../utils/Images';
import { useSelector } from 'react-redux';
import { Input, Form, Select } from 'antd';
import apiRequest from '../../../utils/api';
const { TextArea } = Input;

function AccountPurpose({ accountPurposeData, clientEmail, accountType }) {
    const imageBaseUrl = useSelector(state => state.config?.image_base_url);
    const [isSave, setIsSave] = useState(false);
    const { Option } = Select;
    const email = localStorage.getItem('email');
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            accountPurpose: accountPurposeData?.accountPurpose || [],
            flowOfFunds: accountPurposeData?.flowOfFunds,
            sourceOfFunds: accountPurposeData?.sourceOfFunds,
        });
    }, [accountPurposeData]);
    const updateClientDetails = async () => {
        const formValues = form.getFieldValue();
        const body = {
            email,
            userEmail: clientEmail,
            clientEmail,
            accountType,
            accountPurpose: formValues.accountPurpose,
            flowOfFunds: formValues.flowOfFunds,
            sourceOfFunds: formValues.sourceOfFunds,
        };

        try {
            setIsLoading(true);
            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response) {
                setIsSave(false);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);

            console.error(error);
        }
    };

    const accountPurposeLabels = [
        { value: 'firstInbound', label: '1st party inbound payments' },
        { value: 'firstOutbound', label: '1st party outbound payments' },
        { value: 'thirdInbound', label: '3rd party inbound payments' },
        { value: 'thirdOutbound', label: '3rd party outbound payments' },
        { value: 'FX', label: 'FX' },
    ];

    const personalInfo = [
        {
            label: 'Account Purpose:',
            value: accountPurposeLabels
                .filter(item => accountPurposeData?.accountPurpose?.includes(item.value))
                .map(item => item.label)
                .join(', '),
            name: 'accountPurpose',
        },
        {
            label: 'Please explain the flow of funds in/out of your Juno Money:',
            value: accountPurposeData?.flowOfFunds,
            name: 'flowOfFunds',
        },
        {
            label: 'Please explain the Source of Funds that will be sent into your Juno Money account:',
            value: accountPurposeData?.sourceOfFunds,
            name: 'sourceOfFunds',
        },
    ];

    const onEditAccountPurpose = () => {
        isSave ? updateClientDetails() : setIsSave(prevStatus => !prevStatus);
    };

    return (
        <div className="p-4 py-0 md:px-8 md:pt-2 lg:p-0">
            <div className="flex items-center justify-between my-3">
                <h1 className="text-lg font-semibold">Account Purpose</h1>
                <Button
                    loading={isLoading}
                    onClick={onEditAccountPurpose}
                    icon={
                        !isSave && (
                            <img src={`${imageBaseUrl}${Edit}`} alt="" className="w-5 h-5 " />
                        )
                    }
                    className="mr-2 bg-[#F4F4F5] h-8 !min-w-10 !max-w-fit">
                    {isSave && 'Save'}
                </Button>
            </div>
            <Form form={form} layout="vertical" disabled={!isSave}>
                {personalInfo.map((item, index) => (
                    <div key={item.label}>
                        <h1 className="text-[#51525C] mt-5 mb-2">{item.label}</h1>
                        {index === 0 ? (
                            <Form.Item
                                name={item.name}
                                className="account-pupose-form"
                                formClassName="!mt-10 !mb-0 errorFields"
                                rules={[
                                    { required: true, message: 'Please select an account purpose' },
                                    {
                                        type: 'accountPurpose',
                                        message: 'Please select an account purpose',
                                    },
                                ]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select"
                                    placeholder="Select reason for opening account">
                                    {accountPurposeLabels.map(option => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            <Form.Item name={item.name}>
                                <TextArea
                                    rows={4}
                                    placeholder={item.label}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 5,
                                    }}
                                />
                            </Form.Item>
                        )}
                    </div>
                ))}
            </Form>
        </div>
    );
}

export default AccountPurpose;
