import { Skeleton, Spin } from 'antd';
import React from 'react';

export const LoadingSkeleton = () => {
    return <Skeleton className="bg-white p-5" active></Skeleton>;
};

export const LoadingAvatarSkeleton = () => {
    return <Skeleton avatar active paragraph={{ rows: 1 }} className="bg-white p-2 rounded " />;
};

export const LoadingParagraphSkeleton = () => {
    return (
        <div
            style={{ width: '100%' }}
            className="min-w-[208px] p-5 flex flex-col justify-start gap-6 rounded-xl border border-gray-200 bg-white mb-1">
            <Skeleton.Avatar size="large" active />

            <Skeleton
                active
                paragraph={{ rows: 2, width: ['50%', '75%'] }}
                className="mt-4"
                title={false}
            />
        </div>
    );
};
const Loading = () => {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <Spin size="large" />
        </div>
    );
};

export default Loading;
