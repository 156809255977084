import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Drawer, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ThemeButton from '../common/ThemeButton';

const transactionType = [
    { value: 'transactionChart', label: 'Transaction Chart', checked: true },
    { value: 'totalTransactions', label: 'Total Transactions', checked: true },
    { value: 'completed', label: 'Completed', checked: true },
    { value: 'processing', label: 'In progress', checked: true },
    { value: 'pending', label: 'Pending', checked: true },
    { value: 'rejected', label: 'Rejected', checked: true },
    { value: 'failed', label: 'Failed', checked: true },
    { value: 'flagged', label: 'Flagged Transactions', checked: true },
    { value: 'allTransactions', label: 'All Transactions', checked: true },
    { value: 'deposits', label: 'Deposits', checked: true, type: 'junomoney' },
    { value: 'withdrawals', label: 'Withdrawals', checked: true, type: 'junomoney' },
    { value: 'FX', label: 'FX', checked: true, type: 'junomoney' },
    { value: 'transfers', label: 'Transfers', checked: true, type: 'junomoney' },
    { value: "TPP's", label: "TPP's", checked: true, type: 'junomoney' },
    // { value: 'merchant', label: 'Merchant', checked: true, type: 'junomoney' },
    { value: 'buy', label: 'Buy', checked: true, type: 'junox' },
    { value: 'sell', label: 'Sell', checked: true, type: 'junox' },
    { value: 'exchange', label: 'Exchange', checked: true, type: 'junox' },
    { value: 'send', label: 'Send', checked: true, type: 'junox' },
    { value: 'receive', label: 'Receive', checked: true, type: 'junox' },
    { value: 'housekeeper', label: 'HouseKeeping Status', checked: true, type: 'junox' },
];

const accountType = [
    { value: 'accountChart', label: 'Account Chart', checked: true },
    { value: 'signupStatus', label: 'Sign-up status', checked: true },
    { value: 'registeredAccountStatus', label: 'Registered accounts status', checked: true },
    { value: 'amlStatus', label: 'AML Status', checked: true },
];

const SettingsDrawer = ({ onClose, open, setCheckedTransaction, tabKey, currencyType }) => {
    const [transactionList, setTransactionList] = useState(transactionType);
    const [accountList, setAccountList] = useState(accountType);

    useEffect(() => {
        setCheckedTransaction(transactionList);
    }, [transactionList]);

    const handleCheckboxChange = checkedList => {
        setTransactionList(
            transactionList.map(prev => {
                return {
                    ...prev,
                    checked: prev.value === checkedList.value ? !prev.checked : prev.checked,
                };
            }),
        );
        setAccountList(
            accountList.map(prev => {
                return {
                    ...prev,
                    checked: prev.value === checkedList.value ? !prev.checked : prev.checked,
                };
            }),
        );
        if (tabKey === 'transactions') {
            setCheckedTransaction(transactionList);
        } else if (tabKey === 'accounts') {
            setCheckedTransaction(accountList);
        }
    };

    const resetToDefault = () => {
        setTransactionList(transactionType);
        setAccountList(accountType);
        if (tabKey === 'transactions') {
            setCheckedTransaction(transactionType);
        } else if (tabKey === 'accounts') {
            setCheckedTransaction(accountType);
        }
    };

    const filteredTransactionList = transactionList.filter(
        transaction => !transaction.type || transaction.type === currencyType,
    );

    return (
        <Drawer
            className="transaction-settings"
            width={280}
            closable={false}
            title={<p className="text-lg font-semibold text-primary">Dashboard configuration</p>}
            extra={
                <Space>
                    <CloseOutlined onClick={onClose} className="text-2xl" />
                </Space>
            }
            onClose={onClose}
            open={open}>
            <div className="flex flex-col justify-between h-full">
                <div className="rounded-lg p-3 bg-white">
                    {tabKey === 'transactions' && (
                        <>
                            <p className="font-medium text-base">Transaction</p>
                            <div className="flex flex-col mt-4 gap-3">
                                {filteredTransactionList.map((transaction, index) => {
                                    return (
                                        <Checkbox
                                            key={index}
                                            onChange={() => handleCheckboxChange(transaction)}
                                            checked={transaction.checked}>
                                            <span className="font-medium text-darkGray">
                                                {transaction.label}
                                            </span>
                                        </Checkbox>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {tabKey === 'accounts' && (
                        <>
                            <p className="font-medium text-base">Accounts</p>
                            <div className="flex flex-col mt-4 gap-3">
                                {accountList.map((account, index) => (
                                    <Checkbox
                                        key={index}
                                        onChange={() => handleCheckboxChange(account)}
                                        checked={account.checked}>
                                        <span className="font-medium text-darkGray">
                                            {account.label}
                                        </span>
                                    </Checkbox>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className="p-6 pb-3 border-t border-[#d1d1d6]">
                    <ThemeButton
                        action={resetToDefault}
                        type="primary"
                        shape="round"
                        text="Reset to Default"
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default SettingsDrawer;
