import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ClockCircleOutlined,
    CloseOutlined,
    CheckOutlined,
    ExclamationCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import StatusSummaryCard from './StatusSummaryCard';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { transactions, statusCode, transactionTypes } from '../utils/constant';
import { useWindowWidth } from '../utils/common';
import apiRequest from '../utils/api';
import { useNavigate } from 'react-router-dom';
import Loading, { LoadingAvatarSkeleton, LoadingParagraphSkeleton } from './common/Loading';
import { Flag } from '../utils/Images';
import CardDesktop from './dashboard/CardDesktop';
import CardMobile from './dashboard/CardMobile';

function getStatus(code) {
    for (const [status, codes] of Object.entries(statusCode)) {
        if (codes.includes(code)) {
            return status;
        }
    }
    return 'N/A';
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const AllTransaction = ({ checkedTransaction, currencyType }) => {
    const [loading, setLoading] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState('All Transactions');
    const [transactionCounts, setTransactionCounts] = useState({
        all: 0,
        completed: 0,
        processing: 0,
        pending: 0,
        failed: 0,
        rejected: 0,
        flagged: 0,
    });
    const [unmatchedHousekeepingCount, setUnmatchedHousekeepingCount] = useState([]);
    const [transactionDetails, setTransactionDetails] = useState();
    const [category, setCategory] = useState();
    const [seriesData, setSeriesData] = useState([]);
    const windowWidth = useWindowWidth();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const dashboardFilters = useSelector(state => state.dashboardFilters);
    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getTransactionDetails = useCallback(async () => {
        const body = {
            email,
            whiteLabelId,
            startDate: dashboardFilters?.startDate,
            endDate: dashboardFilters?.endDate,
            accountType: dashboardFilters?.accountType,
        };

        const endpoint =
            currencyType === 'junomoney'
                ? '/get-dashboard-transaction-details'
                : '/get-crypto-dashboard-transaction-details';

        try {
            const response = await apiRequest(endpoint, 'POST', body);

            if (response.success && response.data) {
                const { allTransactionDetails, transactionType } = response.data;
                const result = {
                    allTransactions: {},
                    typeTransactions: {},
                };

                allTransactionDetails.forEach(item => {
                    if (item.currencyType === (currencyType === 'junomoney' ? 'fiat' : 'crypto')) {
                        const status = getStatus(item.statusCode);
                        if (!result.allTransactions[status]) {
                            result.allTransactions[status] = { count: 0, totalUSDAmount: 0 };
                        }
                        result.allTransactions[status].count += item.count;
                        result.allTransactions[status].totalUSDAmount += item.totalUSDAmount;
                    }
                });

                transactionType.forEach(item => {
                    if (item.currencyType === (currencyType === 'junomoney' ? 'fiat' : 'crypto')) {
                        const type = item.type;
                        const status = getStatus(item.statusCode);

                        if (!result.typeTransactions[type]) {
                            result.typeTransactions[type] = {};
                        }
                        if (!result.typeTransactions[type][status]) {
                            result.typeTransactions[type][status] = { count: 0, totalUSDAmount: 0 };
                        }
                        result.typeTransactions[type][status].count += item.count;
                        result.typeTransactions[type][status].totalUSDAmount += item.totalUSDAmount;
                    }
                });

                setTransactionDetails(result);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [dashboardFilters, currencyType]);

    const getTransactionGraph = useCallback(async () => {
        const body = {
            email,
            whiteLabelId,
            startDate: dashboardFilters?.startDate,
            endDate: dashboardFilters?.endDate,
            accountType: dashboardFilters?.accountType,
        };

        const endpoint =
            currencyType === 'junomoney'
                ? '/get-dashboard-transaction-graph'
                : '/get-crypto-dashboard-transaction-graph';

        try {
            const response = await apiRequest(endpoint, 'POST', body);
            if (response.success && response.data) {
                const { labels, dataSets } = response.data;

                const capitalizedDataSets = dataSets.map(item => ({
                    ...item,
                    name: capitalizeFirstLetter(item.name),
                }));

                setCategory(labels);
                setSeriesData(capitalizedDataSets);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [dashboardFilters, currencyType]);

    const getTransactionCounts = useCallback(async () => {
        const body = {
            email,
            whiteLabelId,
            startDate: dashboardFilters?.startDate,
            endDate: dashboardFilters?.endDate,
            accountType: dashboardFilters?.accountType,
        };

        const endpoint =
            currencyType === 'junomoney'
                ? '/get-dashboard-transaction-counts'
                : '/get-crypto-dashboard-transaction-counts';

        try {
            const response = await apiRequest(endpoint, 'POST', body);

            if (response.success && response.data) {
                const { totalTransactionCount, statusCounts, unMatchedHousekeepingCount } =
                    response.data;

                const counts = statusCounts.reduce(
                    (acc, { count, code, currencyType: itemCurrencyType }) => {
                        if (
                            itemCurrencyType === (currencyType === 'junomoney' ? 'fiat' : 'crypto')
                        ) {
                            acc.all += count;
                            for (const [status, codes] of Object.entries(statusCode)) {
                                if (codes.includes(code)) {
                                    acc[status] += count;
                                    break;
                                }
                            }
                        }
                        return acc;
                    },
                    {
                        all: 0,
                        processing: 0,
                        pending: 0,
                        completed: 0,
                        failed: 0,
                        rejected: 0,
                        amlHold: 0,
                    },
                );

                setTransactionCounts(prevCounts => ({
                    ...prevCounts,
                    ...counts,
                    total: totalTransactionCount,
                    flagged: counts?.amlHold,
                }));

                setUnmatchedHousekeepingCount(unMatchedHousekeepingCount);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [dashboardFilters, currencyType]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await Promise.all([
                getTransactionDetails(),
                getTransactionGraph(),
                getTransactionCounts(),
            ]);
            setLoading(false);
        };

        fetchData();
    }, [dashboardFilters, currencyType]);

    const transactionsCounts = [
        {
            id: 1,
            title: 'Total Transactions',
            value: 'totalTransactions',
            count: transactionCounts?.all,
            icon: (
                <img
                    alt="transactions"
                    src={`${imageBaseUrl}/adminUi/exchangeIcon.svg`}
                    className="w-[30px] h-[30px]"
                />
            ),
            color: '#475467',
        },
        {
            id: 2,
            title: 'Completed',
            value: 'completed',
            count: transactionCounts?.completed,
            icon: <CheckOutlined className="text-[30px]" />,
            color: '#039855',
        },
        {
            id: 3,
            title: 'Processing',
            value: 'processing',
            count: transactionCounts?.processing,
            icon: <ClockCircleOutlined className="text-[30px]" />,
            color: '#1570EF',
        },
        {
            id: 4,
            title: 'Pending',
            value: 'pending',
            count: transactionCounts?.pending,
            icon: <ExclamationCircleOutlined className="text-[30px]" />,
            color: '#F79009',
        },
        {
            id: 5,
            title: 'Rejected',
            value: 'rejected',
            count: transactionCounts?.rejected,
            icon: (
                <img
                    alt="transactions"
                    src={`${imageBaseUrl}/adminUi/rejected.svg`}
                    className="w-[30px] h-[30px]"
                />
            ),
            color: '#7A5AF8',
        },
        {
            id: 6,
            title: 'Failed',
            value: 'failed',
            count: transactionCounts?.failed,
            icon: <CloseOutlined className="text-[30px]" />,
            color: '#D92D20',
        },
        {
            id: 7,
            title: 'Flagged Transactions',
            value: 'flagged',
            count: transactionCounts?.flagged,
            icon: <img alt="flag" src={`${imageBaseUrl}${Flag}`} className="w-[30px] h-[30px]" />,
            color: '#912018',
        },
        {
            id: 8,
            title: 'Transaction Alerts',
            value: 'housekeeper',
            count: parseInt(unmatchedHousekeepingCount?.count || 0),
            icon: <WarningOutlined className="text-yellow-500 text-[30px]" />,
            color: '#EAB308',
        },
    ];

    const statusColors = transactionsCounts
        .filter(transaction => transaction.value !== 'totalTransactions')
        .reduce((acc, transaction) => {
            acc[transaction.value] = transaction.color;
            return acc;
        }, {});

    const options = useMemo(() => {
        const maxValue = Math.max(...seriesData.flatMap(series => series.data));
        const yAxisMax = Math.ceil(maxValue / 5) * 5; // Round up to nearest multiple of 5
        const tickAmount = 5;
        return {
            chart: {
                type: 'line',
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
            },
            colors: seriesData.map(series => statusColors[series.name.toLowerCase()] || '#000000'),
            xaxis: {
                categories: category,
                labels: {
                    show: true, // Show x-axis labels
                },
                title: {
                    text: 'Time',
                    offsetY: -15, // Adjust the vertical position
                },
            },
            yaxis: {
                min: 0,
                max: yAxisMax,
                tickAmount: tickAmount,
                labels: {
                    formatter: value => {
                        if (value >= 1000) {
                            return `${Math.floor(value / 1000)}k`;
                        }
                        return Math.floor(value);
                    },
                    show: true, // Show y-axis labels
                },
                title: {
                    text: 'Transaction Counts',
                    rotate: -90, // Rotate the label to be vertical
                    offsetX: 0, // Adjust the horizontal position
                },
            },
            stroke: {
                curve: 'smooth',
                width: 1, // Set line width to be thinner
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5,
                },
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
            },
        };
    }, [category, seriesData, statusColors]);

    const TransactionGraph = () => {
        const transactionChart = checkedTransaction?.find(
            item => item.value === 'transactionChart',
        );
        const transactionStatus = checkedTransaction
            ?.filter(item => item?.checked)
            ?.map(item => item?.value);

        const transactionData = transactionsCounts.filter(transaction =>
            transactionStatus?.includes(transaction?.value),
        );

        return (
            <>
                {transactionChart && transactionChart.checked && (
                    <div
                        className={`bg-white rounded-xl p-6 ${
                            transactionData?.length > 0 ? 'lg:basis-2/3' : 'w-full'
                        }`}>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                <p className="pb-6 text-lg font-semibold text-primary">
                                    Transactions
                                </p>
                                <div className="h-[100%]">
                                    {seriesData && (
                                        <ReactApexChart
                                            options={options}
                                            series={seriesData}
                                            type="line"
                                            height={windowWidth < 1024 ? 347 : 720}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };

    const TransactionStatus = () => {
        const transactionChart = checkedTransaction?.find(
            item => item.value === 'transactionChart',
        );
        const transactionStatus = checkedTransaction
            ?.filter(item => item?.checked)
            ?.map(item => item?.value);

        const transactionData = transactionsCounts.filter(transaction =>
            transactionStatus?.includes(transaction?.value),
        );

        const handleStatusCountCard = code => {
            const isFlagged = code.value === 'flagged';
            const isHousekeeper = code.value === 'housekeeper';

            navigate('/transactions', {
                state: {
                    transactionType: transactionTypes
                        .slice(
                            currencyType === 'junomoney' ? 0 : -5,
                            currencyType === 'junomoney' ? 5 : undefined,
                        )
                        .map(transaction => transaction.value),
                    transactionStatus: isFlagged ? ['amlHold'] : code.value,
                    dashboardFilters: dashboardFilters,
                    houseKeeperStatus:
                        isHousekeeper && unmatchedHousekeepingCount?.status ? ['Unmatched'] : null,
                },
            });
        };

        return (
            <>
                {windowWidth >= 1024 ? (
                    <div className="flex flex-row gap-[12px] cursor-pointer overflow-x-scroll mb-3 custom-Dashboard-scrollbar">
                        {transactionData.map((status, index) => (
                            <CardDesktop
                                key={index}
                                icon={status?.icon}
                                count={status?.count}
                                color={status?.color}
                                title={status?.title}
                                onClick={() => handleStatusCountCard(status)}
                                loading={loading}
                            />
                        ))}
                    </div>
                ) : (
                    <div
                        className={`grid grid-cols-1 md:grid-cols-2 gap-[8px] cursor-pointer justify-between mb-3 ${
                            transactionChart?.checked
                                ? transactionData?.length > 0 && 'lg:basis-1/3'
                                : 'w-full'
                        }`}>
                        {transactionData.map((status, index) => (
                            <CardMobile
                                key={index}
                                icon={status?.icon}
                                count={status?.count}
                                color={status?.color}
                                title={status?.title}
                                onClick={() => handleStatusCountCard(status)}
                                loading={loading}
                            />
                        ))}
                    </div>
                )}
            </>
        );
    };

    const TransactionDashboard = () => {
        const transactionAllType = checkedTransaction
            ?.filter(item => !item.type || item.type === currencyType)
            ?.filter(item => item?.checked)
            ?.map(item => item?.label);

        const transactionData = transactions.filter(transaction =>
            transactionAllType?.includes(transaction?.label),
        );

        return (
            <>
                <div className="flex gap-2 mt-6 lg:hidden overflow-x-auto">
                    {transactionData
                        .slice(-6)
                        .sort((a, b) => a.key - b.key)
                        .map((transaction, index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedTransaction(transaction?.label)}
                                className={`px-[8px] py-[6px] min-w-fit w-full ${
                                    transaction?.label === selectedTransaction
                                        ? 'border-b-2 border-primary bg-[#E4E4E7]'
                                        : ''
                                } rounded-md text-primary `}>
                                {transaction?.label}
                            </button>
                        ))}
                </div>
                <div className="mt-6 grid  lg:grid-cols-3 gap-3">
                    {transactionData
                        .filter(trans =>
                            windowWidth < 1024
                                ? trans?.label === selectedTransaction ||
                                  selectedTransaction === 'All Transactions'
                                : true,
                        )

                        .map((transaction, key) => (
                            <div key={key}>
                                <StatusSummaryCard
                                    key={key}
                                    title={transaction}
                                    data={
                                        transaction?.label === 'All Transactions'
                                            ? transactionDetails?.allTransactions
                                            : transactionDetails?.typeTransactions[
                                                  transaction?.value
                                              ] || {}
                                    }
                                    dashboardFilters={dashboardFilters}
                                    loading={loading}
                                    currencyType={currencyType}
                                />
                            </div>
                        ))}
                </div>
            </>
        );
    };

    return (
        <>
            <TransactionStatus />
            <TransactionGraph />
            <TransactionDashboard />
        </>
    );
};

export default AllTransaction;
