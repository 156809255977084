import React, { useEffect, useState } from 'react';
import { FormInput, FormPassword, FormSelect } from '../forms';
import { Form } from 'antd';
import { getCountries } from '../../utils/common';
import apiRequest from '../../utils/api';

const RegistrationBusinessDetailsForm = ({ form, isEdit }) => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [formValue, setFormValue] = useState();
    const email = localStorage.getItem('email');
    const [whiteLabelData, setWhiteLabelData] = useState([]);

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const handleChange = value => {
        form.setFieldsValue({ country: value });
        setSelectedCountry(value);
    };
    const handleSelectChange = (fieldName, value) => {
        setFormValue(prevState => {
            const updatedState = {
                ...prevState,
                [fieldName]: value,
            };

            form.setFieldsValue({ [fieldName]: value });
        });
    };
    const getWhiteLableList = async () => {
        const body = {
            email,
            filterParams: {
                limit: 100,
            },
        };

        try {
            const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);

            if (response?.success || response?.data) {
                const options = response.data?.whitelabelList?.map(
                    ({ whitelableName, whitelabelId }) => ({
                        label: whitelableName,
                        value: whitelabelId,
                    }),
                );
                setWhiteLabelData([{ label: 'None', value: 'none' }, ...options]);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getWhiteLableList();
    }, []);

    return (
        <div>
            <Form
                form={form}
                layout="horizontal"
                colon={false}
                className="h-[75vh] px-[7px] overflow-y-auto hide-scrollbar modal-form registeration-form">
                <Form.Item name="clientEmail">
                    <FormInput
                        label="Business email:"
                        name="clientEmail"
                        formClassName="!mt-10 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Business Email is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address!',
                            },
                        ]}
                        placeholder="Enter business email"
                        dataE2e="enter-email"
                        type="email"
                    />
                </Form.Item>

                <Form.Item name="fullLegalEntityName">
                    <FormInput
                        label="Full legal entity name:"
                        name="fullLegalEntityName"
                        placeholder="Enter business name"
                        dataE2e="enter-name"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(
                                            '* Full legal entity name is required',
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="businessRepresentativeName">
                    <FormInput
                        label="Business representatives name:"
                        name="businessRepresentativeName"
                        placeholder="Enter first and last name"
                        dataE2e="enter-name"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(
                                            '* Business representatives name is required',
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="businessWebsite">
                    <FormInput
                        label="Business website:"
                        name="businessWebsite"
                        placeholder="Business website"
                        dataE2e="enter-name"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Business Website is required');
                                    }

                                    let urlString = value.trim();

                                    // If the user enters 'www.google.com', prepend 'https://'
                                    if (
                                        !urlString.startsWith('http://') &&
                                        !urlString.startsWith('https://')
                                    ) {
                                        urlString = `https://${urlString}`;
                                    }

                                    try {
                                        const url = new URL(urlString);
                                        const validProtocols = ['http:', 'https:'];
                                        if (!validProtocols.includes(url.protocol)) {
                                            return Promise.reject(
                                                '* Website must start with http:// or https://',
                                            );
                                        }
                                        if (!url.hostname.includes('.')) {
                                            return Promise.reject(
                                                '* Please enter a valid domain name',
                                            );
                                        }
                                        return Promise.resolve();
                                    } catch {
                                        return Promise.reject('* Please enter a valid website URL');
                                    }
                                },
                            },
                        ]}
                    />
                </Form.Item>

                <FormSelect
                    label="Country of incorporation:"
                    name="country"
                    dataE2e="enter-country"
                    formClassName="!my-8 !mb-4 errorFields"
                    placeholder={<span>Select country of incorporation</span>}
                    options={[
                        { value: '', label: 'Select country of incorporation' },
                        ...countries.map((country, key) => ({
                            value: country.name,
                            label: country.name,
                            key: key,
                        })),
                    ]}
                    required={true}
                    rules={[
                        {
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject('* Country of incorporation is required');
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}
                    value={selectedCountry || form.getFieldValue('country')}
                    onChange={handleChange}
                />

                {!isEdit && (
                    <Form.Item name="password">
                        <FormPassword
                            label="Password:"
                            name="password"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value || value.trim() === '') {
                                            return Promise.reject('* Please enter a Password');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                },
                            ]}
                            placeholder="Create Password"
                            dataE2e="create-password"
                            formClassName="!mt-4 !mb-0 errorFields"
                            className="registeration-form"
                        />
                    </Form.Item>
                )}
                <Form.Item name="address">
                    <FormInput
                        label="Personal Address Line:"
                        name="address"
                        placeholder="Enter Personal Address"
                        dataE2e="enter-address"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(
                                            '* Personal Address Line is required',
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="city">
                    <FormInput
                        label="City:"
                        name="city"
                        placeholder="Enter City"
                        dataE2e="enter-city"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* City is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^[A-Za-z\s]*$/,
                                message: 'City must contain only Latin letters and spaces',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="state">
                    <FormInput
                        label="State:"
                        name="state"
                        placeholder="Enter State"
                        dataE2e="enter-state"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* State is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^[A-Za-z\s]*$/,
                                message: 'State must contain only Latin letters and spaces',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="postCode">
                    <FormInput
                        label="ZIP / Postal Code:"
                        name="postCode"
                        placeholder="Enter Post Code"
                        dataE2e="enter-post-code"
                        formClassName="!mt-5 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Postal Code is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^[A-Za-z0-9\s-]+$/,
                                message: '* ZIP / Postal Code must contain only numbers',
                            },
                        ]}
                    />
                </Form.Item>
                {isEdit && (
                    <>
                        <FormSelect
                            label="Whitelabel:"
                            name="whiteLabelId"
                            placeholder="Select Whitelabel"
                            options={whiteLabelData?.map((item, key) => ({
                                value: item?.value,
                                label: item?.label,
                                key: key,
                            }))}
                            defaultValue={form?.getFieldValue('whiteLabelId')}
                            onChange={value => handleSelectChange('whiteLabelId', value)}
                        />
                        <FormSelect
                            label="Status:"
                            name="status"
                            placeholder="Select Status"
                            options={[
                                { value: true, label: 'Active' },
                                { value: false, label: 'Inactive' },
                            ]}
                            defaultValue={form?.getFieldValue('status')}
                            onChange={value => handleSelectChange('status', value)}
                        />
                    </>
                )}
            </Form>
        </div>
    );
};

export default RegistrationBusinessDetailsForm;
