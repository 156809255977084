import React, { useState, useEffect } from 'react';
import { Divider, Tabs, DatePicker, Select, Badge } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import Sidebar from '../components/layout/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import SettingsDrawer from '../components/layout/SettingsDrawer';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { setDashboardFilters } from '../redux/actions/dashboardFiltersAction';
import moment from 'moment';
import { getDate } from '../utils/common';
import AllTransaction from '../components/AllTransaction';
import DashboardAccounts from '../components/DashboardAccounts';
import ThemeButton from '../components/common/ThemeButton';
import { Filter, MobileFilter } from '../utils/Images';
import DashboardFilter from '../components/layout/DashboardFilter';

dayjs.extend(customParseFormat);

const Dashboard = () => {
    const [openSettings, setOpenSettings] = useState(false);
    const [tabKey, setTabKey] = useState('accounts');
    const [checkedTransaction, setCheckedTransaction] = useState();
    const [filterModal, setFilterModal] = useState(false);
    const [currencyType, setCurrencyType] = useState('junomoney');
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [badgeCount, setBadgeCount] = useState(0);

    const now = moment.utc();
    const image_base_url = useSelector(state => state.config.image_base_url);
    const dispatch = useDispatch();
    const dashboardFilters = useSelector(state => state.dashboardFilters);

    useEffect(() => {
        if (dashboardFilters.accountType && dashboardFilters.selectedDateRange) {
            setBadgeCount(2);
        } else if (dashboardFilters.accountType) {
            setBadgeCount(1);
        } else if (dashboardFilters.selectedDateRange) {
            setBadgeCount(1);
        } else {
            setBadgeCount(0);
        }
    }, [dashboardFilters]);

    const items = [
        {
            key: 'accounts',
            label: 'Accounts',
            children: <DashboardAccounts />,
        },
        {
            key: 'transactions',
            label: 'Transactions',
            children: (
                <AllTransaction
                    checkedTransaction={checkedTransaction}
                    currencyType={currencyType}
                />
            ),
        },
    ];

    const dateItems = [
        { label: 'All Time', key: 'all' },
        { label: '6M', key: '6M' },
        { label: '3M', key: '3M' },
        { label: '2W', key: '2W' },
        { label: '1D', key: '1D' },
    ];

    const handleDateFilter = item => {
        const formattedDates = getDate(item.key);

        dispatch(
            setDashboardFilters({
                date: [item.key],
                startDate: formattedDates,
                endDate: now.format('YYYY-MM-DDTHH:mm:ssZ'),
                accountType: dashboardFilters?.accountType,
                selectedDateRange: null,
            }),
        );
    };

    const onChangeTab = tab => {
        setTabKey(tab);
    };

    const DashboardHeader = () => {
        return (
            <div className="dashboard-header flex border-b border-[#D1D1D6] justify-between">
                <div className="flex items-center gap-8">
                    <p className="main-page-title">Dashboard</p>
                    <div className="border border-red-[#D1D1D6] h-full" />
                    <Select
                        value={currencyType}
                        onChange={val => setCurrencyType(val)}
                        defaultValue="junomoney"
                        className="w-44"
                        options={[
                            { value: 'junomoney', label: 'Juno Money' },
                            { value: 'junox', label: 'Juno X' },
                        ]}
                    />
                </div>
                <div className="flex gap-[12px] items-center">
                    <div className="lg:flex gap-[8px] hidden">
                        {dateItems.map(item => (
                            <div
                                key={item.key}
                                className={`p-[8px] flex items-center justify-center cursor-pointer hover:bg-[#E4E4E7] hover:rounded-lg ${
                                    dashboardFilters.date.includes(item.key)
                                        ? 'border-b-2 border-primary bg-[#D1D1D6] rounded-lg'
                                        : ''
                                }`}
                                onClick={() => handleDateFilter(item)}>
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Sidebar>
                <DashboardHeader />
                <div className="dashboard-tabs">
                    <Tabs
                        defaultActiveKey="accounts"
                        tabBarExtraContent={
                            <div className="flex gap-4">
                                <Badge
                                    count={badgeCount}
                                    className="cursor-pointer hidden lg:block">
                                    <ThemeButton
                                        icon={
                                            <img
                                                src={`${imageBaseUrl}${Filter}`}
                                                alt=""
                                                className="w-3 h-3 inline cursor-pointer"
                                            />
                                        }
                                        text="Filters"
                                        dataE2e="filters"
                                        action={() => setFilterModal(true)}
                                    />
                                </Badge>
                                <img
                                    src={`${imageBaseUrl}${MobileFilter}`}
                                    className="w-4 h-3 cursor-pointer lg:hidden self-center"
                                    onClick={() => setFilterModal(true)}
                                    alt="mobile-filter"
                                />
                                {tabKey !== 'accounts' && (
                                    <SettingFilled
                                        onClick={() => setOpenSettings(true)}
                                        className="text-xl"
                                    />
                                )}
                            </div>
                        }
                        items={items}
                        onChange={onChangeTab}
                    />
                </div>
                <SettingsDrawer
                    open={openSettings}
                    onClose={() => setOpenSettings(false)}
                    setCheckedTransaction={setCheckedTransaction}
                    tabKey={tabKey}
                    currencyType={currencyType}
                />
                <DashboardFilter filterModal={filterModal} setFilterModal={setFilterModal} />
            </Sidebar>
        </>
    );
};

export default Dashboard;
