import { Modal, Button } from 'antd';
import React from 'react';
import ThemeButton from '../common/ThemeButton';

const CommonModal = ({
    title,
    open,
    handleClose,
    children,
    footerText,
    onFinish,
    error,
    disabled,
    loading,
    form,
    footerContent,
    ...rest
}) => {
    const handleClick = async () => {
        try {
            if (form) {
                await form.validateFields();
                await onFinish();
            } else {
                await onFinish();
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <Modal
            title={<p className="text-2xl font-semibold relative">{title}</p>}
            open={open}
            destroyOnClose
            centered
            footer={null}
            width={570}
            onCancel={() => handleClose()}
            {...rest}>
            <div className="h-[70vh] lg:h-auto overflow-auto hide-scrollbar px-[7px] common-model-body">
                {children}
            </div>
            {error && <div className="text-red-500">{error}</div>}
            {(footerText || footerContent) && (
                <div className="flex flex-col mt-6 text-end">
                    <div className="mt-2 sm:w-max sm:ml-auto and-btn-min-width flex">
                        {footerContent && footerContent}
                        {footerText && (
                            <ThemeButton
                                loading={loading}
                                dataE2e={footerText}
                                action={() => handleClick()}
                                disabled={disabled}
                                text={footerText}
                                type="primary"
                                shape="round"
                                size="large"
                            />
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default CommonModal;
