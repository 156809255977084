import { Button, Modal, Spin } from 'antd';
import {
    getCryptoFee,
    getCurrencySymbol,
    toFixedTrunc,
    validateInputAmount,
} from '../../utils/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CryptoSelectModal } from './CryptoSelectModal';
import TransactionResultModal from './TransactionResultModal';
import { LoadingOutlined } from '@ant-design/icons';

export const ReceiveCryptoModal = ({ open, handleClose, onSubmit, client }) => {
    const [unfreezeBalances, setUnfreezeBalances] = useState([]);
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const [transactionFee, setTransactionFee] = useState(0);
    const email = localStorage.getItem('email');
    const [fee, setFee] = useState(0);
    const [feeLoader, setFeeLoader] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        cryptoAmount: null,
        cryptoId: null,
        confirmedTxHash: null,
    });
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        const filteredBalances = balancesCrypto.filter(balance => !balance.isFreeze);
        setUnfreezeBalances(filteredBalances);
        setFormData(prevState => ({
            ...prevState,
            cryptoId: prevState?.cryptoId || filteredBalances[0]?.currencyShortName,
        }));
    }, [balancesCrypto]);

    const fetchData = async () => {
        if (!formData.cryptoId || !open) return;
        setFeeLoader(true);
        const payload = {
            email,
            crypto: formData?.cryptoId,
            profile: client?.feeProfile,
            transactionType: 'Receive',
            clientId: client?.clientId,
        };
        try {
            const cryptoFee = await getCryptoFee(payload);
            setFee(!cryptoFee?.error ? cryptoFee?.data?.fee / 100 : 0);
        } catch (error) {
            console.error('Failed to fetch crypto fee:', error);
        } finally {
            setFeeLoader(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [formData.cryptoId, open]);

    const createTransaction = async () => {
        setLoading(true);
        try {
            const blockchain = balancesCrypto.find(
                crypto => crypto.currencyShortName === formData.cryptoId,
            )?.blockchain;

            const body = {
                currencyType: 'crypto',
                type: 'Receive',
                clientId: client?.clientId,
                email,
                transactionEmail: localStorage.getItem('user_name'),
                transactionFee: transactionFee && parseFloat(transactionFee).toFixed(6),
                transactionDetails: {
                    cryptoId: formData.cryptoId,
                    blockchain,
                    cryptoAmount: formData.cryptoAmount,
                    confirmedTxHash: formData.confirmedTxHash,
                    totalAmount,
                },
            };
            const response = await onSubmit(body);
            if (response.success) {
                setStep(2);
            } else if (response?.error) {
                setError(response?.error);
                setStep(2);
            }
        } catch (error) {
            setError(error.message || 'An error occurred while creating the transaction');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (formData) {
            const transactionFee = formData.cryptoAmount * parseFloat(fee);
            const totalAmount = formData.cryptoAmount - transactionFee;
            setTransactionFee(toFixedTrunc(transactionFee, 8));
            setTotalAmount(parseFloat(totalAmount.toFixed(8)));
        }
    }, [formData, fee]);

    const onCancel = () => {
        setLoading(false);
        setFormData({
            cryptoAmount: null,
            cryptoId: null,
        });
        setTransactionFee(0);
        handleClose();
    };

    const handleCryptoAmount = e => {
        const value = validateInputAmount(e.target.value);
        setFormData({ ...formData, cryptoAmount: value });
    };

    return (
        <>
            {step === 1 && (
                <Modal
                    open={open}
                    destroyOnClose
                    centered
                    width={570}
                    className="sell-crypto-modal common-mobile-view"
                    title={'Receive Crypto'}
                    onCancel={handleClose}
                    footer={
                        <div className="flex justify-end w-full">
                            <Button
                                key="cancel"
                                onClick={() => {
                                    onCancel();
                                }}
                                className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                                Cancel
                            </Button>
                            <Button
                                key="confirm"
                                loading={loading}
                                disabled={
                                    formData.cryptoAmount === '' ||
                                    formData.cryptoAmount <= 0 ||
                                    loading
                                }
                                type="primary"
                                onClick={() => {
                                    createTransaction();
                                }}
                                className="rounded-full px-8 py-3 w-full sm:!w-[140px] h-[46px]">
                                {loading ? 'Processing' : 'Confirm'}
                            </Button>
                        </div>
                    }
                    handleClose={handleClose}>
                    <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">Crypto:</h1>
                        <div className="col-span-2">
                            <CryptoSelectModal
                                value={formData.cryptoId}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        cryptoId: e,
                                    })
                                }
                                options={unfreezeBalances || []}
                                className="w-full !rounded-l-3xl max-sm:!w-full"
                            />
                        </div>
                    </div>
                    <div className="mb-[30px] grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">Amount:</h1>
                        <div className="col-span-2">
                            <input
                                type="text"
                                placeholder="Enter Wallet Amount"
                                onChange={e => handleCryptoAmount(e)}
                                data-e2e="from-wallet"
                                value={formData.cryptoAmount}
                                className="bg-input rounded-3xl mr-1 py-[16px] pl-5 max-sm:!w-full w-full"
                            />
                        </div>
                    </div>
                    <div className="mb-[30px] grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Confirmation TX Hash:
                        </h1>
                        <div className="col-span-2">
                            <input
                                type="text"
                                data-e2e="confirm-hash"
                                placeholder="Enter Confirmation TX Hash"
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        confirmedTxHash: e.target.value,
                                    })
                                }
                                value={formData.confirmedTxHash}
                                className="bg-input rounded-3xl mr-1 py-[16px] pl-5 max-sm:!w-full w-full"
                            />
                        </div>
                    </div>
                    <div className="mb-[30px] flex justify-between items-center">
                        <label className="text-base font-normal text-start">Fee:</label>
                        <div className="">
                            {feeLoader && (
                                <Spin indicator={<LoadingOutlined spin />} size="small" />
                            )}
                            <span className="text-base font-semibold">{fee * 100}%</span>
                        </div>
                    </div>
                    <hr className="mb-[30px]"></hr>
                    <div className="mb-8 flex justify-between items-center">
                        <label
                            className="text-lg font-normal text-start"
                            data-e2e={totalAmount || 0}>
                            Total:
                        </label>
                        <span className="text-lg font-semibold">
                            {getCurrencySymbol(formData.cryptoId)} {totalAmount || 0} (
                            {formData.cryptoId})
                        </span>
                    </div>
                </Modal>
            )}
            <TransactionResultModal
                type={error ? error : 'success'}
                open={open && step === 2}
                title={error ? 'Error!' : 'Receive Confirmed!'}
                handleClick={() => {
                    handleClose();
                }}
                message={error ? 'Please contact Support' : 'Has been sent successfully!'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {getCurrencySymbol(formData.cryptoId)} {totalAmount || 0} (
                            {formData.cryptoId})
                        </p>
                    </>
                )}
            </TransactionResultModal>
        </>
    );
};
