import { SET_COUNTRIES, SET_SETTINGS } from './actionTypes';

export const setSettings = value => ({
    type: SET_SETTINGS,
    payload: value,
});

export const setCountries = value => ({
    type: SET_COUNTRIES,
    payload: value,
});
