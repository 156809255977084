import { constant } from '../../utils/constant';
import { SET_COUNTRIES, SET_SETTINGS } from '../actions/actionTypes';

const initialState = {
    maintenance: false,
    complyAdvantage: null,
    countries: [],
    sumsub: false,
};

function setCountries(state = initialState, action) {
    return {
        ...state,
        countries: action.payload?.allCountries,
    };
}

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS: {
            const settings = action.payload || [];

            const getSettingValue = key =>
                settings?.find(({ settingName }) => settingName === key)?.value ?? null;

            return {
                ...state,
                maintenance: getSettingValue(constant.SETTINGS.MAINTENANCE),
                complyAdvantage: getSettingValue(constant.SETTINGS.COMPLY_ADVANTAGE),
                sumsub: getSettingValue(constant.SETTINGS.SUMSUB),
            };
        }
        case SET_COUNTRIES:
            return setCountries(state, action);

        default:
            return state;
    }
};

export default settingsReducer;
