import { useState, useLayoutEffect, useEffect, useMemo, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import AccountsSummaryCard from './AccountsSummaryCard';
import { useSelector } from 'react-redux';
import apiRequest from '../utils/api';
import Loading, { LoadingAvatarSkeleton } from './common/Loading';
import { CloseOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useWindowWidth } from '../utils/common';
import CardMobile from './dashboard/CardMobile';
import CardDesktop from './dashboard/CardDesktop';

const DashboardAccounts = () => {
    const windowWidth = useWindowWidth();
    const navigate = useNavigate();
    const [seriesData, setSeriesData] = useState([]);
    const [category, setCategory] = useState();
    const [accountCounts, setAccountCounts] = useState({
        all: 0,
        rejected: 0,
        approved: 0,
        onboarding: 0,
        awaitingApproval: 0,
    });
    const [loading, setLoading] = useState(false);
    const dashboardFilters = useSelector(state => state.dashboardFilters);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');

    const getAccountsGraph = useCallback(async () => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        const body = {
            email,
            whiteLabelId,
            startDate: dashboardFilters?.startDate,
            endDate: dashboardFilters?.endDate,
            accountType: dashboardFilters?.accountType,
        };

        const endpoint = '/get-dashboard-accounts-graph';

        try {
            const response = await apiRequest(endpoint, 'POST', body);
            if (response.success && response.data) {
                const { labels, dataSets } = response.data;

                const capitalizedDataSets = dataSets.map(item => ({
                    ...item,
                    name: capitalizeFirstLetter(item.name || 'null'),
                }));

                setCategory(labels);
                setSeriesData(capitalizedDataSets);
            }
        } catch (error) {
            console.error(error);
        }
    }, [dashboardFilters]);

    const getAccountCounts = useCallback(async () => {
        const body = {
            email,
            startDate: dashboardFilters?.startDate,
            endDate: dashboardFilters?.endDate,
            accountType: dashboardFilters?.accountType,
        };
        const endpoint = '/get-dashboard-account-counts';
        try {
            const response = await apiRequest(endpoint, 'POST', body);

            if (response.success && response.data) {
                const { onboardingStatusCounts } = response.data;
                const statusMap = onboardingStatusCounts.reduce(
                    (acc, item) => {
                        acc.all = (acc.all || 0) + item.count;
                        if (item?.onboardingStatus) acc[item.onboardingStatus] = item.count || 0;
                        return acc;
                    },
                    { all: 0 },
                );

                setAccountCounts({
                    ...accountCounts,
                    all: statusMap['all'] || 0,
                    approved: statusMap['Approved'] || 0,
                    onboarding: statusMap['Onboarding'] || 0,
                    rejected: statusMap['Rejected'] || 0,
                    awaitingApproval: statusMap['AwaitingApproval'] || 0,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [dashboardFilters]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await Promise.all([getAccountsGraph(), getAccountCounts()]);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dashboardFilters]);

    const accountCountsData = [
        {
            id: 1,
            title: 'Total Accounts',
            value: 'totalAccounts',
            count: accountCounts?.all,
            icon: (
                <img
                    alt="totalAccounts"
                    src={`${imageBaseUrl}/adminUi/exchangeIcon.svg`}
                    className="w-[30px] h-[30px]"
                />
            ),
            color: '#475467',
        },
        {
            id: 2,
            title: 'Onboarding',
            value: 'onboarding',
            count: accountCounts?.onboarding,
            color: '#0091FF',
            icon: (
                <img
                    alt="transactions"
                    src={`${imageBaseUrl}/adminUi/multipleUsersIcon.svg`}
                    className="w-[30px] h-[30px]"
                />
            ),
        },
        {
            id: 3,
            title: 'Approved',
            value: 'approved',
            count: accountCounts?.approved,
            color: '#039855',
            icon: <CheckOutlined className="text-[30px]" />,
        },
        {
            id: 4,
            title: 'Rejected',
            value: 'rejected',
            count: accountCounts?.rejected,
            color: '#F79009',
            icon: <CloseOutlined className="text-[30px]" />,
        },
        {
            id: 5,
            title: 'Awaiting Approval',
            value: 'awaitingApproval',
            count: accountCounts?.awaitingApproval,
            color: '#4B5563',
            icon: <ExclamationCircleOutlined className="text-[30px]" />,
        },
    ];

    const statusColors = accountCountsData.reduce((acc, transaction) => {
        acc[transaction.value] = transaction.color;
        return acc;
    }, {});

    const options = useMemo(() => {
        const maxValue = Math.max(...seriesData.flatMap(series => series.data));
        const yAxisMax = Math.ceil(maxValue / 5) * 5; // Round up to nearest multiple of 5
        const tickAmount = 5;
        return {
            chart: {
                type: 'line',
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
            },
            colors: seriesData.map(series => statusColors[series.name.toLowerCase()] || '#000000'),
            xaxis: {
                categories: category,
                labels: {
                    show: true, // Show x-axis labels
                },
                title: {
                    text: 'Time',
                    offsetY: -15, // Adjust the vertical position
                },
            },
            yaxis: {
                min: 0,
                max: yAxisMax,
                tickAmount: tickAmount,
                labels: {
                    formatter: value => {
                        if (value >= 1000) {
                            return `${Math.floor(value / 1000)}k`;
                        }
                        return Math.floor(value);
                    },
                    show: true, // Show y-axis labels
                },
                title: {
                    text: 'Accounts Counts',
                    rotate: -90, // Rotate the label to be vertical
                    offsetX: 0, // Adjust the horizontal position
                },
            },
            stroke: {
                curve: 'smooth',
                width: 1, // Set line width to be thinner
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5,
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                showForSingleSeries: true,
            },
        };
    }, [category, seriesData, statusColors]);

    const handleClickCountCard = code => {
        let filterValue = code.value;
        const onboardingStatus =
            filterValue === 'totalAccounts'
                ? 'all'
                : filterValue.charAt(0).toUpperCase() + filterValue.slice(1);

        navigate('/accounts', {
            state: {
                onboardingStatus: onboardingStatus,
                startDate: dashboardFilters?.startDate,
                endDate: dashboardFilters?.endDate,
                accountType: dashboardFilters?.accountType,
            },
        });
    };

    return (
        <div className="w-full">
            {windowWidth >= 1024 ? (
                <div className="flex flex-row gap-[12px] cursor-pointer overflow-x-scroll mb-3 custom-Dashboard-scrollbar">
                    {accountCountsData.map((status, index) => (
                        <CardDesktop
                            key={index}
                            icon={status?.icon}
                            count={status?.count}
                            color={status?.color}
                            title={status?.title}
                            onClick={() => handleClickCountCard(status)}
                            loading={loading}
                        />
                    ))}
                </div>
            ) : (
                <div
                    className={`grid grid-cols-1 md:grid-cols-2 gap-[8px] cursor-pointer justify-between mb-3 w-full`}>
                    {accountCountsData.map((status, index) => (
                        <CardMobile
                            key={index}
                            icon={status?.icon}
                            count={status?.count}
                            color={status?.color}
                            title={status?.title}
                            onClick={() => handleClickCountCard(status)}
                            loading={loading}
                        />
                    ))}
                </div>
            )}

            <div className="flex flex-col lg:flex-row gap-3">
                <div className="bg-white p-6 w-full rounded-xl ">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <p className="text-lg font-semibold text-primary ml-4 mb-2">Accounts</p>
                            <ReactApexChart
                                options={options}
                                series={seriesData}
                                type="line"
                                height={windowWidth < 1024 ? 347 : 720}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardAccounts;
