import React from 'react';
import { Form, Input, Select, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon } from '../../utils/Images';

export default function FormSelectCurrency({
    label,
    name,
    selectName,
    type,
    tooltip,
    value,
    selectValue,
    defaultValue,
    selectDefaultValue,
    placeholder,
    required,
    disabled,
    rules,
    options,
    onChange,
    onSelectChange,
    className,
    formClassName,
    dataE2e,
    selectDataE2e,
}) {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <Form.Item
            label={
                <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                    {label}
                </p>
            }
            required={required}
            className={`mt-[30px] ${formClassName}`}>
            <div className="relative w-full sm:w-[320px] mr-auto flex gap-0.5 !h-[50px] items-center">
                <Tooltip title={tooltip}>
                    <Form.Item name={name} rules={rules} noStyle>
                        <Input
                            name={name}
                            type={type}
                            value={defaultValue}
                            placeholder={placeholder}
                            data-e2e={dataE2e}
                            disabled={disabled}
                            onChange={onChange}
                            className={`flex-1 w-full h-[50px] text-base rounded-l-3xl rounded-r-none pl-6 py-2.5 p-2 bg-[#F4F4F5] hover:bg-[#F4F4F5] px-[24px] disabled:opacity-50 read-only:opacity-50 focus:shadow-none ${className}`}
                        />
                    </Form.Item>
                </Tooltip>
                <Form.Item name={selectName} rules={[{ required: true, message: '' }]} noStyle>
                    <Select
                        suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                        className={`custom-rounded-select bg-[#F4F4F5] !w-[120px] !min-w-[120px] h-[50px] !rounded-tr-[25px] !rounded-br-[25px] !border-none hover:bg-[#F4F4F5] disabled:opacity-50 `}
                        defaultValue={selectDefaultValue}
                        dropdownStyle={{ zIndex: 1002 }}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        options={options}
                        data-e2e={selectDataE2e}
                        onChange={onSelectChange}
                        dropdownRender={menu => (
                            <div className="!rounded-3xl" data-e2e={menu}>
                                {menu}
                            </div>
                        )}
                    />
                </Form.Item>
            </div>
        </Form.Item>
    );
}
