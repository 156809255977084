import { Button, Tooltip } from 'antd';
import { useState } from 'react';

export default function ThemeButton({
    style,
    icon,
    action,
    text,
    dataE2e,
    htmlType,
    type,
    shape,
    loading,
    size,
    disabled,
    key,
    autoWidth,
    tooltipText,
    textCss = 'text-center',
}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const buttonElement = (
        <Button
            size={size || 'middle'}
            shape={shape || 'default'}
            type={type || 'default'}
            loading={loading}
            block={!autoWidth}
            disabled={disabled}
            className={`${style || ''}`}
            {...(htmlType && { htmlType })}
            {...(key && { key })}
            {...(dataE2e && { 'data-e2e': dataE2e })}
            {...(icon && { icon })}
            onClick={e => {
                if (tooltipText && window.innerWidth <= 768) {
                    if (!tooltipOpen) {
                        setTooltipOpen(true);
                        e.preventDefault();
                        return;
                    }
                }
                action?.(e);
            }}
            onTouchStart={() => {
                if (tooltipText) {
                    setTooltipOpen(true);
                }
            }}
            onTouchEnd={() => {
                if (tooltipText) {
                    setTimeout(() => setTooltipOpen(false), 1500);
                }
            }}>
            <p className={textCss}>{!loading && text}</p>
        </Button>
    );

    return tooltipText ? (
        <Tooltip
            title={tooltipText}
            open={tooltipOpen}
            onOpenChange={visible => setTooltipOpen(visible)}
            trigger={['hover', 'click']}>
            {buttonElement}
        </Tooltip>
    ) : (
        buttonElement
    );
}
