import React from 'react';
import { Form, Input, Select } from 'antd';

function AccountPurposeForm({ form }) {
    const { Option } = Select;

    return (
        <div className="p-4 py-0 md:px-8 md:pt-2 lg:p-0 w-full">
            <Form form={form} layout="vertical" className="mt-2">
                <Form.Item
                    name="accountPurpose"
                    label="Account Purpose"
                    className="account-pupose-form  "
                    formClassName="!mt-10 !mb-0 errorFields"
                    rules={[
                        { required: true, message: 'Please select an account purpose' },
                        {
                            type: 'accountPurpose',
                            message: 'Please select an account purpose',
                        },
                    ]}>
                    <Select
                        mode="multiple"
                        allowClear
                        data-e2e="Select reason for opening account"
                        className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select"
                        placeholder="Select reason for opening account">
                        <Option value="firstInbound">1st party inbound payments</Option>
                        <Option value="firstOutbound">1st party outbound payments</Option>
                        <Option value="thirdInbound">3rd party inbound payments</Option>
                        <Option value="thirdOutbound">3rd party outbound payments</Option>
                        <Option value="FX">FX</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="flowOfFunds"
                    formClassName="!mt-10 !mb-0 errorFields"
                    label="Please explain the flow of funds in/out of your Juno Money"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject('* Please enter flow of funds');
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}
                    className="account-pupose-form">
                    <Input.TextArea
                        className="rounded-lg border-[1px] border-[#D1D1D6] placeholder-[#70707B]"
                        placeholder="Enter text here"
                        data-e2e="enter-text-here"
                        rows={3}
                    />
                </Form.Item>

                <Form.Item
                    name="sourceOfFunds"
                    label="Please explain the source of funds that will be sent into your Juno Money account"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject('* Please enter source of funds');
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}
                    className="account-pupose-form">
                    <Input.TextArea
                        className="rounded-lg border-[1px] border-[#D1D1D6] placeholder-[#70707B]"
                        placeholder="Enter text here"
                        data-e2e="source-of-funds"
                        rows={3}
                    />
                </Form.Item>
            </Form>
        </div>
    );
}

export default AccountPurposeForm;
