import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CommonModal from '../modals/CommonModal';
import RegistrationPersonalDetails from './RegistrationPersonalDetails';
import RegistrationBusinessDetails from './RegistrationBusinessDetails';

const CreateAccountModal = ({
    open,
    handleCloseAddAccountModal,
    handleOpenAddAccountModal,
    getClients,
}) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [openPersonalDetailsForm, setPersonalDetailsForm] = useState(false);
    const [openBuisnessDetailsForm, setBuisnessDetailsForm] = useState(false);

    return (
        <div>
            <RegistrationPersonalDetails
                open={openPersonalDetailsForm}
                handleCloseAddAccountModal={() => setPersonalDetailsForm(false)}
                handleOpenAddAccountModal={handleOpenAddAccountModal}
                getClients={getClients}
            />

            <RegistrationBusinessDetails
                open={openBuisnessDetailsForm}
                handleCloseAddAccountModal={() => setBuisnessDetailsForm(false)}
                handleOpenAddAccountModal={handleOpenAddAccountModal}
                getClients={getClients}
            />

            <CommonModal
                open={open}
                title={'Create Your Account'}
                className={'common-mobile-view '}
                handleClose={handleCloseAddAccountModal}>
                <p className="text-gray-600 font-inter text-base font-normal leading-6 mt-4">
                    What kind of account would you like to open today?
                </p>

                <div className="flex flex-col md:flex-row gap-4 md:gap-6 mt-[40px] ">
                    <div
                        onClick={() => {
                            setPersonalDetailsForm(true);
                            handleCloseAddAccountModal();
                        }}
                        className="p-8 sm:p-4! px-6 rounded-xl bg-gray-100 hover:bg-[#d1d1d6] group hover:cursor-pointer flex-1">
                        <div className="flex flex-row md:flex-col items-center gap-4 md:gap-0">
                            <div className="flex items-center justify-center md:p-[14px] group-hover:bg-[#e4e4e7] rounded-xl mb-5">
                                <img
                                    alt="personal account"
                                    data-e2e="personal-account"
                                    src={`${imageBaseUrl}/common/personicon.svg`}
                                    className="w-[52px] h-[52px]"
                                />
                            </div>

                            <div className="md:text-center">
                                <p className="md:text-xl font-semibold text-brand-black font-inter cursor-pointer group-hover:underline">
                                    Personal account
                                </p>
                                <p className="!mt-2 text-[#51525C] font-inter text-sm sm:text-base">
                                    Send, spend and receive around the world.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            setBuisnessDetailsForm(true);
                            handleCloseAddAccountModal();
                        }}
                        className="p-8  px-6 rounded-xl bg-gray-100 hover:bg-[#d1d1d6] group hover:cursor-pointer flex-1">
                        <div className="flex flex-row md:flex-col items-center gap-4 md:gap-0">
                            <div className="flex items-center justify-center md:p-[14px] group-hover:bg-[#e4e4e7] rounded-xl mb-5">
                                <img
                                    alt="business account"
                                    src={`${imageBaseUrl}/common/workicon.svg`}
                                    className="w-[52px] h-[52px]"
                                    data-e2e="business-account"
                                />
                            </div>

                            <div className="md:text-center">
                                <p className="md:text-xl font-semibold text-brand-black font-inter cursor-pointer group-hover:underline">
                                    Business account
                                </p>
                                <p className="!mt-2 text-[#51525C] font-inter text-sm sm:text-base">
                                    Do business or freelance work internationally.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonModal>
        </div>
    );
};

export default CreateAccountModal;
