import React from 'react';
import { transactionStatus } from '../utils/constant';
import { Tooltip } from 'antd';
import { formatAmount } from '../utils/formatAmount';
import { useNavigate } from 'react-router-dom';
import { LoadingSkeleton } from './common/Loading';
import { toFixedTrunc } from '../utils/common';

const StatusSummaryCard = ({ title, data, loading, dashboardFilters, currencyType }) => {
    const navigate = useNavigate();

    const filteredTransactionStatus = transactionStatus.filter(status => {
        if (title?.label === 'Deposits' || title?.label === 'FX' || title?.label === 'Transfers') {
            return status.value !== 'rejected';
        }
        return true;
    });

    const handleStatusCountCode = (title, code) => {
        let transactionType = [title?.value];
        const isFlagged = code.value === 'flagged';
        if (title?.value === 'all') {
            transactionType =
                currencyType == 'junomoney'
                    ? ['Deposit', 'Withdrawal', 'FX', 'Transfer', 'Payment Out']
                    : ['Buy', 'Sell', 'Exchange', 'Send', 'Receive'];
        }
        navigate('/transactions', {
            state: {
                transactionType: transactionType,
                transactionStatus: isFlagged ? ['amlHold'] : code.value,
                dashboardFilters,
            },
        });
    };

    if (loading) {
        return <LoadingSkeleton loading={loading} />;
    }

    return (
        <div className="rounded-xl border border-[#E4E4E7] overflow-hidden">
            <div className="px-6 py-3.5 border-b border-[#D1D1D6] bg-white">
                <span className="text-primary text-base font-semibold">{title?.label}</span>
            </div>
            {filteredTransactionStatus.map((status, key) => {
                // Flagged Transactions
                const isFlagged = status.value === 'flagged';
                const totalUSDAmount = isFlagged
                    ? data?.amlHold?.totalUSDAmount || 0
                    : (data && data[status.value]?.totalUSDAmount) || 0;

                const count = isFlagged
                    ? data?.amlHold?.count || 0
                    : data?.[status.value]?.count || 0;

                return (
                    <div
                        key={key}
                        className={`px-6 py-2.5 flex items-center ${key < transactionStatus.length - 1 ? 'border-b border-[#E4E4E7]' : ''} ${key % 2 === 0 ? 'bg-white' : 'bg-[#FAFAFA]'}`}>
                        <span className="flex-1 text-primary">{status.label}:</span>
                        <span>
                            <Tooltip title={`$ ${toFixedTrunc(totalUSDAmount)}`}>
                                <span className="text-[#70707B]">
                                    $ {formatAmount(totalUSDAmount)} -{' '}
                                </span>
                            </Tooltip>
                            {data && (
                                <span
                                    className={`text-[#1570EF] font-medium ml-1 hover:underline ${count ? 'cursor-pointer' : 'cursor-default'}`}
                                    onClick={() => count && handleStatusCountCode(title, status)}>
                                    {count || 'N/A'}
                                </span>
                            )}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default StatusSummaryCard;
