import React, { useState, useEffect } from 'react';
import CommonModal from '../modals/CommonModal';
import { ArrowLeftOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import RegistrationPersonalDetailsForm from './RegistrationPersonalDetailsForm';
import TransactionActivityForm from './TransactionActivityForm';
import AccountPurposeForm from './AccountPurposeForm';
import { useCommonFunctions } from '../../utils/common';
import { Form } from 'antd';
import ThemeButton from '../common/ThemeButton';

const RegistrationPersonalDetails = ({
    open,
    handleCloseAddAccountModal,
    handleOpenAddAccountModal,
    getClients,
}) => {
    const [form] = Form.useForm();
    const [accountPurposeForm] = Form.useForm();
    const [transactionActivityForm] = Form.useForm();

    const adminRole = localStorage.getItem('adminRole');
    const email = localStorage.getItem('email');
    const [clientEmail, setClientEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [headerContent, setHeaderContent] = useState('');
    const { handleRegistrationStages } = useCommonFunctions();
    const [error, setError] = useState('');

    useEffect(() => {
        switch (step) {
            case 1:
                setHeaderContent('Your Personal Details');
                break;
            case 2:
                setHeaderContent('Account Purpose');
                break;
            case 3:
                setHeaderContent('Transaction Activity');
                break;
        }
    }, [step]);

    const handleBack = () => {
        if (step === 1) {
            handleOpenAddAccountModal();
            handleCloseAccountModal();
        } else {
            setStep(step - 1);
        }
    };

    const addClientAccount = async () => {
        try {
            const formValues = await form.validateFields();
            const body = {
                email: email,
                adminRole: adminRole,
                ...formValues,
                accountType: 'Personal',
            };

            setLoading(true);
            try {
                const response = await apiRequest('/create-client', 'POST', body);

                if (response.success || response.data) {
                    setClientEmail(form.getFieldValue('clientEmail'));
                    form.resetFields();
                    setStep(step + 1);
                    setError('');
                } else {
                    setError(response.error);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const submitAccountPurpose = async () => {
        try {
            const formValues = await accountPurposeForm.validateFields();

            setLoading(true);
            try {
                const response = await handleRegistrationStages('account-purpose', {
                    ...formValues,
                    email: clientEmail,
                });
                if (response.success) {
                    setStep(step + 1);
                }
            } catch (error) {
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Validation failed:', error);
        }
    };

    const submitTransactionActivity = async () => {
        try {
            const formValues = await transactionActivityForm.validateFields();
            setLoading(true);
            try {
                const payload = {
                    transactionActivity: {
                        fx: {
                            numberPerMonth: formValues.fxTransactions,
                            averageUsdValue: formValues.fxUsdValue.replace(/\$/g, '').trim(),
                            mainFxPairs: formValues.fxPairs,
                        },
                        inbound: {
                            averageUsdValue: formValues.inboundUsdValue.replace(/\$/g, '').trim(),
                            estimatedNumberPerMonth: formValues.inboundTransactions,
                            jurisdictionsReceivingFundsFrom:
                                formValues.inboundJurisdictions.join(', '),
                            mainCurrencies: formValues.inboundCurrencies,
                            topEntitiesSendingFunds: formValues.inboundEntities,
                        },
                        outbound: {
                            averageUsdValue: formValues.outboundUsdValue.replace(/\$/g, '').trim(),
                            estimatedNumberPerMonth: formValues.outboundTransactions,
                            jurisdictionsSendingFundsTo:
                                formValues.outboundJurisdictions.join(', '),
                            mainCurrencies: formValues.outboundCurrencies,
                            topBeneficiariesReceivingFunds: formValues.outboundEntities,
                        },
                    },
                };
                const response = await handleRegistrationStages('transaction-activity', {
                    ...payload,
                    email: clientEmail,
                });
                if (response.success) {
                    handleCloseAccountModal();
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Validation failed:', error);
        }
    };

    const handleCloseAccountModal = async () => {
        handleCloseAddAccountModal();
        form.resetFields();
        accountPurposeForm.resetFields();
        transactionActivityForm.resetFields();
        setStep(1);
        if (step !== 1) {
            await getClients();
        }
        setError('');
    };

    const handleFinish = () => {
        if (step === 1) {
            addClientAccount();
        } else if (step === 2) {
            submitAccountPurpose();
        } else if (step === 3) {
            submitTransactionActivity();
        }
    };

    return (
        <CommonModal
            maskClosable={false} // Prevents closing on outside click
            title={
                <>
                    <div className="flex items-center">
                        {step === 2 ? (
                            ''
                        ) : (
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={handleBack}
                            />
                        )}
                        <p className="text-2xl font-semibold relative flex">{headerContent}</p>
                    </div>
                    {error && (
                        <p className="text-red-500 mt-3 text-base font-normal ml-4">{error}</p>
                    )}
                </>
            }
            open={open}
            handleClose={handleCloseAccountModal}
            onFinish={handleFinish}
            className={'common-mobile-view account-model-view'}
            loading={loading}
            footerText="Continue"
            footerContent={
                step !== 1 && (
                    <ThemeButton
                        style="mr-2"
                        action={() => {
                            step === 3 ? handleCloseAccountModal() : setStep(step + 1);
                        }}
                        text="Skip"
                        dataE2e="skip-btn"
                        shape="round"
                        size="large"
                    />
                )
            }>
            {step === 1 && <RegistrationPersonalDetailsForm form={form} />}
            {step === 2 && <AccountPurposeForm form={accountPurposeForm} />}
            {step === 3 && <TransactionActivityForm form={transactionActivityForm} />}
        </CommonModal>
    );
};

export default RegistrationPersonalDetails;
