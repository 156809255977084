import React, { useMemo } from 'react';
import { Tabs, Tooltip } from 'antd';
import { fiatDetailsNav } from '../../utils/constant';
import { useCommonFunctions } from '../../utils/common';
import { useSelector } from 'react-redux';

const FiatTab = () => {
    const { getUserOnboardingStatus } = useCommonFunctions();
    const isUserOnboarded = useSelector(state => getUserOnboardingStatus(state.account.clientData));
    const disabledTabs = useMemo(
        () => (isUserOnboarded ? ["Fee's", 'Balances'] : []),
        [isUserOnboarded],
    );

    console.log(isUserOnboarded, 'isUserOnboarded');

    const getFiatDetailsNav = disabledTabs =>
        fiatDetailsNav.map(({ key, label, children }) => ({
            key,
            label: disabledTabs.includes(label) ? (
                <Tooltip title={isUserOnboarded} placement="top">
                    <span>{label}</span>
                </Tooltip>
            ) : (
                label
            ),
            children,
            disabled: disabledTabs.includes(label),
        }));

    const items = useMemo(() => getFiatDetailsNav(disabledTabs), [disabledTabs]);

    return (
        <div className="col-span-2 bg-white">
            <div className="pt-7 fiat-tab-tabs">
                <Tabs defaultActiveKey="0" items={items} className="transaction-detail fiat-tab" />
            </div>
        </div>
    );
};

export default FiatTab;
