import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, Pagination, message, Spin } from 'antd';
import apiRequest from '../../utils/api';
import moment from 'moment';
import CustomPagination from '../layout/CustomPagination';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Delete } from '../../utils/Images';
import { useSelector } from 'react-redux';
import DeleteModal from '../modals/DeleteModal';

const WhiteLabelUsers = ({ tab }) => {
    const email = localStorage.getItem('email');
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [toDelete, setToDelete] = useState('');

    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    useEffect(() => {
        if (tab === '1') {
            getWhiteLabelUsers('', 'createdDate', 'desc', 1);
        }
    }, [tab]);

    const getWhiteLabelUsers = async (searchText, sortBy, orderBy, page) => {
        setLoading(true);
        const body = {
            email,
            filterParams: {
                searchText,
                page,
                sortBy,
                orderBy,
            },
        };
        const response = await apiRequest('/get-admin-white-label', 'POST', body);
        if (response.success) {
            setData(response.data.whiteLabelUser);
            setPagination(prev => ({
                ...prev,
                total: response.data.totalCount,
                current: page,
            }));
            setLoading(false);
        } else {
            setLoading(false);
            message.error(response.error);
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const { current } = pagination;
        const { field, order } = sorter;

        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));

        let sortBy, orderBy;
        if (order === 'ascend') {
            sortBy = field;
            orderBy = 'asc';
        } else if (order === 'descend') {
            sortBy = field;
            orderBy = 'desc';
        }
        getWhiteLabelUsers('', 'createdDate', orderBy, current);
    };

    const handlePagination = page => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
        }));
        getWhiteLabelUsers('', 'createdDate', 'desc', page);
    };

    const deleteUser = async clientEmail => {
        const body = {
            email,
            clientEmail,
        };
        const response = await apiRequest('/delete-admin-white-label', 'POST', body);
        getWhiteLabelUsers('', 'createdDate', 'desc', 1);
        setDeleteModal(false);
        setExpandedRowKey(null);
    };

    const handleExpandRow = key => {
        setExpandedRowKey(expandedRowKey === key ? null : key);
    };

    const modalClose = () => {
        setDeleteModal(false);
    };

    const columns = [
        {
            title: 'Account Email',
            dataIndex: 'email',
            key: 'userEmail',
        },
        {
            title: 'White Label',
            key: 'whiteLabel',
            render: row => {
                return <div>{row.whitelabelRef?.whitelableName || 'N/A'}</div>;
            },
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Created Date',
            key: 'createdDate',
            sorter: true,
            render: row => {
                return <div>{moment(row.createdDate).format('DD/MM/YYYY - HH:mm')}</div>;
            },
        },
        {
            title: 'Actions',
            render: row => {
                return (
                    <Button
                        type="text"
                        onClick={() => {
                            setDeleteModal(true);
                            setToDelete(row.email);
                        }}>
                        {
                            <img
                                className="!w-5 !h-5"
                                data-e2e={row.email}
                                src={`${imageBaseUrl}${Delete}`}
                            />
                        }
                    </Button>
                );
            },
        },
    ];

    const ExpandedRow = ({ record }) => {
        return (
            <div className="p-3 bg-[#FCFCFC] border rounded-lg">
                {[
                    { label: 'User Email', value: record.email },
                    { label: 'White Label', value: record?.whitelabelRef?.whitelableName || 'N/A' },
                    { label: 'Created By', value: record.createdBy },
                    { label: 'Role', value: record.role },
                    {
                        label: 'Created Date',
                        value: moment(record.createdDate).format('DD/MM/YYYY - HH:mm'),
                    },
                ].map(({ label, value }) => (
                    <p
                        key={label}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-3">
                        <span>{label}:</span> {value}
                    </p>
                ))}
                <Divider />
                <Button
                    className="px-2 py-3"
                    icon={<img className="svg-red" src={`${imageBaseUrl}${Delete}`} />}
                    onClick={() => {
                        setDeleteModal(true);
                        setToDelete(record.email);
                    }}>
                    Delete
                </Button>
            </div>
        );
    };

    return (
        <>
            <div className="lg:block hidden w-full accounts-page mt-8">
                <h2 className="page-title ml-6">White Label Account</h2>
                <Table
                    dataSource={data}
                    columns={columns}
                    className="cursor-pointer border-[#E4E4E7] border-[1px] border-solid rounded-b-lg custom-table mt-6 settings-table"
                    scroll={true}
                    rowKey={record => record._id}
                    loading={loading}
                    pagination={{
                        position: ['bottomCenter'],
                        itemRender: CustomPagination,
                        showSizeChanger: false,
                        current: pagination.current,
                        total: pagination.total,
                    }}
                    onChange={handleTableChange}
                />
            </div>

            <div className="block lg:hidden">
                {!loading ? (
                    <>
                        <h2
                            className="text-base font-semibold ml-3 my-4"
                            data-e2e="white-label-user">
                            White Label Users
                        </h2>
                        {data?.map((item, key) => {
                            const isExpanded = expandedRowKey === key;
                            return (
                                <div key={key} className="bg-white border-b border-[#E4E4E7]">
                                    <div className="flex justify-between items-center p-3 cursor-pointer">
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <h1 className="text-sm font-medium pb-1 text-[#18181B]">
                                                    {item.email}
                                                </h1>
                                                <p className="font-normal text-xs text-darkGray">
                                                    {item.whitelabelRef?.whitelableName || 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex gap-4 items-center">
                                            <div className="" onClick={() => handleExpandRow(key)}>
                                                {isExpanded ? (
                                                    <UpOutlined width={10} height={10} />
                                                ) : (
                                                    <DownOutlined width={10} height={10} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {isExpanded && (
                                        <div className="p-3">
                                            <ExpandedRow record={item} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        <Pagination
                            total={pagination.total}
                            responsive
                            className="py-3 flex justify-center"
                            current={pagination.current}
                            onChange={handlePagination}
                            showSizeChanger={false}
                        />
                    </>
                ) : (
                    <div className="flex items-center justify-center h-[50vh] ">
                        <Spin />
                    </div>
                )}
            </div>

            <DeleteModal
                open={deleteModal}
                title="Delete white label user"
                content={'Are you sure you want to delete the white label user'}
                name={toDelete}
                toDelete={toDelete}
                handleClose={modalClose}
                handleDelete={deleteUser}></DeleteModal>
        </>
    );
};

export default WhiteLabelUsers;
