import React, { useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { Button, Divider, Input, Form } from 'antd';
import { LeftOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../utils/api';
import { CopyButton } from '../utils/common';
import DownloadCsvModal from '../components/modals/DownloadCsvModal';
import CommonModal from '../components/modals/CommonModal';
import { FormPassword } from '../components/forms';
import '../styles/profile.css';
import { useSelector } from 'react-redux';
import { DefaultUserIcon } from '../utils/Images';
import { EditPencilIcon } from '../utils/Images';
import ThemeButton from '../components/common/ThemeButton';

const Profile = () => {
    const [data, setData] = useState([]);
    const [downloadCsv, setDownloadCsv] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState({});
    const [error, setError] = useState('');
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const email = localStorage.getItem('email');
    const adminRole = localStorage.getItem('adminRole');
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const name = localStorage.getItem('name');

    const changeAdminPassword = async () => {
        const values = form.getFieldsValue();
        const { currentPassword, newPassword } = values;

        const body = {
            email: email,
            adminRole: adminRole,
            oldPassword: currentPassword,
            newPassword: newPassword,
        };

        setLoading(true);
        try {
            const response = await apiRequest('/change-admin-password', 'POST', body);

            if (response.success || response.data) {
                setLoading(false);
                handleChangePasswordClose();
                localStorage.clear();
                navigate('/');
            } else {
                setError(response.error);
                setLoading(false);
            }
        } catch (error) {
            setError('Something went wrong');
            setLoading(false);
        }
    };

    const handleChangePasswordClose = () => {
        setChangePasswordModal(false);
        form.resetFields();
        setErrorMsg({});
        setError('');
    };

    function ProfileUserContent() {
        return (
            <>
                <div className="profile__user">
                    <div className="profile__user-icon">
                        <img src={`${imageBaseUrl}${DefaultUserIcon}`} />
                    </div>
                    <div>
                        <p className="title-mb text-base lg:text-2xl">
                            <span className="font-semibold">{name}</span>{' '}
                            <span className="text-[#51525c]">({adminRole})</span>
                        </p>
                        <p className="profile__block-text text-mb truncate">
                            {email}
                            <CopyButton msg={email} />
                        </p>
                        <p className="profile__block-text text-mb">
                            Account ID:{' '}
                            <span className="text-[#18181B] truncate">
                                {paymentProviderId}
                                <CopyButton msg={paymentProviderId} />
                            </span>
                        </p>
                    </div>
                </div>
                <div className="divider"></div>
                <div>
                    <p className="page-title mb-[12px]">Admin privileges:</p>
                    <p className="profile__block-title mb-el">
                        Access to all admin functionality for all admin applications.
                    </p>
                    <ThemeButton
                        text="Edit admin role"
                        type="primary"
                        autoWidth={true}
                        icon={<img src={`${imageBaseUrl}${EditPencilIcon}`} alt="edit" />}
                        // action={() => setChangePasswordModal(true)}
                    />
                </div>
            </>
        );
    }

    function ProfilePasswordContent() {
        return (
            <div>
                <div className="profile__block">
                    <p className="page-title mb-[12px]">Password</p>
                    <p className="profile__block-text mb-el">
                        Click below to update your admin password.
                    </p>

                    <ThemeButton
                        type="primary"
                        autoWidth={true}
                        text="Change password"
                        action={() => setChangePasswordModal(true)}
                        icon={<img src={`${imageBaseUrl}${EditPencilIcon}`} alt="edit" />}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            <Sidebar>
                <div className="profile">
                    <div className="profile__content">
                        <div>
                            <div className="profile__block">
                                <p className="page-title mb-el">Admin Details</p>
                                <ProfileUserContent />
                            </div>
                        </div>
                        <ProfilePasswordContent />
                    </div>
                </div>
                <div className="profile mobile-view">
                    <div className="flex justify-between items-center p-[12px] sm:p-5 bg-[#F7F7F7]">
                        <div className="page-title cursor-pointer" onClick={() => navigate(-1)}>
                            <LeftOutlined className="mr-2" />
                            <span>Admin Details</span>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div>
                            <div className="profile__block">
                                <ProfileUserContent />
                            </div>
                        </div>
                        <ProfilePasswordContent />
                    </div>
                </div>
            </Sidebar>
            <DownloadCsvModal
                data={[data]}
                open={downloadCsv}
                handleClose={() => setDownloadCsv(false)}
                name="singleClient"
            />
            <CommonModal
                title="Change Password"
                open={changePasswordModal}
                handleClose={handleChangePasswordClose}
                className={'common-mobile-view'}
                footerText="Change"
                error={error}
                disabled={!!(errorMsg?.newPassword || errorMsg?.confirmNewPassword)}
                onFinish={changeAdminPassword}
                loading={loading}
                form={form}>
                <Form
                    form={form}
                    layout="horizontal"
                    labelAlign="left"
                    labelWrap
                    labelCol={{
                        flex: '130px',
                    }}
                    colon={false}
                    className="mt-10 lg:mt-[30px] h-[75vh] lg:h-auto overflow-x-auto pr-2 modal-form pt-2 px-1 hide-scrollbar">
                    <FormPassword
                        label="Current Password:"
                        name="currentPassword"
                        placeholder="Enter current password"
                        required
                        rules={[{ required: true, message: 'Current password is required' }]}
                        errorMsg={errorMsg?.currentPassword}
                    />
                    <FormPassword
                        label="New Password:"
                        name="newPassword"
                        placeholder="Enter New password"
                        required
                        rules={[{ required: true, message: 'New password is required' }]}
                        errorMsg={errorMsg?.currentPassword}
                    />
                    <FormPassword
                        label="Confirm New Password:"
                        name="confirmNewPassword"
                        placeholder="Enter New password"
                        required
                        rules={[
                            { required: true, message: 'New password is required' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('The two passwords do not match'),
                                    );
                                },
                            }),
                        ]}
                    />
                    <hr className="my-[30px]" />
                    <div className="font-normal text-sm text-[#51525C]">
                        <p className="mb-4">Your password must contain:</p>
                        <p className="flex items-center gap-4 mb-3 font-normal text-sm text-[#18181B] ">
                            <span>
                                <CloseCircleFilled className="text-[#F04438] text-lg" />
                            </span>
                            Between 8 and 20 characters
                        </p>
                        <p className="flex items-center gap-4 mb-3 font-normal text-sm text-[#18181B]">
                            <span>
                                <CloseCircleFilled className="text-[#F04438] text-lg" />
                            </span>
                            1 upper case letter
                        </p>
                        <p className="flex items-center gap-4 mb-3 font-normal text-sm text-[#18181B]">
                            <span>
                                <CloseCircleFilled className="text-[#F04438] text-lg" />
                            </span>
                            1 or more numbers
                        </p>
                    </div>
                </Form>
            </CommonModal>
        </>
    );
};

export default Profile;
