import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCountries } from '../../utils/common';
import { fiatCurrencies } from '../../utils/constant';

const numberOfTransactionsOption = [
    { value: '1-500', label: '1-500' },
    { value: '500-5000', label: '500-5,000' },
    { value: '5000-25000', label: '5,000-25,000' },
    { value: '25000-100000', label: '25,000-100,000' },
    { value: '100000-1000000', label: '100,000-1,000,000' },
    { value: '1000000+', label: '1,000,000+' },
];

function getFormattedOptions(isAmount) {
    if (!isAmount) {
        return numberOfTransactionsOption;
    } else {
        return numberOfTransactionsOption.map(option => {
            const parts = option.label.split('-');
            const formattedOptions = parts.map(part => `$${part}`).join('-');

            return {
                value: formattedOptions,
                label: formattedOptions,
            };
        });
    }
}

function TransactionActivityForm({ form }) {
    const [countries, setCountries] = useState([]);

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const transactionsCurrenciesOptions = fiatCurrencies.map(currency => ({
        value: currency.symbol,
        label: `${currency.name} (${currency.symbol})`,
    }));

    const fxPairsOptions = [];
    for (let i = 0; i < fiatCurrencies.length; i++) {
        for (let j = i + 1; j < fiatCurrencies.length; j++) {
            const pair = `${fiatCurrencies[i].symbol}-${fiatCurrencies[j].symbol}`;
            fxPairsOptions.push({
                value: pair,
                label: `${fiatCurrencies[i].symbol} to ${fiatCurrencies[j].symbol}`,
            });
        }
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                className="mt-2 lg:mt-[20px] h-[60vh] px-[7px] overflow-y-auto hide-scrollbar">
                <p
                    level={5}
                    className="my-4 !font-inter font-medium text-lg leading-7 tracking-normal">
                    Inbound
                </p>
                <Form.Item
                    name="inboundTransactions"
                    label="Estimated number of Inbound transactions per month*"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="Inbound-Select-transaction-count"
                        placeholder="Select transaction count">
                        {getFormattedOptions(false).map(item => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="inboundUsdValue"
                    label="Average USD value of each inbound transaction*"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="inbound-Usd-Value"
                        placeholder="Select amount">
                        {getFormattedOptions(true).map(item => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="inboundCurrencies"
                    label="Main inbound currencies*"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select currencies you operate with"
                        data-e2e="inbound-select-currencies-you-operate-with"
                        value={form.mainCurrencies}
                        className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                        {transactionsCurrenciesOptions.map(obj => (
                            <Option value={obj.value}>{obj.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="inboundJurisdictions"
                    label="Top Jurisdictions you will receive funds from"
                    rules={[{ required: true, message: 'Please select a jurisdiction' }]}
                    className="account-pupose-form">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        data-e2e="inbound-Select-jurisdiction-you-operate-with"
                        className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select"
                        placeholder="Select jurisdiction you operate with">
                        {countries.length > 0 ? (
                            countries.map((country, key) => (
                                <Option key={key} value={country.name}>
                                    {country.name}
                                </Option>
                            ))
                        ) : (
                            <Option value="">Loading...</Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="inboundEntities"
                    label="Top entities/individuals that will send funds into your Juno Money account"
                    rules={[{ required: true, message: 'Please enter entities or individuals' }]}
                    className="account-pupose-form">
                    <Input.TextArea
                        className="rounded-lg border-[2px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="List-full-names-with-commas"
                        placeholder="inbound-List-full-names-with-commas"
                        rows={3}
                    />
                </Form.Item>
                <p className="my-4 !font-inter font-medium text-lg leading-7 tracking-normal">
                    Outbound
                </p>
                <Form.Item
                    name="outboundTransactions"
                    label="Estimated number of outbound transactions per month"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="outBound-Select transaction count"
                        placeholder="Select transaction count">
                        {getFormattedOptions(false).map(item => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="outboundUsdValue"
                    label="Average USD value of each outbound transaction"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="outBound-Select amount"
                        placeholder="Select amount">
                        {getFormattedOptions(true).map(item => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="outboundCurrencies"
                    label="Main outbound currencies"
                    rules={[{ required: true, message: 'Please select currencies' }]}
                    className="account-pupose-form">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', backgroundColor: 'none' }}
                        placeholder="Select currencies you operate with"
                        data-e2e="outBound-Select-currencies-you-operate-with"
                        className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                        {transactionsCurrenciesOptions.map(obj => (
                            <Option value={obj.value}>{obj.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="outboundJurisdictions"
                    label="Top Jurisdictions you will send funds to"
                    className="account-pupose-form"
                    rules={[{ required: true, message: 'Please select a jurisdiction' }]}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select jurisdiction you operate with"
                        data-e2e="outBound-Select-jurisdiction-you-operate-with"
                        className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                        {countries.length > 0 ? (
                            countries.map((country, key) => (
                                <Option key={key} value={country.name}>
                                    {country.name}
                                </Option>
                            ))
                        ) : (
                            <Option value="">Loading...</Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="outboundEntities"
                    label="Top entities/individuals that will send funds into your Juno Money account"
                    rules={[{ required: true, message: 'Please enter entities or individuals' }]}
                    className="account-pupose-form">
                    <Input.TextArea
                        className="rounded-lg border-[2px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        placeholder="List full names with commas"
                        data-e2e="outBound-List-full-names-with-commas"
                        rows={3}
                    />
                </Form.Item>
                <p className="my-4 !font-inter font-medium text-lg leading-7 tracking-normal">FX</p>

                <Form.Item
                    name="fxTransactions"
                    label="Number of FX transactions per month"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="fx-Select transaction count"
                        placeholder="Select transaction count">
                        {getFormattedOptions(false).map(item => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="fxUsdValue"
                    label="Average USD value of each FX transaction"
                    rules={[{ required: true, message: 'Please select amount' }]}
                    className="account-pupose-form">
                    <Select
                        className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                        data-e2e="fx-select-amount"
                        placeholder="Select amount">
                        {getFormattedOptions(true).map(item => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="fxPairs"
                    label="Main FX Pairs"
                    rules={[{ required: true, message: 'Please select currencies' }]}
                    className="account-pupose-form">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select main FX pairs"
                        data-e2e="fx-select-main-fx-pairs"
                        className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                        {fxPairsOptions.map(obj => (
                            <Option value={obj.value}>{obj.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    );
}

export default TransactionActivityForm;
