import { Checkbox, Form, Space } from 'antd';
import React from 'react';

const FormCheckbox = ({ name, valuePropName, value, onChange, checkboxTitle }) => {
    return (
        <div className="w-full sm:w-[320px] my-6">
            <Space>
                <Form.Item name={name} valuePropName={valuePropName} initialValue={value}>
                    <Checkbox
                        className="mt-[10px] text-sm lg:text-base"
                        checked={value}
                        onChange={onChange}>
                        {checkboxTitle}
                    </Checkbox>
                </Form.Item>
            </Space>
        </div>
    );
};

export default FormCheckbox;
