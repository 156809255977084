import { useSelector } from 'react-redux';
import { CryptoSelectModal } from './CryptoSelectModal';
import React, { useEffect, useState } from 'react';
import apiRequest from '../../utils/api';
import { Button, message, Modal, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CopyButton } from '../../utils/common';
import CryptoAddressConfirmationModal from './CryptoAddressConfirmationModal';
import { USDTBlockchainOptions } from '../../utils/constant';

const initialFormData = {
    cryptoId: 'BTC',
    nickName: '',
    address: '',
    network: '',
};

export const CryptoAddresseModal = ({
    onBack,
    open,
    getCryptoAddress,
    addressData,
    addressActions,
    selectedCryptoAddress,
}) => {
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const { clientData } = useSelector(state => state.account);
    const email = localStorage.getItem('email');
    const [formData, setFormData] = useState(initialFormData);
    const [isConfirm, setIsConfirm] = useState(false);
    const [walletError, setWalletError] = useState(null);

    useEffect(() => {
        if (addressData && addressActions?.edit) {
            setFormData({
                cryptoId: selectedCryptoAddress?.cryptoId || 'BTC',
                nickName: selectedCryptoAddress?.nickName || '',
                address: selectedCryptoAddress?.address || '',
                network: selectedCryptoAddress?.blockchain || '',
            });
        } else {
            setFormData(initialFormData);
        }
    }, [addressData, addressActions, selectedCryptoAddress]);

    const validateWalletAddress = async () => {
        try {
            const selectedCrypto = balancesCrypto.find(
                crypto => crypto?.currencyShortName === formData.cryptoId,
            );

            const body = {
                email,
                crypto: formData.cryptoId,
                blockchain: formData.network || selectedCrypto?.blockchain,
                walletAddress: formData.address,
                ...(selectedCrypto?.walletNetwork && { network: selectedCrypto.walletNetwork }),
            };

            const validWalletResponse = await apiRequest('/validate-wallet-address', 'POST', body);

            if (validWalletResponse.success) {
                if (validWalletResponse?.data?.error) {
                    setWalletError(validWalletResponse?.data?.message?.message);
                    return;
                }

                setWalletError(null);

                if (addressActions?.edit) {
                    setIsConfirm(true);
                } else {
                    saveNewAddress();
                }
            } else {
                message.error(validWalletResponse?.error || 'Validation failed');
            }
        } catch (error) {
            console.log('error', error);
            message.error(error.message || error.msg || 'Validation failed');
        }
    };

    const handleApiRequest = async (url, body, successMessage) => {
        try {
            const response = await apiRequest(url, 'POST', body);
            if (response.success) {
                message.success(successMessage);
                resetForm();
                getCryptoAddress();
                onBack();
            } else {
                message.error(response.error);
            }
        } catch (error) {
            message.error(error.message || 'An error occurred');
        }
    };

    const saveNewAddress = async () => {
        const blockchain = balancesCrypto?.find(
            crypto => crypto?.currencyShortName === formData.cryptoId,
        )?.blockchain;

        const body = {
            email: email,
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
            blockchain: formData.network || blockchain,
        };

        handleApiRequest('/save-crypto-address', body, 'Address added successfully');
    };

    const updateAddress = async () => {
        const blockchain = balancesCrypto?.find(
            crypto => crypto?.currencyShortName === formData.cryptoId,
        )?.blockchain;

        const body = {
            email: email,
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
            addressId: selectedCryptoAddress._id,
            blockchain: formData.network || blockchain,
        };

        handleApiRequest('/update-crypto-address', body, 'Address updated successfully');
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setWalletError(null);
    };

    return (
        <>
            <Modal
                className="sell-crypto-modal common-mobile-view "
                width={570}
                centered
                title={
                    <div className="flex items-center">
                        <ArrowLeftOutlined
                            className="text-xl mr-[12px] w-6 h-6"
                            onClick={() => {
                                onBack();
                                setWalletError(null);
                            }}
                        />
                        <p className="text-2xl font-semibold relative flex">
                            {addressActions?.add ? 'Add Address' : 'Edit Address'}
                        </p>
                    </div>
                }
                open={(open && addressActions?.add) || addressActions?.edit}
                onCancel={() => {
                    onBack();
                    setWalletError(null);
                }}
                handleClose={() => {
                    onBack();
                    setWalletError(null);
                }}
                footer={
                    <div className="flex justify-end w-full mt-11">
                        <Button
                            key="address"
                            type="primary"
                            onClick={() => {
                                validateWalletAddress();
                            }}
                            className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                            {addressActions?.edit ? `Save address` : `Add Address`}
                        </Button>
                    </div>
                }>
                <>
                    <div className="mt-8 grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Select crypto:
                        </h1>
                        <div className="col-span-2">
                            <CryptoSelectModal
                                value={formData.cryptoId}
                                onChange={e => setFormData({ ...formData, cryptoId: e })}
                                options={balancesCrypto}
                                className="!rounded-l-3xl"
                            />
                        </div>
                    </div>
                    {formData.cryptoId === 'USDT' && (
                        <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                            <label className="text-base font-normal text-[#26272B] col-span-1">
                                Network:
                            </label>
                            <Select
                                value={formData?.network}
                                placeholder="Select network"
                                className="bg-input max-sm:col-span-3 col-span-2 max-sm:mt-2 rounded-3xl h-14 address-select pl-2"
                                onChange={value => setFormData({ ...formData, network: value })}>
                                <Option value="" disabled>
                                    Select network
                                </Option>
                                {USDTBlockchainOptions.map(option => (
                                    <Option key={option.key} value={option.value}>
                                        {option.key}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Nickname:
                        </h1>
                        <input
                            type="text"
                            placeholder="Create address nickname"
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    nickName: e.target.value,
                                })
                            }
                            value={formData.nickName}
                            className="bg-input col-span-2 rounded-3xl w-full py-[17px] pl-6"
                        />
                    </div>
                    <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Wallet Address:
                        </h1>
                        <div className="col-span-2 bg-input border-[#D1D1D6] rounded-3xl py-[17px] pl-6 overflow-hidden">
                            <input
                                type="text"
                                placeholder="Enter wallet address"
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        address: e.target.value,
                                    })
                                }
                                value={formData.address}
                                className="bg-input w-10/12"
                            />
                            <CopyButton msg={formData.address} />
                        </div>
                    </div>
                    {walletError && (
                        <div className="jncAlert alert" role="alert">
                            {walletError}
                        </div>
                    )}
                </>
            </Modal>
            <CryptoAddressConfirmationModal
                open={isConfirm && addressActions.edit}
                onCancel={() => setIsConfirm(false)}
                data={formData.address}
                handleConfirm={() => {
                    updateAddress();
                    setIsConfirm(false);
                }}
                handleClose={() => setIsConfirm(false)}
            />
        </>
    );
};
