import React, { useState } from 'react';
import { DeleteOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { documentsFeilds } from '../../utils/constant';
import { Tooltip } from 'antd';

const BusinessDocumentation = ({ attachments, setAttachments, setAttachmentsError }) => {
    const handleDeleteBusinessDocument = async (label, fileName) => {
        const field = documentsFeilds.find(field => field.label === label);
        if (!field) return;

        try {
            setAttachments(prevAttachments => {
                return prevAttachments
                    .map(attachment => {
                        if (attachment.label === label) {
                            return {
                                ...attachment,
                                files: attachment.files.filter(file => file.name !== fileName),
                            };
                        }
                        return attachment;
                    })
                    .filter(attachment => attachment.files.length > 0);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleFile = (e, label) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return;

        setAttachmentsError('');

        setAttachments(prevAttachments => {
            const updatedAttachments = prevAttachments.map(attachment => {
                if (attachment.label === label) {
                    const existingFiles = attachment.files || [];
                    const newFiles = Array.from(files).filter(file => {
                        if (file.size > 4 * 1024 * 1024) {
                            setAttachmentsError('File size exceeds the limit (4 MB)');
                            setTimeout(() => setAttachmentsError(''), 2000);
                            return false;
                        }

                        if (existingFiles.some(existingFile => existingFile.name === file.name)) {
                            setAttachmentsError(`File "${file.name}" is already uploaded`);
                            setTimeout(() => setAttachmentsError(''), 5000);
                            return false;
                        }

                        return true;
                    });

                    return {
                        ...attachment,
                        files: [...existingFiles, ...newFiles],
                    };
                }
                return attachment;
            });

            const labelExists = updatedAttachments.some(attachment => attachment.label === label);
            if (!labelExists) {
                updatedAttachments.push({ label, files: Array.from(files) });
            }

            return updatedAttachments;
        });
    };

    const UploadInput = ({ label, toolTipText }) => {
        const attachment = attachments?.find(item => item?.label === label) || {};

        const currentFiles = [...(attachment?.files || [])];

        const handleClick = e => {
            e.preventDefault();
            document.getElementById(`file-input-${label}`).click();
        };

        return (
            <div>
                <p className="text-sm font-normal mb-[6px] text-gray-600 font-inter">
                    <span>{label}:</span>
                    {toolTipText && (
                        <Tooltip title={toolTipText} placement="right">
                            <span className="ml-1 cursor-pointer self-center">
                                <QuestionCircleFilled
                                    color="#70707B"
                                    sx={{
                                        color: '#70707B',
                                        fontSize: 16,
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )}
                </p>
                <div className="rounded-lg border-[1px] border-[#D1D1D6] px-[14px] py-[10px]">
                    <label htmlFor={`file-input-${label}`} className="w-full cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <input
                                type="file"
                                id={`file-input-${label}`}
                                onChange={e => handleFile(e, label)}
                                multiple
                                className="hidden"
                            />
                            <button
                                type="button"
                                className="bg-gray-200 px-2 py-[6px] text-sm border rounded-md border-gray-700"
                                onClick={handleClick}>
                                Choose file
                            </button>
                            <span className="text-sm text-gray-900">
                                {currentFiles?.length > 0
                                    ? `${currentFiles.length} file(s) selected`
                                    : 'No file chosen'}
                            </span>
                        </div>
                    </label>
                </div>
                {currentFiles?.length > 0 && (
                    <div className="my-[12px]">
                        <p className="text-sm font-normal text-[#202939] pb-[6px] border-b-[1px] border-[#CDD5DF]">
                            File/Attachment name
                        </p>
                        {currentFiles.map((file, index) => (
                            <div
                                key={index}
                                className="my-[12px] flex justify-between border-b-[1px] border-[#CDD5DF] pb-[6px]">
                                <p className="text-[#0E7090] text-sm font-medium">{file?.name}</p>
                                <span
                                    onClick={() => handleDeleteBusinessDocument(label, file?.name)}>
                                    <DeleteOutlined className="cursor-pointer text-lg" />
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="lg:mt-[20px] lg:h-[50vh] px-[7px] overflow-y-auto hide-scrollbar modal-form registeration-form">
                <div className="flex flex-col gap-3">
                    {documentsFeilds.map(item => {
                        return <UploadInput label={item.label} toolTipText={item?.toolTipText} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default BusinessDocumentation;
