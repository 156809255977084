import React, { useState, useEffect } from 'react';
import {
    Badge,
    Button,
    Divider,
    Form,
    Input,
    Space,
    Table,
    Tag,
    Pagination,
    Spin,
    Select,
    message,
    Tooltip,
} from 'antd';
import Sidebar from '../components/layout/Sidebar';
import {
    SearchOutlined,
    PlusOutlined,
    RightOutlined,
    DownOutlined,
    UpOutlined,
    LeftOutlined,
    CheckOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Download, Filter, MobileFilter, Fiat, Crypto, Flag, Info } from '../utils/Images';
import CustomPagination from '../components/layout/CustomPagination';
import TransactionFilterDrawer from '../components/modals/TransactionFilterDrawer';
import CommonModal from '../components/modals/CommonModal';
import {
    cryptoOptions,
    currencyTypes,
    fiatOptions,
    navbarItems,
    transactionStatusGroup,
    transactionTypes,
} from '../utils/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import apiRequest from '../utils/api';
import useDebounce from '../utils/useDebounce';
import moment from 'moment';
import {
    getBalancesData,
    getBalanceSign,
    getBeneficiaryList,
    getCurrencySymbol,
    getStatusComponent,
    toFixedTrunc,
    useCommonFunctions,
} from '../utils/common';
import DownloadCsvModal from '../components/modals/DownloadCsvModal';
import TransferModal from '../components/modals/TransferModal';
import FXFiatModal from '../components/modals/FXFiatModal';
import DepositFiatModal from '../components/modals/DepositFiatModal';
import WithdrawalAndTPPFiatModal from '../components/modals/WithdrawalAndTPPFiatModal';
import { singleClientData } from '../redux/actions/accountActions';
import { FormInput } from '../components/forms';
import ThemeButton from '../components/common/ThemeButton';
import CryptoTransactionModal from '../components/modals/CryptoTransactionModal';
import MerchantTransfer from '../components/modals/MerchantTransfer';
import TransactionSummary from '../components/TransactionSummary';
const { Option } = Select;

const Transactions = () => {
    const location = useLocation();
    const [state, setState] = useState(location.state || {});

    const getInitialFilterQuery = () => {
        let initialQuery = [];
        if ((state?.transactionType && state?.transactionStatus) || state?.houseKeeperStatus) {
            initialQuery.push(
                {
                    type: 'transactionType',
                    label: 'Transaction Type',
                    value: state?.transactionType,
                },
                // {
                //     type: 'currencyType',
                //     label: 'White Label',
                //     value:
                //         state.currencyType === 'all'
                //             ? currencyTypes.map(item => item.value)
                //             : [state.currencyType],
                // },
                {
                    type: 'status',
                    label: 'Status Group',
                    value:
                        state?.transactionStatus === 'totalTransactions'
                            ? transactionStatusGroup.map(item => item?.value)
                            : Array.isArray(state?.transactionStatus)
                              ? state?.transactionStatus
                              : [state?.transactionStatus],
                },
                {
                    type: 'dateFilter',
                    label: 'Date Filter',
                    value: state?.dashboardFilters?.date?.[0],
                    startDate: state?.dashboardFilters?.startDate,
                    endDate: state?.dashboardFilters?.endDate,
                },
                {
                    type: 'accountType',
                    label: 'Account Type',
                    value: state?.dashboardFilters?.accountType || 'All',
                },
            );
        }

        if (state?.clientId) {
            initialQuery.push({
                type: 'clientId',
                label: 'Client ID',
                value: state?.clientId,
            });
        }
        if (state?.houseKeeperStatus) {
            initialQuery = initialQuery.filter(filter => filter.type !== 'status');

            initialQuery.push({
                type: 'houseKeeperStatus',
                label: 'HouseKeeping Status',
                value: state?.houseKeeperStatus,
                startDate: state.dashboardFilters.startDate,
                endDate: state.dashboardFilters.endDate,
            });
        }

        return initialQuery;
    };

    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const {
        balancesData,
        savedBeneficiaryAccounts: beneficiaryList,
        clientData: accountData,
    } = useSelector(state => state?.account);
    const { getClientFeeSetting, getUserOnboardingStatus } = useCommonFunctions();
    const [filterModal, setFilterModal] = useState(false);
    const [addTransactionModal, setAddTransactionModal] = useState(false);
    const [createTransactionModal, setCreateTransactionModal] = useState(false);
    const [cryptoTransactionModal, setCryptoTransactionModal] = useState(false);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [searchText, setSearchText] = useState(state ? state?.clientName : '');
    const [orderBy, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('status.updated');
    const [data, setData] = useState([]);
    const [downloadCsv, setDownloadCsv] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [csvModal, setCsvModal] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const [filterQuery, setFilterQuery] = useState(getInitialFilterQuery);
    const [enableSearch, setEnableSearch] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [searchClientName, setSearchClientName] = useState();
    const [fetching, setFetching] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState(balancesData[0]);
    const [unfreezeBalances, setUnfreezeBalances] = useState([]);
    const [showMore, setShowMore] = useState('Show More');
    const [totalUsd, setTotalUsd] = useState(0);
    const [firstTransactionFromDate, setFirstTransactionFromDate] = useState('');
    const [cryptoAmount, setCryptoAmount] = useState(null);
    const [totalFee, setTotalFee] = useState(0);

    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');
    const isUserOnboarded = getUserOnboardingStatus(selectedClient);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const page = 1;

    const getClientsDetails = async () => {
        const body = {
            email: email,
            clientId: selectedClient?.clientId,
        };
        try {
            const response = await apiRequest('/get-single-client-data', 'POST', body);

            if (response.success) {
                if (response.data) {
                    dispatch(singleClientData(response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const filteredBalances = balancesData.filter(balance => !balance.isFreeze);
        setUnfreezeBalances(filteredBalances);
    }, [balancesData]);

    useEffect(() => {
        setSelectedCurrency(unfreezeBalances[0]);
    }, [unfreezeBalances]);

    useEffect(() => {
        const isSelectedClient = Object.keys(selectedClient).length > 0;
        if (isSelectedClient) {
            getClientsDetails();
        }
    }, [selectedClient]);

    useEffect(() => {
        if (Object.keys(accountData).length > 0) {
            getBalancesData(
                dispatch,
                accountData?.clientId,
                email,
                accountData?.clientEmail,
                setBalanceLoading,
            );
            getBeneficiaryList(dispatch, email, accountData?.clientEmail);
        }
    }, [accountData]);

    useEffect(() => {
        setSelectedCurrency(balancesData?.[0]);
    }, [balancesData]);

    useEffect(() => {
        getAllTransactions(sortBy, orderBy, 1, searchText);
    }, [filterQuery, downloadCsv]);
    useEffect(() => {
        fetchClients('', setClientData);
    }, []);
    useEffect(() => {
        Object.keys(selectedClient).length != 0 &&
            getBalancesData(
                dispatch,
                selectedClient?.clientId,
                email,
                selectedClient?.clientEmail,
                setBalanceLoading,
            );
    }, [selectedClient]);

    const searchTransaction = useDebounce(value => {
        getAllTransactions(sortBy, orderBy, page, value);
    }, 500);

    const getFilterDisplayValue = value => {
        const selectedValue =
            filterQuery.length > 0 ? filterQuery.find(item => item.type === value) : null;

        if (!selectedValue) return 'All';

        const valueMappings = {
            transactionType: transactionTypes.length,
            currencyType: currencyTypes.length,
            status: transactionStatusGroup.length,
            fiatCurrency: fiatOptions.length,
            cryptoCurrency: cryptoOptions.length,
        };

        const lengthToCompare = valueMappings[value];

        if (selectedValue.value.length === 0 || selectedValue.value.length === lengthToCompare) {
            return 'All';
        } else {
            return selectedValue.value;
        }
    };

    const getClientIdFilterValue = () => {
        const clientIdFilter = filterQuery.find(item => item.type === 'clientId');

        if (!clientIdFilter || clientIdFilter.value.length === 0) {
            return undefined;
        }

        return clientIdFilter.value;
    };

    const getAllTransactions = async (
        sort = sortBy || 'status.updated',
        order = orderBy || 'desc',
        page,
        searchText = '',
    ) => {
        let pageNo = 1;
        if (page) {
            pageNo = page;
        }
        let body = {
            email: email,
            page: pageNo,
            sortBy: sort,
            orderBy: order,
            whiteLabelId,
            client: getClientIdFilterValue(),
            transactionId: searchText.toString()
                ? searchText.toString()
                : getFilterDisplayValue('transactionId'),
            clientName: searchText ? [searchText] : '',
            type: getFilterDisplayValue('transactionType'),
            name: searchText.toString(),
            clientId: searchText.toString(),
            statusGroup: getFilterDisplayValue('status'),
            FiatCurrency: getFilterDisplayValue('fiatCurrency'),
            currencyType: getFilterDisplayValue('currencyType'),
            sender: getFilterDisplayValue('sender'),
            receiver: getFilterDisplayValue('receiver'),
            cryptoCurrency: getFilterDisplayValue('cryptoCurrency'),
            accountType: getFilterDisplayValue('accountType'),
            houseKeeperStatus: getFilterDisplayValue('houseKeeperStatus'),
        };

        const activeFilter = filterQuery.find(item => item.type === 'active');
        if (activeFilter?.value?.includes('true') && activeFilter?.value?.includes('false')) {
            body.active = 'All';
        } else if (activeFilter?.value?.includes('true')) {
            body.active = true;
        } else if (activeFilter?.value?.includes('false')) {
            body.active = false;
        }

        //Handle date filters
        const dateUpdatedFilter = filterQuery.find(item => item.type === 'dateUpdated');
        const { startDate, endDate } = state?.dashboardFilters || {};

        if (dateUpdatedFilter?.value) {
            body.fromDateUpdated = moment(dateUpdatedFilter.value[0].$d)
                .startOf('day')
                .format('YYYY-MM-DD 00:00');
            body.toDateUpdated = moment(dateUpdatedFilter.value[1].$d)
                .endOf('day')
                .format('YYYY-MM-DD 23:59');
        } else if (startDate && endDate) {
            if (startDate !== 'all') body.fromDateUpdated = startDate;
            body.toDateUpdated = endDate;
        } else if (filterQuery.find(item => item.type === 'clientId')) {
            body.toDateUpdated = moment.utc().endOf('day').format('YYYY-MM-DD 23:59');
        } else {
            body.fromDateUpdated = moment
                .utc()
                .subtract(28, 'days')
                .startOf('day')
                .format('YYYY-MM-DD 00:00');
            body.toDateUpdated = moment.utc().endOf('day').format('YYYY-MM-DD 23:59');
        }

        const amountFilter = filterQuery.find(item => item.type === 'amountRange');
        if (amountFilter && amountFilter.value) {
            body.fromAmountRange = amountFilter.from;
            body.toAmountRange = amountFilter.to;
        } else {
            body.fromAmountRange = 'All';
        }

        if (downloadCsv) {
            delete body.page;
            body = {
                ...body,
                findAll: true,
            };
        }

        setLoading(true);
        try {
            const transactionPromise = apiRequest('/get-all-client-transactions', 'POST', body);
            const summaryPromise = apiRequest('/get-client-transaction-summary', 'POST', body);
            const cryptoSummaryPromise = apiRequest(
                '/get-crypto-client-transaction-summary',
                'POST',
                body,
            );
            const response = await transactionPromise;
            if (response.success || response.data) {
                if (downloadCsv) {
                    setCsvData(response.data.transactionsList);
                    setCsvModal(true);
                    setDownloadCsv(false);
                } else {
                    setData(response.data.transactionsList);
                    setPagination(prev => ({
                        ...prev,
                        total: response.data.totalCount,
                    }));
                }
            }
            setLoading(false);

            const summaryResponse = await summaryPromise;
            const cryptoSummaryResponse = await cryptoSummaryPromise;
            if (summaryResponse?.success && cryptoSummaryResponse?.success) {
                setTotalUsd(
                    summaryResponse?.data?.totalUsd + cryptoSummaryResponse?.data?.totalUsd,
                );
                setTotalFee(
                    (summaryResponse?.data?.totalFee || 0) +
                        (cryptoSummaryResponse?.data?.totalFee || 0),
                );
                setFirstTransactionFromDate(summaryResponse?.data?.firstTransactionFromDate);
                setCryptoAmount(cryptoSummaryResponse?.data?.cryptoTotalByCurrency);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleTableChange = (newPagination, _, sorter) => {
        const { current } = newPagination;
        const { columnKey, order } = sorter;

        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));

        const sortFieldMap = {
            fromAmount: 'fromAmount',
            createdDate: 'createdDate',
            updatedDate: 'status.updated',
        };

        const sortOrderMap = {
            ascend: 'asc',
            descend: 'desc',
        };

        let sortBy = order ? sortFieldMap[columnKey] : 'status.updated';
        let orderBy = sortOrderMap[order] || 'desc';

        setSortBy(sortBy);
        setOrderBy(orderBy);

        getAllTransactions(sortBy, orderBy, current, searchText);
    };

    const renderTableData = (row, key) => {
        const emptyState = <div className="pl-5">--</div>;

        switch (key) {
            case 'type':
                return `${row?.type} ${row?.transferInOut ? `(${row.transferInOut})` : ''}`;
            case 'createdDate':
                return row ? moment(row).format('DD.MM.YYYY HH:mm:ss.SSS') : emptyState;
            case 'fromAmount':
                if (row && row.transactionDetails && row.transactionDetails.length > 0) {
                    const transaction = row.transactionDetails[0];

                    if (row.type === 'FX') {
                        const fromCurrencySymbol = getCurrencySymbol(transaction.fromCurrency);
                        const fromAmount = toFixedTrunc(transaction.fromAmount);
                        const toCurrencySymbol = getCurrencySymbol(transaction.toCurrency);
                        const toAmount = toFixedTrunc(transaction.toAmount);

                        return `${fromCurrencySymbol} ${fromAmount} - ${toCurrencySymbol} ${toAmount}`;
                    } else if (row.type === 'Exchange') {
                        const fromCurrencySymbol = getCurrencySymbol(transaction.fromCryptoId);
                        const fromAmount = transaction.fromCryptoAmount;
                        const toCurrencySymbol = getCurrencySymbol(transaction.toCryptoId);
                        const toAmount = transaction.toCryptoAmount;

                        return `${fromCurrencySymbol} ${fromAmount} - ${toCurrencySymbol} ${toAmount}`;
                    } else {
                        const balanceSign = getBalanceSign(row);
                        const currencySymbol = getCurrencySymbol(
                            transaction.currency || transaction.cryptoId,
                        );
                        const amount = transaction.amount || transaction.cryptoAmount;

                        return `${balanceSign} ${currencySymbol} ${amount}`;
                    }
                } else return 'N/A';
            case 'toAmount':
                if (row && row.transactionDetails && row.transactionDetails.length > 0) {
                    const transaction = row.transactionDetails[0];

                    if (row.type === 'FX') {
                        const toCurrencySymbol = getCurrencySymbol(transaction.toCurrency);
                        const toAmount = toFixedTrunc(transaction.toAmount);
                        return `+ ${toCurrencySymbol} ${toAmount}`;
                    } else {
                        const balanceSign = getBalanceSign(row);
                        const currencySymbol = getCurrencySymbol(
                            transaction.currency || transaction.cryptoId,
                        );
                        const amount = toFixedTrunc(
                            transaction.amount || transaction.cryptoAmount,
                            6,
                        );
                        return `${balanceSign} ${currencySymbol} ${amount}`;
                    }
                } else return 'N/A';
            case 'fromCurrency':
                if (row && row.transactionDetails && row.transactionDetails.length > 0) {
                    const transaction = row.transactionDetails[0];
                    let currency = 'N/A';
                    if (row.type === 'Exchange') {
                        const fromCurrency = transaction.fromCryptoId;
                        const toCurrency = transaction.toCryptoId;
                        return `${fromCurrency} - ${toCurrency}`;
                    } else if (row.type === 'FX') {
                        const fromCurrency = transaction.fromCurrency;
                        const toCurrency = transaction.toCurrency;
                        return `${fromCurrency} - ${toCurrency}`;
                    } else {
                        currency = transaction.currency || transaction.cryptoId;
                    }
                    return `${currency}`;
                } else return 'N/A';
            case 'toCurrency':
                if (row && row.transactionDetails && row.transactionDetails.length > 0) {
                    const transaction = row.transactionDetails[0];
                    let currency = 'N/A';
                    if (row.type === 'Exchange') {
                        currency = transaction.toCryptoId;
                    } else if (row.type === 'FX') {
                        currency = transaction.toCurrency;
                    } else {
                        currency = transaction.currency || transaction.cryptoId;
                    }
                    return `${currency}`;
                } else return 'N/A';
            case 'receiverName':
                if (row && row.transactionDetails && row.transactionDetails.length > 0) {
                    const transaction = row.transactionDetails[0];
                    const emptyState = <div className="flex justify-center">-</div>;
                    if (row.type === 'Withdrawal' || row.type === 'Payment Out') {
                        return transaction?.beneficiaryName !== ''
                            ? transaction.beneficiaryName
                            : emptyState;
                    } else if (row.type === 'Transfer') {
                        return transaction?.accountName !== ''
                            ? transaction.accountName
                            : emptyState;
                    } else {
                        return row?.clientRef?.name;
                    }
                } else return 'N/A';
            case 'currencyType':
                return row?.currencyType === 'fiat' ? `Juno Money` : 'Juno X';
            case 'active':
                if (row && row.clientRef) {
                    return (
                        <span
                            className={`px-2 py-1 rounded-full ${
                                row.clientRef?.active
                                    ? 'bg-green-100 text-green-600'
                                    : 'bg-gray-100 text-gray-600'
                            }`}>
                            {row.clientRef?.active ? 'Active' : 'Inactive'}
                        </span>
                    );
                } else return 'N/A';
            case 'intiator':
                if (row && row.clientRef) {
                    return (
                        <div className="bg-[#F4F4F5] w-[30px] h-[30px] flex items-center justify-center rounded-full text-center text-xs font-medium text-[#3F3F46] capitalize">
                            {row?.clientRef?.name[0]}
                        </div>
                    );
                } else return 'N/A';
            case 'transactionFee':
                const fee = row?.transactionFee ?? row?.transferInFee;
                const feeRoundoff = row.currencyType === 'crypto' ? 6 : 2;

                return fee ? toFixedTrunc(fee, feeRoundoff) : '-';
            default:
                return row ?? emptyState;
        }
    };

    const handlePagination = page => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
        }));
        getAllTransactions(sortBy, orderBy, page);
    };

    function getFailedRuleResults(data) {
        if (!data?.result?.rule_results || data.result.have_all_passed) {
            return 0;
        }
        return data.result.rule_results.filter(ruleResult => !ruleResult.has_passed).length;
    }

    const renderAlerts = alertData => {
        return alertData?.alerts?.map(alert => {
            return (
                <div className="my-2">
                    <p className="font-bold">Name : {alert?.rule?.name}</p>
                    <p>Status : {alert?.action?.label}</p>
                </div>
            );
        });
    };
    const columns = [
        {
            title: 'Type',
            key: 'type',
            render: row => renderTableData(row, 'type'),
        },
        {
            title: 'Client Name',
            render: row => renderTableData(row?.clientRef?.name),
            key: 'clientName',
        },
        {
            title: 'Account Name',
            render: row => renderTableData(row?.transactionDetails?.[0]?.accountName),
            key: 'accountName',
        },
        {
            title: 'Beneficiary Name',
            render: row => renderTableData(row?.transactionDetails?.[0]?.beneficiaryName),
            key: 'beneficiaryName',
        },
        {
            title: 'Remitter Name',
            render: row => renderTableData(row?.transactionDetails?.[0]?.remitterName),
            key: 'remitterName',
        },
        // {
        //     title: 'White Label',
        //     dataIndex: 'currencyType',
        //     render: row => (row === 'fiat' ? 'Juno Money' : 'Juno X'),
        //     key: 'currencyType',
        // },
        {
            title: 'Date Created',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: createdDate => (
                <div className="xl:w-32 w-24">{renderTableData(createdDate, 'createdDate')}</div>
            ),
            sorter: true,
        },
        {
            title: 'Date Updated',
            dataIndex: 'status',
            key: 'updatedDate',
            render: status => (
                <div className="xl:w-32 w-24">
                    {renderTableData(status?.updated, 'createdDate')}
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Flag',
            key: 'flag',
            render: row => (
                <div className="xl:w-32 w-20 text-center bg-[#FEF3F2] flex items-center justify-center gap-2 p-1 text-[#912018] font-medium rounded-full">
                    <div className="">
                        {getFailedRuleResults(row?.complyAdvantageCheck)}{' '}
                        <img src={`${imageBaseUrl}${Flag}`} alt="flag" className="w-3 h-3 inline" />
                    </div>
                    <Tooltip
                        title={
                            <div className="max-w-[520px]">
                                Comply Advantage alerts <br />
                                {renderAlerts(row?.complyAdvantageCheck?.amlAlerts)}
                            </div>
                        }
                        className="mr-3"
                        overlayInnerStyle={{
                            maxWidth: '550px',
                            overflow: 'auto',
                            maxHeight: '500px',
                        }}>
                        <img
                            src={`${imageBaseUrl}${Info}`}
                            alt="info"
                            className="w-5 h-5 mr-0 rounded-full bg-white cursor-pointer"
                        />
                    </Tooltip>
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, row) => (
                <div className="flex justify-between items-center gap-2">
                    {getStatusComponent(status.code, row)}
                    {row?.housekeeping &&
                        (row.housekeeping.status === 'Unmatched' ? (
                            <WarningOutlined className="text-yellow-500" />
                        ) : (
                            <CheckOutlined className="text-green-500" />
                        ))}
                </div>
            ),
            key: 'status',
        },
        {
            title: 'Amount',
            key: 'fromAmount',
            render: row => renderTableData(row, 'fromAmount'),
            sorter: true,
        },
        {
            title: 'Currency',
            key: 'fromCurrency',
            render: row => renderTableData(row, 'fromCurrency'),
        },
        {
            title: 'Fee',
            key: 'transactionFee',
            render: row => renderTableData(row, 'transactionFee'),
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            render: transactionId => <div className="w-28">{transactionId}</div>,
        },
        {
            title: 'Client ID',
            dataIndex: 'clientId',
            key: 'clientId',
        },
        {
            title: 'Client Email',
            render: row => renderTableData(row?.clientRef?.clientEmail),
            key: 'email',
        },
        {
            render: row => (
                <div className="flex items-center justify-between gap-5">
                    <RightOutlined />
                </div>
            ),
        },
    ];

    const ExpandedRow = ({ record }) => {
        return (
            <div className="p-4 bg-[#FCFCFC] border rounded-lg">
                {[
                    { label: 'Type', value: renderTableData(record, 'type') },
                    { label: 'Client Name', value: renderTableData(record.clientRef?.name) },
                    {
                        label: 'Account Name',
                        value: renderTableData(record?.transactionDetails?.[0]?.accountName),
                    },
                    {
                        label: 'Beneficiary Name',
                        value: renderTableData(record?.transactionDetails?.[0]?.beneficiaryName),
                    },
                    {
                        label: 'Remitter Name',
                        value: renderTableData(record?.transactionDetails?.[0]?.remitterName),
                    },
                    // {
                    //     label: 'White Label',
                    //     value: renderTableData(record, 'currencyType'),
                    // },
                    {
                        label: 'Date Created',
                        value: renderTableData(record.createdDate, 'createdDate'),
                    },
                    {
                        label: 'Date Updated',
                        value: renderTableData(record.status?.updated, 'createdDate'),
                    },
                    {
                        label: 'Status',
                        value: (
                            <div className="flex items-center gap-2">
                                {getStatusComponent(record.status.code, record)}
                                {record?.housekeeping &&
                                    (record.housekeeping.status === 'Unmatched' ? (
                                        <WarningOutlined className="text-yellow-500" />
                                    ) : (
                                        <CheckOutlined className="text-green-500" />
                                    ))}
                            </div>
                        ),
                    },
                    { label: 'Amount', value: renderTableData(record, 'fromAmount') },
                    { label: 'Currency', value: renderTableData(record, 'fromCurrency') },
                    { label: 'Fee', value: renderTableData(record, 'transactionFee') },
                    { label: 'Transaction ID', value: record.transactionId },
                    { label: 'Client ID', value: record.clientId },
                    {
                        label: 'Client Email',
                        value: renderTableData(record?.clientRef?.clientEmail),
                    },
                ].map(({ label, value }) => (
                    <p
                        key={label}
                        data-e2e={value}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-2">
                        <span>{label}:</span> {value}
                    </p>
                ))}
            </div>
        );
    };

    const handleExpandRow = id => {
        setExpandedRowKey(expandedRowKey === id ? null : id);
    };

    const handleQuery = async value => {
        const filterValue = [
            { type: 'transactionType', value: value.transactionType, label: 'Transaction Type' },
            { type: 'currencyType', value: value.currencyType, label: 'White Label' },
            { type: 'status', value: value.statusGroup, label: 'Status Group' },
            { type: 'fiatCurrency', value: value.fiatCurrency, label: 'Fiat Currency' },
            { type: 'cryptoCurrency', value: value.cryptoCurrency, label: 'Crypto ID' },
            {
                type: 'houseKeeperStatus',
                value: value.houseKeeperStatus,
                label: 'Housekeeper Status',
            },
            { type: 'active', value: value.active, label: 'Account Status' },
            { type: 'transactionId', value: value.transactionId, label: 'Transaction ID' },
            { type: 'dateFilter', value: value.dateFilter, label: 'Date Filter', date: 'filter' },
            {
                type: 'dateCreated',
                value: value.dateCreated,
                label: 'Date Created',
                date: 'filter',
            },
            {
                type: 'dateUpdated',
                value: value.dateUpdated,
                label: 'Date Updated',
                date: 'filter',
            },
            {
                type: 'amountRange',
                value: value.amountRange
                    ? `${value.amountRange?.from} - ${value.amountRange?.to}`
                    : '',
                label: 'Amount Range',
                to: value.amountRange?.to,
                from: value.amountRange?.from,
            },
            { type: 'sender', value: value.sender, label: 'Sender' },
            { type: 'receiver', value: value.receiver, label: 'Receiver' },
            { type: 'accountType', value: value.accountType, label: 'Account Type' },
            { type: 'clientId', value: getClientIdFilterValue(), label: 'Client ID' },
        ].filter(entry => entry.value && entry.value.length > 0);

        setFilterQuery(filterValue);

        setState(prevState => ({
            ...prevState,
            clientId: value.clientId ? value.clientId[0] : undefined,
            transactionType: value.transactionType ? value.transactionType : undefined,
            currencyType: value.currencyType ? value.currencyType[0] : undefined,
            transactionStatus: value.statusGroup ? value.statusGroup[0] : undefined,
            fiatCurrency: value.fiatCurrency ? value.fiatCurrency : undefined,
            cryptoCurrency: value.cryptoCurrency ? value.cryptoCurrency : undefined,
            active: value.active ? value.active : undefined,
            transactionId: value.transactionId ? value.transactionId : undefined,
            // dateFilter: value.dateFilter ? value.dateFilter : undefined,
            dateCreated: value.dateCreated ? value.dateCreated : undefined,
            dateUpdated: value.dateUpdated ? value.dateUpdated : undefined,
            amountRange: value.amountRange ? value.amountRange : undefined,
            sender: value.sender ? value.sender[0] : undefined,
            receiver: value.receiver ? value.receiver[0] : undefined,
        }));
    };

    const handleTagClose = removedTag => {
        const removedQuery = filterQuery.filter(tag => tag !== removedTag);
        setFilterQuery(removedQuery);

        setState(prevState => {
            const newState = { ...prevState };
            if (removedTag.type === 'clientId') delete newState.clientId;
            if (removedTag.type === 'transactionType') delete newState.transactionType;
            if (removedTag.type === 'currencyType') delete newState.currencyType;
            if (removedTag.type === 'statusGroup') delete newState.transactionStatus;
            if (removedTag.type === 'fiatCurrency') delete newState.fiatCurrency;
            if (removedTag.type === 'cryptoCurrency') delete newState.cryptoCurrency;
            if (removedTag.type === 'active') delete newState.active;
            if (removedTag.type === 'transactionId') delete newState.transactionId;
            if (removedTag.type === 'dateFilter') delete newState.dateFilter;
            if (removedTag.type === 'dateCreated') delete newState.dateCreated;
            if (removedTag.type === 'dateUpdated') delete newState.dateUpdated;
            if (removedTag.type === 'amountRange') delete newState.amountRange;
            if (removedTag.type === 'sender') delete newState.sender;
            if (removedTag.type === 'receiver') delete newState.receiver;

            return newState;
        });

        setPagination(prevPagination => ({
            ...prevPagination,
            current: 1,
        }));

        navigate('/transactions', { replace: true });
    };

    const clearQueryRender = () => {
        setFilterQuery([]);
        setState({});

        setPagination(prevPagination => ({
            ...prevPagination,
            current: 1,
        }));

        navigate('/transactions', { replace: true });
    };

    const FilterQuerySection = ({ color }) => {
        const formatDate = dateString => {
            if (!dateString || dateString === 'all') return dateString;
            const date = new Date(dateString);
            return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        };

        return (
            <div className="filter-tags overflow-hidden">
                {filterQuery.map((filter, key) => (
                    <Tag
                        bordered={false}
                        closable
                        key={key}
                        className={`text-[14px] transaction-tag flex font-medium ${color && '!bg-white'}`}
                        onClose={() => handleTagClose(filter)}>
                        <div className="flex">
                            {filter.label}
                            {filter.type === 'active' ? (
                                <p className="wrapped-text">
                                    {': '}
                                    {filter.value.includes('true') && filter.value.includes('false')
                                        ? 'All'
                                        : filter.value.includes('true')
                                          ? 'Active'
                                          : 'Inactive'}
                                </p>
                            ) : filter.value === 'range' ? (
                                <p className="wrapped-text">
                                    {': '}
                                    {filter?.startDate === 'all'
                                        ? filter.startDate
                                        : formatDate(filter?.startDate)}{' '}
                                    - {formatDate(filter?.endDate)}
                                </p>
                            ) : filter.date === 'filter' ? (
                                <p className="wrapped-text">
                                    {': '}
                                    {formatDate(filter?.value[0])} - {formatDate(filter?.value[1])}
                                </p>
                            ) : (
                                <p className="wrapped-text">
                                    {filter.value ? ': ' + filter.value : ''}
                                </p>
                            )}
                        </div>
                    </Tag>
                ))}

                {filterQuery.length > 0 && (
                    <div
                        className="text-[#1570EF] text-[14px] px-2 font-medium cursor-pointer"
                        onClick={clearQueryRender}>
                        Reset all ({filterQuery.length})
                    </div>
                )}
            </div>
        );
    };

    const handleDownloadCsvFile = () => {
        setDownloadCsv(true);
    };

    const handleCloseCsvModal = () => {
        setCsvModal(false);
        setCsvData([]);
    };

    const fetchClients = async value => {
        setFetching(true);
        let filterParams = {
            searchText: value,
            page: 1,
            sortBy: 'createdAt',
            orderBy: 'desc',
            limit: 200,
        };

        let payload = {
            email: email,
            filterParams: filterParams,
        };

        try {
            const res = await apiRequest('/get-clients', 'POST', payload);
            if (res.success) {
                setClientData(res.data.clientsData);
            }
            setFetching(false);
        } catch (err) {
            setFetching(false);
            console.log(err.message, 'Fetch Error');
        }
    };

    const handleSearch = useDebounce(value => {
        fetchClients(value);
    }, 500);

    const handleChange = newValue => {
        setSearchClientName(newValue);
    };

    const optionClick = item => {
        setSelectedClient(item);
        form.setFieldsValue({
            accountNickName: item.name,
            clientId: item.clientId,
        });
    };

    const createTransaction = async body => {
        try {
            const response = await apiRequest('/create-client-transaction-merchant', 'POST', body);
            if (response.success) {
                await getBalancesData(
                    dispatch,
                    accountData.clientId,
                    email,
                    accountData.clientEmail,
                    setBalanceLoading,
                );
                getAllTransactions();
                return { success: true };
            } else {
                message.error(response.error);
                return { error: response.error };
            }
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    const fetchFeeSetting = async modalType => {
        if (!modalType) return null;

        const transactionType = modalType === 'Third-party payment' ? 'Payment Out' : modalType;

        const payload = {
            email,
            clientId: selectedClient?.clientId,
            transactionType: transactionType,
            currencyType: 'fiat',
        };

        try {
            return await getClientFeeSetting(payload);
        } catch (error) {
            console.error('Error fetching fee setting:', error);
            return null;
        }
    };

    const openModalAction = modalType => {
        setIsModalOpen(true);
        setOpenModal(modalType);
        fetchFeeSetting(modalType);
        setAddTransactionModal(false);
    };

    const onModalClose = () => {
        setIsModalOpen(false);
        setOpenModal('');
        setSelectedClient({});
        setSearchClientName();
    };

    return (
        <Sidebar>
            <div className="lg:px-[24px] py-[16px]">
                <div className="">
                    {/* Desktop Screen */}
                    <div className="desktop-page-block hidden lg:flex flex-col p-6 bg-white rounded-t-xl">
                        <div className="flex items-center justify-between">
                            <p className="page-title">Transactions history</p>
                            <Space>
                                <Input
                                    placeholder="Search transaction ID, client name"
                                    data-e2e="search-box"
                                    suffix={<SearchOutlined />}
                                    className="w-full xl:w-[460px] p-2"
                                    name="searchText"
                                    value={searchText}
                                    onChange={e => {
                                        setSearchText(e.target.value);
                                        searchTransaction(e.target.value);
                                    }}
                                    allowClear
                                />
                                <ThemeButton
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    text="Create new transaction"
                                    dataE2e="create-new-transaction"
                                    action={() => setCreateTransactionModal(true)}
                                />
                                <Button
                                    className="!w-[40px] !h-[40px] bg-[#F4F4F5]"
                                    onClick={handleDownloadCsvFile}
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${Download}`}
                                            alt=""
                                            className="w-5 h-5 inline"
                                        />
                                    }></Button>
                                <Badge count={filterQuery.length} className="">
                                    <ThemeButton
                                        icon={
                                            <img
                                                src={`${imageBaseUrl}${Filter}`}
                                                alt=""
                                                className="w-3 h-3 inline cursor-pointer"
                                            />
                                        }
                                        text="Filters"
                                        dataE2e="filters"
                                        action={() => setFilterModal(true)}
                                    />
                                </Badge>
                                {/* TODO : WE can use that commented code in v2 */}

                                {/* <Button
                                    className="!w-[40px] !h-[40px]"
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${SettingIcon}`}
                                            alt=""
                                            className="w-5 h-5 inline"
                                        />
                                    }></Button> */}
                            </Space>
                        </div>
                        <div>
                            <TransactionSummary
                                totalCount={pagination?.total}
                                cryptoAmount={cryptoAmount}
                                totalUsd={totalUsd}
                                totalFee={totalFee}
                                fromDate={
                                    ((state?.dashboardFilters?.startDate === 'all' ||
                                        (filterQuery.find(item => item.type === 'clientId') &&
                                            !filterQuery.find(
                                                item => item.type === 'dateUpdated',
                                            ))) &&
                                        firstTransactionFromDate) ||
                                    filterQuery.find(item => item.type === 'dateUpdated')?.value[0]
                                        ?.$d ||
                                    state?.dashboardFilters?.startDate ||
                                    moment.utc().subtract(28, 'days')
                                }
                                toDate={
                                    state?.dashboardFilters?.endDate ||
                                    filterQuery.find(item => item.type === 'dateUpdated')?.value[1]
                                        ?.$d ||
                                    moment()
                                }
                                showMore={showMore}
                                setShowMore={setShowMore}
                            />
                        </div>
                        <div className="hidden lg:flex items-center gap-x-[8px] gap-y-[12px] mt-2">
                            <FilterQuerySection />
                        </div>
                    </div>
                    {/* Tablet & Mobile Screen */}
                    <div className="flex flex-col">
                        <div className="lg:hidden flex justify-between items-center px-3">
                            {enableSearch ? (
                                <Input
                                    placeholder="Search accounts"
                                    prefix={<LeftOutlined onClick={() => setEnableSearch(false)} />}
                                    suffix={<SearchOutlined />}
                                    className="sm:!w-[351px] md:!w-[696px] w-full p-2"
                                    name="searchText"
                                    value={searchText}
                                    onChange={e => {
                                        setSearchText(e.target.value);
                                        searchTransaction(e.target.value);
                                    }}
                                    allowClear
                                />
                            ) : (
                                <>
                                    <h2 className="text-base font-semibold pr-2">
                                        Transactions history
                                    </h2>
                                    <div className="flex items-center gap-5">
                                        <SearchOutlined
                                            className="cursor-pointer"
                                            onClick={() => setEnableSearch(true)}
                                        />
                                        <img
                                            src={`${imageBaseUrl}${MobileFilter}`}
                                            className="w-4 h-3 cursor-pointer"
                                            onClick={() => setFilterModal(true)}
                                            alt=""
                                        />
                                        <img
                                            src={`${imageBaseUrl}${Download}`}
                                            onClick={handleDownloadCsvFile}
                                            className=" cursor-pointer"
                                            alt=""
                                        />
                                        <PlusOutlined
                                            className="py-2 px-3 h-auto bg-primary text-white rounded-lg cursor-pointer"
                                            onClick={() => setCreateTransactionModal(true)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="lg:hidden block">
                            <TransactionSummary
                                cryptoAmount={cryptoAmount}
                                totalCount={pagination?.total}
                                totalUsd={totalUsd}
                                totalFee={totalFee}
                                fromDate={
                                    ((state?.dashboardFilters?.startDate === 'all' ||
                                        (filterQuery.find(item => item.type === 'clientId') &&
                                            !filterQuery.find(
                                                item => item.type === 'dateUpdated',
                                            ))) &&
                                        firstTransactionFromDate) ||
                                    filterQuery.find(item => item.type === 'dateUpdated')?.value[0]
                                        ?.$d ||
                                    state?.dashboardFilters?.startDate ||
                                    moment.utc().subtract(28, 'days')
                                }
                                toDate={
                                    state?.dashboardFilters?.endDate ||
                                    filterQuery.find(item => item.type === 'dateUpdated')?.value[1]
                                        ?.$d ||
                                    moment()
                                }
                                isMobile={true}
                                showMore={showMore}
                                setShowMore={setShowMore}
                            />
                            <div className="flex items-center gap-x-[8px] gap-y-[12px] py-[12px]">
                                {showMore === 'Show Less' && <FilterQuerySection color="white" />}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:block hidden bg-[#F7F7F7] h-full xl:h-[900px] w-full accounts-page">
                    <div className="bg-white rounded-lg">
                        <Table
                            dataSource={data}
                            columns={columns}
                            className="settings-table cursor-pointer border-[#E4E4E7] border-[1px] border-solid rounded-b-lg custom-table"
                            scroll={true}
                            rowKey={record => record._id}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: () =>
                                        window.open(
                                            `/transactions/${record.transactionId}`,
                                            '_blank',
                                        ),
                                    'data-e2e': `row-${rowIndex}`,
                                    'data-e2e-transaction': record.transactionId,
                                };
                            }}
                            loading={loading}
                            pagination={{
                                position: ['bottomCenter'],
                                itemRender: CustomPagination,
                                showSizeChanger: false,
                                current: pagination.current,
                                total: pagination.total,
                            }}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
                <div className="block lg:hidden">
                    {!loading ? (
                        <div className="">
                            {data.length > 0 &&
                                data.map((item, index) => {
                                    const isExpanded = expandedRowKey === item._id;
                                    return (
                                        <div
                                            key={item.clientId + '-' + index}
                                            className="bg-white border-b border-[#E4E4E7]">
                                            <div className="flex justify-between items-center p-3 cursor-pointer">
                                                <div
                                                    className="flex gap-2 items-center"
                                                    onClick={() =>
                                                        window.open(
                                                            `/transactions/${item.transactionId}`,
                                                            '_blank',
                                                        )
                                                    }>
                                                    <span className="bg-[#12B76A] inline-block h-2 w-2 rounded-full"></span>
                                                    <div>
                                                        <h1 className="text-sm font-medium text-[#18181B] pb-1">
                                                            {item.transactionId}
                                                        </h1>
                                                        <p className="font-normal text-xs text-darkGray">
                                                            {item.type}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex gap-2 items-center">
                                                    <div className="text-end">
                                                        <h1 className="text-sm font-medium pb-1 max-w-[120px]">
                                                            {renderTableData(item, 'fromAmount')}
                                                        </h1>
                                                        <p className="font-normal text-xs text-darkGray">
                                                            {item?.createdDate &&
                                                                moment(item.createdDate).format(
                                                                    'DD.MM.YYYY HH:mm:ss.SSS',
                                                                )}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className=""
                                                        onClick={() => handleExpandRow(item._id)}>
                                                        {isExpanded ? (
                                                            <UpOutlined width={10} height={10} />
                                                        ) : (
                                                            <DownOutlined width={10} height={10} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {isExpanded && (
                                                <div className="p-3">
                                                    <ExpandedRow record={item} />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            <Pagination
                                total={pagination.total}
                                responsive
                                className="py-3 flex justify-center bg-[#F5F5F5]"
                                current={pagination.current}
                                onChange={handlePagination}
                                showSizeChanger={false}
                            />
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-[50vh] ">
                            <Spin />
                        </div>
                    )}
                </div>
                <TransactionFilterDrawer
                    open={filterModal}
                    setOpen={setFilterModal}
                    handleSearch={handleQuery}
                    filterQuery={filterQuery}
                    state={state}
                    clearQueryRender={clearQueryRender}
                    firstTransactionFromDate={firstTransactionFromDate}
                />
            </div>
            <CommonModal
                title="Create New Transaction"
                open={createTransactionModal}
                className={'create-transaction-model'}
                handleClose={() => setCreateTransactionModal(false)}
                centered
                footerText={false}>
                <div className="create-transaction-model-btns">
                    <button
                        className={`transaction-actions-btn create-transaction-fiat-btn`}
                        onClick={() => {
                            setAddTransactionModal(true);
                            setCreateTransactionModal(false);
                            fetchClients();
                        }}>
                        <div className="transaction-actions-icon">
                            <img src={`${imageBaseUrl}${Fiat}`} alt="fiat" />
                        </div>
                        <p className={`transaction-actions-title`} data-e2e="fiat-option">
                            Fiat
                        </p>
                    </button>
                    <button
                        className={`transaction-actions-btn create-transaction-fiat-btn mt-4`}
                        onClick={() => {
                            setCryptoTransactionModal(true);
                            setCreateTransactionModal(false);
                            fetchClients();
                        }}>
                        <div className="transaction-actions-icon">
                            <img src={`${imageBaseUrl}${Crypto}`} alt="crypto" />
                        </div>
                        <p className={`transaction-actions-title`} data-e2e="crypto-option">
                            Crypto
                        </p>
                    </button>
                </div>
            </CommonModal>
            <CryptoTransactionModal
                open={cryptoTransactionModal}
                handleClose={() => setCryptoTransactionModal(false)}
                onBack={() => {
                    setCreateTransactionModal(true);
                    setCryptoTransactionModal(false);
                    setSelectedClient({});
                    setSearchClientName();
                }}
                clients={clientData || []}
                fetchClients={fetchClients}
            />
            <CommonModal
                title="Create New Transaction"
                open={addTransactionModal}
                className={'common-mobile-view create-transaction-view'}
                handleClose={() => {
                    setAddTransactionModal(false);
                    setSelectedClient({});
                    setSearchClientName();
                }}
                centered
                footerText={false}
                form={form}>
                <Form
                    form={form}
                    layout="horizontal"
                    colon={false}
                    className="overflow-x-auto pr-2 lg:mt-[30px] mt-8 modal-form">
                    <Select
                        showSearch
                        suffixIcon={
                            <>
                                {fetching && <Spin size="small" />}
                                <SearchOutlined className="text-lg text-[#51525C]" />
                            </>
                        }
                        value={searchClientName}
                        placeholder="Search Account Id , Account name"
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        data-e2e="search-option"
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={!fetching && 'Not Found'}
                        className="w-full rounded-lg h-[40px]"
                        optionLabelProp="label">
                        {(clientData || []).map((item, index) => {
                            return (
                                <Option
                                    className={'!bg-transparent !font-light !py-3'}
                                    key={item?.clientId + '-option'}
                                    value={item?.name}
                                    label={item?.name}>
                                    <div
                                        className="flex justify-between"
                                        onClick={() => optionClick(item)}>
                                        <div>
                                            <p className="text-base" data-e2e={item?.name}>
                                                {item?.name}
                                            </p>
                                            <p className="text-xs" data-e2e={item?.clientId}>
                                                {item?.clientId}
                                            </p>
                                        </div>
                                        <div>
                                            {selectedClient.clientId === item?.clientId && (
                                                <CheckOutlined className="text-lg text-[#51525C]" />
                                            )}
                                        </div>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                    {isUserOnboarded && (
                        <div className="text-[#df4444] text-sm mt-2">
                            <sup>*</sup>
                            {isUserOnboarded}
                        </div>
                    )}
                    <FormInput
                        label="Account name:"
                        name="accountNickName"
                        value={selectedClient?.name}
                        placeholder="Enter Account Name"
                        dataE2e="enter-account-name"
                        readOnly
                    />
                    <FormInput
                        label="Account ID:"
                        name="clientId"
                        value={selectedClient?.clientId}
                        placeholder="Enter Account ID"
                        dataE2e="account-id"
                        readOnly
                    />
                    <div className="transaction-actions transaction-actions-fiat">
                        {navbarItems.map((item, index) => (
                            <button
                                disabled={
                                    Object.keys(selectedClient).length === 0 ||
                                    unfreezeBalances.length === 0 ||
                                    (item.type === 'FX' && unfreezeBalances.length <= 1) ||
                                    isUserOnboarded
                                }
                                className={`transaction-actions-btn`}
                                onClick={() => openModalAction(item.type)}
                                key={index + '-transaction-list'}>
                                <div className="transaction-actions-icon">
                                    <img
                                        className={`${
                                            (Object.keys(selectedClient).length === 0 ||
                                                unfreezeBalances.length === 0 ||
                                                (item.type === 'FX' &&
                                                    unfreezeBalances.length <= 1)) &&
                                            'opacity-50'
                                        }`}
                                        src={`${imageBaseUrl}${item.icon}`}
                                        alt="nav item icon"
                                        data-e2e={item.type}
                                    />
                                </div>
                                <p
                                    className={`${
                                        (Object.keys(selectedClient).length === 0 ||
                                            unfreezeBalances.length === 0 ||
                                            (item.type === 'FX' && unfreezeBalances.length <= 1)) &&
                                        'opacity-50'
                                    } transaction-actions-title`}>
                                    {item.type}
                                </p>
                            </button>
                        ))}
                    </div>
                </Form>
            </CommonModal>
            <WithdrawalAndTPPFiatModal
                open={isModalOpen && openModal === 'Withdrawal'}
                confirmTitle={'Withdrawal Confirmation'}
                initialTitle={'Withdrawal'}
                transactionType={'Withdrawal'}
                successProcess={'withdrawn'}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                beneficiaryList={beneficiaryList}
                getBeneficiaryList={getBeneficiaryList}
                onWithdrawalSubmit={body => createTransaction(body)}
            />
            <WithdrawalAndTPPFiatModal
                open={isModalOpen && openModal === 'Third-party payment'}
                confirmTitle={'Third-party Payment Confirmation'}
                initialTitle={'Third-party Payment'}
                transactionType={'Payment Out'}
                successProcess={'sent'}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                beneficiaryList={beneficiaryList}
                getBeneficiaryList={getBeneficiaryList}
                onWithdrawalSubmit={body => createTransaction(body)}
            />
            <TransferModal
                transferModal={isModalOpen && openModal === 'Transfer'}
                client={form.getFieldValue('clientId')}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                onSubmit={body => createTransaction(body)}
            />

            <FXFiatModal
                client={form.getFieldValue('clientId')}
                open={isModalOpen && openModal === 'FX'}
                handleClose={onModalClose}
                onSubmit={body => createTransaction(body)}
            />

            <DepositFiatModal
                client={selectedClient?.clientId}
                open={isModalOpen && openModal === 'Deposit'}
                handleClose={onModalClose}
                onSubmit={body => createTransaction(body)}
            />
            <DownloadCsvModal
                data={csvData}
                open={csvModal}
                handleClose={handleCloseCsvModal}
                name="clientTransactions"
            />
            <MerchantTransfer
                transferModal={isModalOpen && openModal === 'Merchant'}
                client={form.getFieldValue('clientId')}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                onSubmit={body => createTransaction(body)}
            />
        </Sidebar>
    );
};

export default Transactions;
