import { UPDATE_FILTERS, RESET_FILTERS } from '../actions/actionTypes';

const initialState = {
    name: [],
    clientEmail: [],
    phoneNumber: [],
    status: [],
    clientId: [],
    statusCode: [],
    transactionType: [],
    transactionStatus: [],
    accountType: [],
};

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_FILTERS:
            return initialState;
        default:
            return state;
    }
};

export default filtersReducer;
