import { getDate } from '../../utils/common';
import { SET_DASHBOARD_FILTERS } from '../actions/actionTypes';
import moment from 'moment';

const now = moment().utc();

const initialState = {
    date: ['1D'],
    startDate: getDate('1D'),
    endDate: now.format('YYYY-MM-DDTHH:mm:ssZ'),
    accountType: null,
    selectedDateRange: null,
};

const dashboardFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD_FILTERS: {
            const { date, startDate, endDate, accountType, selectedDateRange } = action.payload;
            return {
                ...state,
                date: date,
                startDate:
                    startDate ||
                    now.clone().subtract(1, 'days').add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ'),
                endDate: endDate || now.format('YYYY-MM-DDTHH:mm:ssZ'),
                accountType,
                selectedDateRange,
            };
        }
        default:
            return state;
    }
};

export default dashboardFiltersReducer;
