import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Edit } from '../../../utils/Images';
import { fiatCurrencies } from '../../../utils/constant';
import { getCountries } from '../../../utils/common';
import apiRequest from '../../../utils/api';

function TransactionActivity({ transactionActivityData = {}, clientEmail, accountType }) {
    const [transactionType, setTransactionType] = useState('inbound');
    const imageBaseUrl = useSelector(state => state.config?.image_base_url);
    const [isSave, setIsSave] = useState(false);
    const [form] = Form.useForm();
    const [countries, setCountries] = useState([]);
    const email = localStorage.getItem('email');
    const [isLoading, setIsLoading] = useState(false);

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            ...transactionActivityData.inbound,
            ...transactionActivityData.outbound,
            ...transactionActivityData.fx,
            estimatedNumberPerMonth: transactionActivityData?.inbound?.estimatedNumberPerMonth
                ? transactionActivityData?.inbound?.estimatedNumberPerMonth
                : null,
            averageUsdValue: transactionActivityData?.inbound?.averageUsdValue
                ? transactionActivityData?.inbound?.averageUsdValue
                : null,
            mainCurrencies: transactionActivityData?.inbound?.mainCurrencies,
            outboundEstimatedNumberPerMonth: transactionActivityData?.outbound
                ?.estimatedNumberPerMonth
                ? transactionActivityData?.outbound?.estimatedNumberPerMonth
                : null,
            outboundMainCurrencies: transactionActivityData?.outbound?.mainCurrencies,
            outboundUsdValue: transactionActivityData?.outbound?.averageUsdValue
                ? transactionActivityData?.outbound?.averageUsdValue
                : null,
            numberPerMonth: transactionActivityData?.fx?.numberPerMonth
                ? transactionActivityData?.fx?.numberPerMonth
                : null,
            fxUsdValue: transactionActivityData?.fx?.averageUsdValue
                ? transactionActivityData?.fx?.averageUsdValue
                : null,
            jurisdictionsReceivingFundsFrom: transactionActivityData?.inbound
                ?.jurisdictionsReceivingFundsFrom
                ? transactionActivityData?.inbound?.jurisdictionsReceivingFundsFrom.split(', ')
                : null,
            jurisdictionsSendingFundsTo: transactionActivityData?.outbound
                ?.jurisdictionsSendingFundsTo
                ? transactionActivityData?.outbound?.jurisdictionsSendingFundsTo.split(', ')
                : null,
        });
    }, [transactionActivityData]);

    const numberOfTransactionsOption = [
        { value: '1-500', label: '1-500' },
        { value: '500-5000', label: '500-5,000' },
        { value: '5000-25000', label: '5,000-25,000' },
        { value: '25000-100000', label: '25,000-100,000' },
        { value: '100000-1000000', label: '100,000-1,000,000' },
        { value: '1000000+', label: '1,000,000+' },
    ];
    function getFormattedOptions(isAmount) {
        if (!isAmount) {
            return numberOfTransactionsOption;
        } else {
            return numberOfTransactionsOption.map(option => {
                const parts = option.label.split('-');
                const formattedOptions = parts.map(part => `$${part}`).join('-');

                return {
                    value: formattedOptions,
                    label: formattedOptions,
                };
            });
        }
    }
    const onEditTransactionActivity = () => {
        isSave ? updateClientDetails() : setIsSave(prevStatus => !prevStatus);
    };
    const transactionsCurrenciesOptions = fiatCurrencies.map(currency => ({
        value: currency.symbol,
        label: `${currency.name} (${currency.symbol})`,
    }));

    const fxPairsOptions = [];
    for (let i = 0; i < fiatCurrencies.length; i++) {
        for (let j = i + 1; j < fiatCurrencies.length; j++) {
            const pair = `${fiatCurrencies[i].symbol}-${fiatCurrencies[j].symbol}`;
            fxPairsOptions.push({
                value: pair,
                label: `${fiatCurrencies[i].symbol} to ${fiatCurrencies[j].symbol}`,
            });
        }
    }

    const updateClientDetails = async () => {
        const cleanDollarAmount = value => value?.replace(/\$/g, '').trim();
        const joinValues = values => values?.join(', ');
        const formValues = form.getFieldValue();

        const body = {
            email,
            clientEmail,
            accountType,
            transactionActivity: {
                fx: {
                    numberPerMonth: formValues.numberPerMonth,
                    averageUsdValue: cleanDollarAmount(formValues.fxUsdValue),
                    mainFxPairs: formValues.mainFxPairs,
                },
                inbound: {
                    averageUsdValue: cleanDollarAmount(formValues.averageUsdValue),
                    estimatedNumberPerMonth: formValues.estimatedNumberPerMonth,
                    jurisdictionsReceivingFundsFrom: joinValues(
                        formValues.jurisdictionsReceivingFundsFrom,
                    ),
                    mainCurrencies: formValues.mainCurrencies,
                    topEntitiesSendingFunds: formValues.topEntitiesSendingFunds,
                },
                outbound: {
                    averageUsdValue: cleanDollarAmount(formValues.outboundUsdValue),
                    estimatedNumberPerMonth: formValues.outboundEstimatedNumberPerMonth,
                    jurisdictionsSendingFundsTo: joinValues(formValues.jurisdictionsSendingFundsTo),
                    mainCurrencies: formValues.outboundMainCurrencies,
                    topBeneficiariesReceivingFunds: formValues.topBeneficiariesReceivingFunds,
                },
            },
        };

        try {
            setIsLoading(true);
            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response) {
                setIsSave(false);
                setIsLoading(false);
                return response;
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Failed to update client details:', error);
            throw error;
        }
    };

    return (
        <div className="p-4 pt-0 md:px-8 lg:p-0">
            <div className=" bg-white">
                <div className="flex items-center justify-between my-3 lg:p-0">
                    <h1 className="text-lg font-semibold">Transaction Activity</h1>
                    <Button
                        loading={isLoading}
                        onClick={onEditTransactionActivity}
                        icon={
                            !isSave && (
                                <img src={`${imageBaseUrl}${Edit}`} alt="" className="w-5 h-5" />
                            )
                        }
                        className="mr-2 bg-[#F4F4F5] h-8 !min-w-10 !max-w-fit">
                        {isSave && 'Save'}
                    </Button>
                </div>

                <div className="mb-[10px]">
                    <Button
                        className={`mr-2 outline-none border-0 ${transactionType === 'inbound' ? 'border-b-black border-b-[3px] !border-t-none !border-l-none !border-r-none  !bg-[#F4F4F5] transaction-activity-tabs' : 'text-[#51525C] border-0'}`}
                        onClick={() => setTransactionType('inbound')}>
                        <span className="font-inter font-medium text-lg leading-6 tracking-normal text-center align-middle">
                            Inbound
                        </span>
                    </Button>
                    <Button
                        className={`mr-2 border-0 ${transactionType === 'outbound' ? 'border-b-black border-b-[3px] border-t-none border-l-none border-r-none !bg-[#F4F4F5] transaction-activity-tabs' : 'text-[#51525C] border-0'}`}
                        onClick={() => setTransactionType('outbound')}>
                        <span className="font-inter font-medium text-lg leading-6 tracking-normal text-center align-middle">
                            Outbound
                        </span>
                    </Button>
                    <Button
                        className={`mr-2 outline-none border-0 ${transactionType === 'fx' ? ' border-b-black border-b-[3px] border-t-none border-l-none border-r-none !bg-[#F4F4F5] transaction-activity-tabs' : 'text-[#51525C] border-0'}`}
                        onClick={() => setTransactionType('fx')}>
                        <span className="font-inter font-medium text-lg leading-6 tracking-normal text-center align-middle">
                            FX
                        </span>
                    </Button>
                </div>
                {transactionType === 'inbound' && (
                    <Form
                        disabled={!isSave}
                        form={form}
                        layout="vertical"
                        className="mt-2 h-[60vh] px-[7px] overflow-y-auto hide-scrollbar">
                        <p className="my-4 !font-inter font-medium text-lg leading-7 tracking-normal">
                            Inbound
                        </p>
                        <Form.Item
                            name="estimatedNumberPerMonth"
                            label="Estimated number of inbound transactions per month*"
                            className="account-pupose-form">
                            <Select
                                className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select transaction count">
                                {getFormattedOptions(false).map(item => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="averageUsdValue"
                            label="Average USD value of each inbound transaction*"
                            className="account-pupose-form">
                            <Select
                                className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select amount">
                                {getFormattedOptions(true).map(item => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="mainCurrencies"
                            label="Main inbound currencies*"
                            className="account-pupose-form">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select currencies you operate with"
                                value={form.mainCurrencies}
                                className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                                {transactionsCurrenciesOptions.map(obj => (
                                    <Option value={obj.value}>{obj.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="jurisdictionsReceivingFundsFrom"
                            label="Top Jurisdictions you will receive funds from"
                            className="account-pupose-form">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select jurisdiction you operate with">
                                {countries.length > 0 ? (
                                    countries.map((country, key) => (
                                        <Option key={key} value={country.name}>
                                            {country.name}
                                        </Option>
                                    ))
                                ) : (
                                    <Option value="">Loading...</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="topEntitiesSendingFunds"
                            label="Top entities/individuals that will send funds into your Juno Money account"
                            className="account-pupose-form">
                            <Input.TextArea
                                className="rounded-lg border-[2px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="List full names with commas"
                                rows={3}
                            />
                        </Form.Item>
                    </Form>
                )}
                {transactionType === 'outbound' && (
                    <Form
                        disabled={!isSave}
                        form={form}
                        layout="vertical"
                        className="mt-2 lg:mt-[20px] h-[60vh] px-[7px] overflow-y-auto hide-scrollbar">
                        <p className="my-4 !font-inter font-medium text-lg leading-7 tracking-normal">
                            Outbound
                        </p>
                        <Form.Item
                            name="outboundEstimatedNumberPerMonth"
                            label="Estimated number of outbound transactions per month*"
                            className="account-pupose-form">
                            <Select
                                className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select transaction count">
                                {getFormattedOptions(false).map(item => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="outboundUsdValue"
                            label="Average USD value of each outbound transaction"
                            className="account-pupose-form">
                            <Select
                                className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select amount">
                                {getFormattedOptions(true).map(item => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="outboundMainCurrencies"
                            label="Main outbound currencies"
                            className="account-pupose-form">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select currencies you operate with"
                                className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                                {transactionsCurrenciesOptions.map(obj => (
                                    <Option value={obj.value}>{obj.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="jurisdictionsSendingFundsTo"
                            label="Top Jurisdictions you will send funds to"
                            className="account-pupose-form">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select jurisdiction you operate with"
                                className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                                {countries.length > 0 ? (
                                    countries.map((country, key) => (
                                        <Option key={key} value={country.name}>
                                            {country.name}
                                        </Option>
                                    ))
                                ) : (
                                    <Option value="">Loading...</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="topBeneficiariesReceivingFunds"
                            label="Top entities/individuals that will send funds into your Juno Money account"
                            className="account-pupose-form">
                            <Input.TextArea
                                className="rounded-lg border-[2px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="List full names with commas"
                                rows={3}
                            />
                        </Form.Item>
                    </Form>
                )}

                {transactionType === 'fx' && (
                    <Form
                        disabled={!isSave}
                        form={form}
                        layout="vertical"
                        className="mt-2 lg:mt-[20px] h-[60vh] px-[7px] overflow-y-auto hide-scrollbar"
                        style={{ height: 'auto' }}>
                        <p className="my-4 !font-inter font-medium text-lg leading-7 tracking-normal">
                            FX
                        </p>
                        <Form.Item
                            name="numberPerMonth"
                            label="Number of FX transactions per month*"
                            className="account-pupose-form">
                            <Select
                                className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select transaction count">
                                {getFormattedOptions(false).map(item => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="fxUsdValue"
                            label="Average USD value of each FX transaction"
                            className="account-pupose-form">
                            <Select
                                className="rounded-lg border-[1px] border-[#D1D1D6] h-[45px] !placeholder-[#70707B] account-purpose-select"
                                placeholder="Select amount">
                                {getFormattedOptions(true).map(item => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="mainFxPairs"
                            label="Main FX Pairs"
                            className="account-pupose-form">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select main FX pairs"
                                className="rounded-lg border-[1px] border-[#D1D1D6] min-h-[45px] !placeholder-[#70707B] account-purpose-select">
                                {fxPairsOptions.map(obj => (
                                    <Option value={obj.value}>{obj.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
}

export default TransactionActivity;
