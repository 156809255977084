import React, { useCallback, useEffect, useMemo, useState } from 'react';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import { MaintenanceIcon } from '../../utils/Images';
import { message, Switch, Collapse, Tooltip, Divider } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useCommonFunctions } from '../../utils/common';
import { constant } from '../../utils/constant';

const TransactionTypeList = ({
    transactionTypes,
    complyAdvantageSettings,
    loading,
    handleSwitchChange,
}) => (
    <div className="flex flex-col gap-5 mt-2">
        {transactionTypes?.map(({ key, label }) => {
            const value = complyAdvantageSettings?.transactionTypes?.[key] || false;
            return (
                <div key={key} className="flex justify-between">
                    <p>{label}:</p>
                    <Tooltip
                        placement="topLeft"
                        title={`${value ? 'Disable' : 'Enable'} ${label} check`}>
                        <Switch
                            onChange={val =>
                                handleSwitchChange(
                                    constant.SETTINGS.COMPLY_ADVANTAGE,
                                    val,
                                    false,
                                    key,
                                )
                            }
                            checked={value}
                            loading={loading}
                        />
                    </Tooltip>
                </div>
            );
        })}
    </div>
);

const CollapseLabel = ({ imageBaseUrl, MaintenanceIcon }) => (
    <>
        <div className=" bg-[#FCFCFC] sm:flex hidden rounded-lg gap-3 items-center py-4 px-4">
            <img className="!w-8 !h-8" src={`${imageBaseUrl}${MaintenanceIcon}`} />
            <p className="flex-1 font-inter font-medium text-lg">
                Enable application wide "Comply Advantage checks" for transactions.
            </p>
        </div>

        <div className="sm:hidden block bg-[#FCFCFC] rounded-lg py-4 px-4">
            <div className="flex gap-3 items-center justify-between">
                <img className="!w-7 !h-7" src={`${imageBaseUrl}${MaintenanceIcon}`} />
            </div>
            <p className="flex-1 font-inter font-medium text-base mt-1">
                Enable application wide "Comply Advantage checks" for transactions.
            </p>
        </div>
    </>
);

const CollapseExtra = ({ complyEnabled, loading, activeKey, handleSwitchChange, onToggle }) => (
    <div className="flex h-full sm:items-center">
        <Switch
            className="md:mr-2 mr-3"
            onChange={(val, e) => {
                e.stopPropagation();
                handleSwitchChange(constant.SETTINGS.COMPLY_ADVANTAGE, val);
            }}
            checked={complyEnabled || false}
            loading={loading}
        />
        <Divider type="vertical h-full hidden md:block" />
        <div className="pr-4 pl-2 hidden md:block">
            {activeKey === '1' ? (
                <UpOutlined
                    onClick={onToggle}
                    className={`p-1 rounded-md ${complyEnabled ? 'hover:bg-gray-200 cursor-pointer' : 'opacity-50 cursor-not-allowed'}`}
                />
            ) : (
                <DownOutlined
                    onClick={onToggle}
                    className={`p-1 rounded-md ${complyEnabled ? 'hover:bg-gray-200 cursor-pointer' : 'opacity-50 cursor-not-allowed'}`}
                />
            )}
        </div>
    </div>
);

const Maintenance = ({ tab }) => {
    const email = localStorage.getItem('email');
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState(null);
    const { getSettings } = useCommonFunctions();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const maintenanceSettings = useSelector(state => state.settings.maintenance);
    const sumsubSetting = useSelector(state => state.settings?.sumsub);

    const complyAdvantageSettings = useSelector(state => state.settings.complyAdvantage);
    const complyEnabled = complyAdvantageSettings?.applicationLevel;

    useEffect(() => {
        getSettings();
    }, []);

    const handleSwitchChange = async (
        settingName,
        checked,
        isApplicationLevel = true,
        transactionType,
    ) => {
        try {
            setLoading(true);

            let value;

            if (settingName === constant.SETTINGS.COMPLY_ADVANTAGE) {
                if (isApplicationLevel) {
                    value = { ...complyAdvantageSettings, applicationLevel: checked };

                    if (!checked) {
                        setActiveKey(null);
                    }
                } else {
                    const { transactionTypes, ...rest } = complyAdvantageSettings;
                    value = {
                        ...rest,
                        transactionTypes: { ...transactionTypes, [transactionType]: checked },
                    };
                }
            } else {
                value = checked;
            }

            const body = {
                email,
                settingName,
                value,
                updatedBy: email,
            };

            const response = await apiRequest('/update-settings', 'POST', body);
            if (response.success) {
                getSettings();
            } else {
                message.error(response.error);
            }
        } catch (error) {
            message.error(error.message || 'Failed to update settings');
        } finally {
            setLoading(false);
        }
    };

    const transactionTypes = useMemo(
        () =>
            Object.entries(constant.TRANSACTION_TYPE_CONSTANT.FIAT).map(([key, label]) => ({
                label,
                key: key.toLowerCase(),
            })),
        [],
    );

    const togglePanel = useCallback(() => {
        if (complyEnabled && !loading) {
            setActiveKey(prevKey => (prevKey === '1' ? null : '1'));
        }
    }, [complyEnabled, loading]);

    const items = [
        {
            key: '1',
            label: <CollapseLabel imageBaseUrl={imageBaseUrl} MaintenanceIcon={MaintenanceIcon} />,
            children: (
                <TransactionTypeList
                    transactionTypes={transactionTypes}
                    complyAdvantageSettings={complyAdvantageSettings}
                    loading={loading}
                    handleSwitchChange={handleSwitchChange}
                />
            ),
            extra: (
                <CollapseExtra
                    complyEnabled={complyEnabled}
                    loading={loading}
                    activeKey={activeKey}
                    handleSwitchChange={handleSwitchChange}
                    onToggle={togglePanel}
                />
            ),
            showArrow: false,
        },
    ];

    return (
        <>
            <div className="pt-8  w-full flex accounts-page h-full maintenance-page flex-col gap-3 ">
                <div className="border border-[#E4E4E7] bg-[#FCFCFC] p-4 rounded-lg mx-6 sm:flex gap-3 items-center hidden">
                    <img className="!w-8 !h-8" src={`${imageBaseUrl}${MaintenanceIcon}`} />
                    <p className="flex-1 font-inter font-medium text-lg">
                        Set application wide “Site under maintenance” holding page.
                    </p>
                    <Switch
                        onChange={val => handleSwitchChange(constant.SETTINGS.MAINTENANCE, val)}
                        checked={maintenanceSettings || false}
                        loading={loading}
                    />
                </div>
                <div className="border-[1px] mx-6 rounded-lg hidden md:block">
                    <Collapse
                        collapsible="icon"
                        bordered={false}
                        activeKey={activeKey}
                        items={items}
                        className="bg-[#FCFCFC]"
                        accordion={true}
                    />
                </div>
                <div className="border border-[#E4E4E7] bg-[#FCFCFC] p-4 rounded-lg mx-6 sm:flex gap-3 items-center hidden">
                    <img className="!w-7 !h-7" src={`${imageBaseUrl}${MaintenanceIcon}`} />
                    <p className="flex-1 font-inter font-medium text-lg">
                        Enable application-wide 'SumSub Verification' for KYC checks.
                    </p>
                    <Switch
                        onChange={val => handleSwitchChange(constant.SETTINGS.SUMSUB, val)}
                        checked={sumsubSetting || false}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="w-full accounts-page h-full flex flex-col gap-3 maintenance-page">
                <div className="border border-[#E4E4E7] bg-[#FCFCFC] p-3 rounded-lg mx-3 block sm:hidden">
                    <div className="flex gap-3 items-center justify-between">
                        <img className="!w-7 !h-7" src={`${imageBaseUrl}${MaintenanceIcon}`} />
                        <Switch
                            onChange={val => handleSwitchChange(constant.SETTINGS.MAINTENANCE, val)}
                            checked={maintenanceSettings || false}
                            loading={loading}
                        />
                    </div>
                    <p className="flex-1 font-inter font-medium text-base mt-1">
                        Set application wide “Site under maintenance” holding page.
                    </p>
                </div>

                <div className="border-[1px] sm:mx-6 mx-3 sm:mt-2 rounded-lg block md:hidden ">
                    <Collapse
                        collapsible="icon"
                        bordered={false}
                        activeKey={activeKey}
                        items={items}
                        className=" bg-[#FCFCFC]"
                        accordion={true}
                    />
                    <div className="px-4">
                        <Divider className="my-0" />
                        <p
                            className={`text-[#156fee] text-sm font-medium my-4 ${complyEnabled ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'}`}
                            onClick={complyEnabled ? togglePanel : undefined}>
                            {activeKey ? 'Show less' : 'Show more'}
                        </p>
                    </div>
                </div>
                <div className="border border-[#E4E4E7] bg-[#FCFCFC] p-3 rounded-lg mx-3 block sm:hidden">
                    <div className="flex gap-3 items-center justify-between">
                        <img className="!w-7 !h-7" src={`${imageBaseUrl}${MaintenanceIcon}`} />
                        <Switch
                            onChange={val => handleSwitchChange(constant.SETTINGS.SUMSUB, val)}
                            checked={sumsubSetting || false}
                            loading={loading}
                        />
                    </div>
                    <p className="flex-1 font-inter font-medium text-base mt-1">
                        Enable application-wide 'SumSub Verification' for KYC checks.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Maintenance;
