import React from 'react';
import { DatePicker, Form } from 'antd';

const FormDOB = ({
    label,
    name,
    picker,
    value,
    defaultValue,
    placeholder,
    format,
    onChange,
    required,
    rules,
    help,
    readOnly,
    disabledDate,
    className,
    formClassName,
    dataE2e,
}) => (
    <Form.Item
        label={
            <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                {label}
            </p>
        }
        name={name}
        required={required}
        rules={rules}
        help={help}
        className={`mt-[30px] ${formClassName}`}>
        <div className="relative w-full sm:w-[320px] mr-auto">
            <DatePicker
                picker={picker}
                name={name}
                placeholder={placeholder}
                format={format}
                value={value}
                defaultValue={defaultValue}
                disabledDate={currentDate => {
                    const today = new Date();
                    return currentDate > today;
                }}
                onChange={onChange}
                onKeyDown={e => e.preventDefault()}
                className={`w-full lg:w-[320px] h-[50px] !text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px]  ${className}`}
                allowClear
            />
        </div>
    </Form.Item>
);

export default FormDOB;
