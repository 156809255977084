import AdditionalAccounts from '../components/accountDetailsTabs/AdditionalAccounts';
import BalanceDetailsTab from '../components/fiatTabs/BalanceDetailsTab';
import BankingInfo from '../components/accountDetailsTabs/BankingInfo';
import CryptoTab from '../components/accountDetailsTabs/CryptoTab';
import Details from '../components/accountDetailsTabs/Details';
import FeeTab from '../components/fiatTabs/FeeTab';
import MerchantsTab from '../components/accountDetailsTabs/MerchantsTab';
import ActivityTab from '../components/accountDetailsTabs/ActivityTab';
import StatementTab from '../components/accountDetailsTabs/StatementTab';
// import TransactionDetailsTab from '../components/accountDetailsTabs/TransactionDetailsTab';
import {
    BuyCrypto,
    Deposit,
    FX,
    MerchantIcon,
    SellCrypto,
    Tpp,
    Transfer,
    Withdrawal,
} from './Images';
import SettingsTab from '../components/accountDetailsTabs/SettingsTab';
import FiatTab from '../components/accountDetailsTabs/FiatTab';
import CryptoTransactionsTab from '../components/cryptoTabs/CryptoTransactionsTab';
import CryptoFeeTab from '../components/cryptoTabs/CryptoFeeTab';
import CryptoBalancesTab from '../components/cryptoTabs/CryptoBalancesTab';
import CryptoWalletsTab from '../components/cryptoTabs/CryptoWalletsTab';
import TransactionTab from '../components/fiatTabs/TransactionDetailsTab';
import { getBalanceSign, getCurrencySymbol, toFixedTrunc } from './common';
import AddNotesTab from '../components/accountDetailsTabs/AddNotesTab';
import PersonalTab from '../components/accountDetailsTabs/PersonalAndBusinessTabs/PersonalTab';
import BusinessTab from '../components/accountDetailsTabs/PersonalAndBusinessTabs/BusinessTab';

export const constant = {
    TRANSACTION_TYPE_CONSTANT: {
        FIAT: {
            DEPOSIT: 'Deposit',
            WITHDRAWAL: 'Withdrawal',
            PAYMENT_OUT: 'Payment Out',
            TRANSFER: 'Transfer',
            FX: 'FX',
        },
        CRYPTO: {
            BUY: 'Buy',
            SELL: 'Sell',
            SEND: 'Send',
            EXCHANGE: 'Exchange',
            RECEIVE: 'Receive',
        },
    },
    SETTINGS: {
        MAINTENANCE: 'maintenance',
        COMPLY_ADVANTAGE: 'complyAdvantage',
        SUMSUB: 'sumsub',
    },
    ACCOUNT_TYPE: {
        BUSINESS: 'Business',
        PERSONAL: 'Personal',
    },
};

export const transactions = [
    { key: 1, value: 'all', label: 'All Transactions' },
    { key: 3, value: 'Withdrawal', label: 'Withdrawals' },
    { key: 6, value: 'Payment Out', label: "TPP's" },
    { key: 2, value: 'Deposit', label: 'Deposits' },
    { key: 4, value: 'FX', label: 'FX' },
    { key: 5, value: 'Transfer', label: 'Transfers' },
    { key: 7, value: 'Merchant', label: 'Merchant' },
    { key: 8, value: 'Buy', label: 'Buy' },
    { key: 9, value: 'Sell', label: 'Sell' },
    { key: 10, value: 'Exchange', label: 'Exchange' },
    { key: 11, value: 'Send', label: 'Send' },
    { key: 12, value: 'Receive', label: 'Receive' },
];

export const fiatTransactionsType = [
    { key: 1, value: 'Deposit', label: 'Deposits', currencyType: 'fiat' },
    { key: 2, value: 'Withdrawal', label: 'Withdrawals', currencyType: 'fiat' },
    { key: 3, value: 'Payment Out', label: "TPP's", currencyType: 'fiat' },
    { key: 4, value: 'Transfer', label: 'Transfers', currencyType: 'fiat' },
    { key: 5, value: 'FX', label: 'FX', currencyType: 'fiat' },
    { key: 6, value: 'Merchant', label: 'Merchant', currencyType: 'fiat' },
];

export const navbarItems = [
    {
        type: 'Deposit',
        icon: Deposit,
    },
    {
        type: 'Withdrawal',
        icon: Withdrawal,
    },
    {
        type: 'Third-party payment',
        icon: Tpp,
    },
    {
        type: 'Transfer',
        icon: Transfer,
    },
    {
        type: 'FX',
        icon: FX,
    },
    {
        type: 'Merchant',
        icon: MerchantIcon,
    },
];

export const erc20Tokens = ['ETH', 'DAI', 'USDT', 'USDC'];

export const USDTBlockchainOptions = [
    { key: 'Ethereum', value: 'Ethereum' },
    { key: 'Tron', value: 'Tron' },
];

export const accountDetailsNav = [
    {
        key: '0',
        label: 'Details',
        children: <Details />,
    },
    {
        key: '1',
        label: 'Settings',
        children: <SettingsTab />,
    },
    {
        key: '2',
        label: 'Merchants',
        children: <MerchantsTab />,
    },
    // {
    //     key: '3',
    //     label: 'Activity',
    //     children: <ActivityTab />,
    // },
    {
        key: '4',
        label: 'Fiat',
        children: <FiatTab />,
    },
    {
        key: '5',
        label: 'Crypto',
        children: <CryptoTab />,
    },
    // {
    //     key: '6',
    //     label: 'Banking',
    //     children: <BankingInfo />,
    // },
    {
        key: '7',
        label: 'Notes',
        children: <AddNotesTab />,
        // 'Content of Tab Pane 3'
    },
    {
        key: '8',
        label: 'Personal',
        children: <PersonalTab />,
    },
    {
        key: '9',
        label: 'Business',
        children: <BusinessTab />,
    },
    {
        key: '10',
        label: 'Statements',
        children: <StatementTab />,
    },
    {
        key: '11',
        label: 'Additional Account',
        children: <AdditionalAccounts />,
    },
];
export const fiatDetailsNav = [
    {
        key: '0',
        label: 'Transactions',
        children: <TransactionTab />,
    },
    {
        key: '1',
        label: "Fee's",
        children: <FeeTab />,
    },
    {
        key: '2',
        label: 'Balances',
        children: <BalanceDetailsTab />,
    },
];
export const cryptoDetailsNav = [
    {
        key: '0',
        label: 'Transactions',
        children: <CryptoTransactionsTab />,
    },
    {
        key: '1',
        label: "Fee's",
        children: <CryptoFeeTab />,
    },
    {
        key: '2',
        label: 'Balances',
        children: <CryptoBalancesTab />,
    },
    {
        key: '3',
        label: 'Wallets',
        children: <CryptoWalletsTab />,
    },
];

export const fiatOptions = [
    {
        label: 'EUR',
        value: 'EUR',
    },
    {
        label: 'USD',
        value: 'USD',
    },
    {
        label: 'GBP',
        value: 'GBP',
    },
    {
        label: 'AED',
        value: 'AED',
    },
    {
        label: 'CAD',
        value: 'CAD',
    },
    {
        label: 'AUD',
        value: 'AUD',
    },
    {
        label: 'CHF',
        value: 'CHF',
    },
    {
        label: 'TRY',
        value: 'TRY',
    },
    {
        label: 'HKD',
        value: 'HKD',
    },
    {
        label: 'SGD',
        value: 'SGD',
    },
    {
        label: 'JPY',
        value: 'JPY',
    },
    {
        label: 'CNY',
        value: 'CNY',
    },
    {
        label: 'MXN',
        value: 'MXN',
    },
    {
        label: 'THB',
        value: 'THB',
    },
    {
        label: 'ILS',
        value: 'ILS',
    },
];

export const cryptoOptions = [
    {
        label: 'ETH',
        value: 'ETH',
    },
    {
        label: 'BTC',
        value: 'BTC',
    },
    {
        label: 'USDT',
        value: 'USDT',
    },
    {
        label: 'USDC',
        value: 'USDC',
    },
    {
        label: 'LTC',
        value: 'LTC',
    },
    {
        label: 'DAI',
        value: 'DAI',
    },
    {
        label: 'XRP',
        value: 'XRP',
    },
    {
        label: 'TRX',
        value: 'TRX',
    },
];

export const transactionTypes = [
    {
        label: 'Deposit',
        value: 'Deposit',
    },
    {
        label: 'Withdrawal',
        value: 'Withdrawal',
    },
    {
        label: 'FX',
        value: 'FX',
    },
    {
        label: 'Payment Out',
        value: 'Payment Out',
    },
    {
        label: 'Transfer',
        value: 'Transfer',
    },
    {
        label: 'Buy',
        value: 'Buy',
    },
    {
        label: 'Sell',
        value: 'Sell',
    },
    {
        label: 'Exchange',
        value: 'Exchange',
    },
    {
        label: 'Send',
        value: 'Send',
    },
    {
        label: 'Receive',
        value: 'Receive',
    },
];
export const cryptoTransactionTypes = [
    {
        label: 'Buy',
        value: 'Buy',
        icon: BuyCrypto,
    },
    {
        label: 'Sell',
        value: 'Sell',
        icon: SellCrypto,
    },
    {
        label: 'Send',
        value: 'Send',
        icon: Tpp,
    },
    {
        label: 'Exchange',
        value: 'Exchange',
        icon: FX,
    },
    {
        label: 'Receive',
        value: 'Receive',
        icon: Deposit,
    },
];
export const transactionStatus = [
    // { value: 'initiated', label: 'Initiated' },
    { value: 'pending', label: 'Pending' },
    { value: 'processing', label: 'Processing' },
    // { value: 'alerts', label: 'Alerts' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'failed', label: 'Failed' },
    // { value: 'timedOut', label: 'Timed Out' },
    // { value: 'spread', label: 'Spread' },
    { value: 'flagged', label: 'Flagged' },
];

export const statusCode = {
    processing: [
        'PrivateClient:Processing',
        'PrivateClient:CreateTransaction',
        'PrivateClient:DepositDetail',
        'PrivateClient:ClientBalanceUpdated',
        'PrivateClient:FXDetail',
        'PrivateClient:PaymentOutDetail',
        'PrivateClient:TransactionApprovedBy',
        'PrivateClient:TransferDetail',
        'PrivateClient:WithdrawalDetail',
        'PrivateClient:WithdrawalDetail',

        'BuyFlow:InitiateRequest',
        'BuyFlow:ValidateRequest',
        'BuyFlow:FiatUpdateBalance',
        'BuyFlow:SubmitCaasBuyRequest',
        'BuyFlow:CaasCallbackReceived',
        'BuyFlow:CryptoUpdateBalance',
        'SendFlow:CaasSendCryptoNotification',
        'BuyFlow:Notification',
        'BuyFlow:Processing',

        'SellFlow:InitiateRequest',
        'SellFlow:ValidateRequest',
        'SellFlow:FiatUpdateBalance',
        'SellFlow:SubmitCaasSellRequest',
        'SellFlow:CaasCallbackReceived',
        'SellFlow:CryptoUpdateBalance',
        'SellFlow:Notification',
        'SellFlow:Processing',

        'SendFlow:InitiateRequest',
        'SendFlow:ValidateRequest',
        'SendFlow:UpdateCryptoBalance',
        'SendFlow:SubmitCaasSendRequest',
        'SendFlow:CaasCallbackReceived',
        'SendFlow:CaasSendCryptoNotification',
        'SendFlow:Notification',
        'SendFlow:Processing',

        'ReceiveFlow:InitiateRequest',
        'ReceiveFlow:CryptoUpdateBalance',
        'ReceiveFlow:CaasSendCryptoNotification',
        'ReceiveFlow:Notification',

        'ExchangeFlow:InitiateRequest',
        'ExchangeFlow:ValidateRequest',
        'ExchangeFlow:UpdateFromCryptoBalance',
        'ExchangeFlow:UpdateToCryptoBalance',
        'ExchangeFlow:SubmitCaasExchangeRequest',
        'ExchangeFlow:CaasCallbackReceived',
        'ExchangeFlow:Notification',
        'ExchangeFlow:Processing',
    ],
    pending: ['PrivateClient:RequestForApproval'],
    completed: [
        'PrivateClient:TransactionCompleted',
        'BuyFlow:BuyCompleted',
        'SellFlow:SellCompleted',
        'SendFlow:SendCompleted',
        'ReceiveFlow:ReceiveCompleted',
        'ExchangeFlow:ExchangeCompleted',
    ],
    failed: [
        'PrivateClient:ErrorEncountered',
        'BuyFlow:ErrorEncountered',
        'SellFlow:ErrorEncountered',
        'SendFlow:ErrorEncountered',
        'ReceiveFlow:ErrorEncountered',
        'ExchangeFlow:ErrorEncountered',
    ],
    rejected: [
        'PrivateClient:Rejected',
        'BuyFlow:TransactionRejected',
        'SellFlow:TransactionRejected',
        'SendFlow:TransactionRejected',
        'ReceiveFlow:TransactionRejected',
        'ExchangeFlow:TransactionRejected',
    ],
    amlHold: ['PrivateClient:AMLHold'],
    amlRejected: ['PrivateClient:AMLRejected'],
    amlApproved: ['PrivateClient:AMLApproved'],
};

export const clientTransactionStatusMapping = {
    'PrivateClient:TransactionCompleted': 'complete',
    'PrivateClient:CreateTransaction': 'pending',
    'PrivateClient:Rejected': 'rejected',
    'PrivateClient:ClientBalanceUpdated': 'processing',
    'PrivateClient:DepositDetail': 'processing',
    'PrivateClient:Processing': 'processing',
    'PrivateClient:FXDetail': 'processing',
    'PrivateClient:PaymentOutDetail': 'processing',
    'PrivateClient:TransferDetail': 'processing',
    'PrivateClient:WithdrawalDetail': 'processing',
    'PrivateClient:ErrorEncountered': 'error',
    'PrivateClient:RequestForApproval': 'pending',
};

export const keyMappings = {
    accountName: 'Account name',
    email: 'Email',
    currency: 'Currency',
    amount: 'Amount',
    reference: 'Reference',
    beneficiaryName: 'Beneficiary name',
    beneficiaryAddress: 'Beneficiary address',
    beneficiaryCountry: 'Beneficiary Country',
    bankName: 'Bank name',
    bankAddress: 'Bank Address',
    bankCountry: 'Bank Country',
    beneficiaryAccountNumber: 'Beneficiary account number',
    sortCode: 'Sort code',
    swift: 'SWIFT',
    iban: 'IBAN',
    additionalBankDetails: 'Additional bank details',
    fromAmount: 'From amount',
    toAmount: 'To amount',
    fromCurrency: 'From currency',
    toCurrency: 'To currency',
    exchangeRate: 'Exchange Rate',
    feePercentage: '% Fee',
    fxrate: 'FX Rate',
    fxFee: 'FX Fee',
    transactionFee: 'Transaction fee',
};

export const fiatCurrencies = [
    {
        icon: '/cryptonpay/crytoicons/euro.png',
        name: 'Euro',
        symbol: 'EUR',
    },
    {
        icon: '/cryptonpay/crytoicons/usa.png',
        name: 'US Dollar',
        symbol: 'USD',
    },
    {
        icon: '/cryptonpay/crytoicons/uk.png',
        name: 'British Pound',
        symbol: 'GBP',
    },
    {
        name: 'Australian dollar',
        symbol: 'AUD',
        icon: '/common/crytoicons/aud.png',
    },
    {
        name: 'Canadian dollar',
        symbol: 'CAD',
        icon: '/common/crytoicons/cad.png',
    },
    {
        name: 'dirham',
        symbol: 'AED',
        icon: '/common/crytoicons/aed.png',
    },
    {
        icon: '/common/crytoicons/chf.png',
        name: 'Swiss franc',
        symbol: 'CHF',
    },
    {
        icon: '/common/crytoicons/try.png',
        name: 'Turkish lira',
        symbol: 'TRY',
    },
    {
        icon: '/common/crytoicons/hkd.png',
        name: 'Hong Kong Dollar',
        symbol: 'HKD',
    },
    {
        icon: '/common/crytoicons/sgd.png',
        name: 'Singapore dollar',
        symbol: 'SGD',
    },
    {
        icon: '/common/crytoicons/jpy.png',
        name: 'Japanese Yen',
        symbol: 'JPY',
    },
    {
        icon: '/common/crytoicons/cny.png',
        name: 'Chinese Yuan',
        symbol: 'CNY',
    },
    {
        icon: '/common/crytoicons/mxn.png',
        name: 'Mexican Peso',
        symbol: 'MXN',
    },
    {
        icon: '/common/crytoicons/thb.png',
        name: 'Thai Baht',
        symbol: 'THB',
    },
    {
        symbol: 'ILS',
        name: 'Israeli Shekel',
        icon: '/common/crytoicons/ils.png',
    },
];

export const transactionStatusGroup = [
    {
        label: 'Pending',
        value: 'pending',
    },
    {
        label: 'Processing',
        value: 'processing',
    },
    {
        label: 'Completed',
        value: 'completed',
    },
    {
        label: 'Rejected',
        value: 'rejected',
    },
    {
        label: 'Failed',
        value: 'failed',
    },
    {
        label: 'AML Hold',
        value: 'amlHold',
    },
];

export const getClientTransactionStatus = status =>
    clientTransactionStatusMapping[status] || 'processing';

export const thresholdProfileOptions = [
    { value: 'Low', label: 'Low' },
    { value: 'Medium', label: 'Medium' },
    { value: 'High', label: 'High' },
];

export const feeProfileOptions = [
    { value: 'Standard', label: 'Standard' },
    { value: 'VIP', label: 'VIP' },
    { value: 'Super VIP', label: 'Super VIP' },
    { value: 'Bespoke', label: 'Bespoke' },
];

export const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
    { value: 'Rejected', label: 'Rejected' },
];

export const currencyTypes = [
    {
        label: 'Juno X',
        value: 'crypto',
    },
    {
        label: 'JunoMoney',
        value: 'fiat',
    },
];

export const getExChangeAmount = row => {
    const formatAmount = (currency, amount, sign) =>
        `${currency} ${sign} ${toFixedTrunc(amount, currency === 'crypto' ? 6 : 2)}`;

    const transactionDetails = row?.transactionRef?.transactionDetails?.[0] || {};

    if (
        row?.type === 'FX' &&
        row?.amount &&
        transactionDetails?.fromCurrency &&
        transactionDetails?.toCurrency
    ) {
        const amountDisplay =
            row.fxInOut === 'Out'
                ? `${formatAmount(row.currency, row.amount, '-')}`
                : `${formatAmount(row.currency, row.amount, '+')}`;

        const fxDisplay =
            row.fxInOut === 'Out'
                ? `${formatAmount(transactionDetails.toCurrency, transactionDetails.toAmount, '+')}`
                : `${formatAmount(transactionDetails.fromCurrency, transactionDetails.fromAmount, '-')}`;

        return `${amountDisplay}${fxDisplay}\n`;
    } else if (row?.type === 'Buy') {
        return `${formatAmount(transactionDetails.fiatCurrency, transactionDetails.fiatAmount, '-')}${row.currencyType === 'crypto' ? formatAmount(transactionDetails.cryptoId, transactionDetails.cryptoAmount, '+') : ''}`;
    } else if (row?.type === 'Sell') {
        return `${row.currencyType === 'crypto' ? formatAmount(transactionDetails.cryptoId, transactionDetails.cryptoAmount, '-') : ''}${formatAmount(transactionDetails.fiatCurrency, transactionDetails.fiatAmount, '+')}`;
    } else if (row?.type === 'Exchange') {
        return `${formatAmount(transactionDetails.fromCryptoId, transactionDetails.fromCryptoAmount, '-')}${formatAmount(transactionDetails.toCryptoId, transactionDetails.toCryptoAmount, '+')}`;
    }

    return `${getBalanceSign(row)} ${getCurrencySymbol(row.currency)} ${toFixedTrunc(row.amount)}`;
};

export const getTransectionDetails = (row, currencyType) => {
    if (!row || !row.transactionRef) return row.transactionId || 'N/A';

    const transactionDetails = row.transactionRef.transactionDetails?.[0] || {};
    let statusHtml = '';
    const { type, transactionRef } = row;

    if (
        transactionRef.status?.code === 'PrivateClient:RequestForApproval' &&
        (type === 'Withdrawal' || type === 'Payment Out')
    ) {
        statusHtml = 'Status : Processing';
    } else if (transactionRef.status?.code === 'PrivateClient:Rejected' && row?.txnInOut === 'In') {
        statusHtml = `Cancelled : ${transactionRef.status.message}`;
    }

    const getCurrency = (currency, cryptoId) => currency || cryptoId || 'N/A';

    const commonRender = (from, to) => `from : ${from} - to : ${to}`;

    switch (type) {
        case 'Deposit':
            return `${
                transactionRef.currencyType === 'crypto'
                    ? commonRender(transactionDetails.fromAddress, transactionDetails.toAddress)
                    : transactionDetails.remitterName || 'N/A'
            }\nCurrency ${getCurrency(row.currency, transactionDetails.cryptoId)}
			`;

        case 'Transfer':
            return `Name : ${transactionDetails.name || 'N/A'}\nCurrency ${transactionDetails.currency || 'N/A'}`;

        case 'Withdrawal':
        case 'Payment Out':
            return `from : ${transactionDetails.beneficiaryName || 'N/A'}\nCurrency ${getCurrency(transactionDetails.currency, transactionDetails.cryptoId)}\n${statusHtml}`;

        case 'FX':
            return `${commonRender(transactionDetails.fromCurrency, transactionDetails.toCurrency)}\nFX Rates ${toFixedTrunc(transactionDetails.fxrate, 4) || 'N/A'}`;

        case 'Buy':
            return currencyType === 'fiat'
                ? `To: Juno Pay Ltd.`
                : commonRender(transactionDetails.fiatCurrency, transactionDetails.cryptoId);

        case 'Sell':
            return currencyType === 'fiat'
                ? `From: Juno Pay Ltd.`
                : commonRender(transactionDetails.cryptoId, transactionDetails.fiatCurrency);

        case 'Exchange':
            return commonRender(transactionDetails.fromCryptoId, transactionDetails.toCryptoId);

        case 'Send':
        case 'Receive':
            return `Currency : ${transactionDetails.cryptoId || 'N/A'}`;

        default:
            return `${row.transactionId || 'N/A'}`;
    }
};

export const documentsFeilds = [
    {
        label: 'Online Commercial Registry Extract',
        key: 'onlineCommercialRegistryExtract',
    },
    {
        label: 'Certificate of Incorporation',
        key: 'certificateOfIncorporation',
    },
    {
        label: 'Articles/Memorandum of Association',
        key: 'articlesMemorandumOfAssociation',
    },
    {
        label: 'Proof of Business Address',
        key: 'proofOfBusinessAddress',
        toolTipText:
            'Utility bill issued within the last 90 days, referring to the principal place of business',
    },
    {
        label: 'Signed and Dated Company Structure Chart',
        key: 'signedAndDatedCompanyStructureChart',
    },
    {
        label: 'Ownership Structure / Shareholders Register',
        key: 'ownershipStructureShareholdersRegister',
        toolTipText: 'All shareholders holding 20% or more of the ownership of the company.',
    },
    {
        label: 'Directors Register',
        key: 'directorsRegister',
        toolTipText:
            'An official register of directors/recent extract from the relevant business register/certificate of incumbency/operating agreement',
    },
    {
        label: 'Bank Statement in the Company’s Name',
        key: 'bankStatementInTheCompanysName',
        toolTipText: 'Issued within the last 90 days with visible transaction flows',
    },
    {
        label: 'Operating Licences',
        key: 'operatingLicences',
    },
];

export const statusConfig = {
    Approved: {
        statusText: 'Approved',
        statusClassName: 'text-green-800 bg-[#ECFDF3] rounded-full py-1 px-5 text-sm font-medium',
    },
    Rejected: {
        statusText: 'Rejected',
        statusClassName: 'text-[#B54708] bg-[#FFFAEB;] rounded-full py-1 px-5 text-sm font-medium',
    },
    Onboarding: {
        statusText: 'Onboarding',
        statusClassName:
            'text-sm px-5 py-1 bg-[#F4F4F5] text-center rounded-2xl text-[#3F3F46] font-medium capitalize',
    },
    AwaitingApproval: {
        statusText: 'Awaiting Approval',
        statusClassName:
            'text-sm px-5 py-1 bg-[#EFF8FF] text-center rounded-2xl text-[#175CD3] font-medium capitalize',
    },
};
