import React, { useEffect, useState } from 'react';
import { QuestionCircleFilled, EyeOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { Delete, Download } from '../../utils/Images';
import apiRequest from '../../utils/api';
import Loading from '../common/Loading';
import { documentsFeilds } from '../../utils/constant';
import { useCommonFunctions } from '../../utils/common';

const DocumentsTab = () => {
    const [attachments, setAttachments] = useState([]);
    const [attachmentsError, setAttachmentsError] = useState('');
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [loading, setLoading] = useState(false);

    const email = localStorage.getItem('email');
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const { clientData } = useSelector(state => state.account);
    const { getUserOnboardingStatus } = useCommonFunctions();
    const isUserOnboarded = getUserOnboardingStatus(clientData);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        getClientBusinessData();
    }, []);

    const getClientBusinessData = async () => {
        const body = {
            email,
            clientEmail: clientData?.clientEmail,
        };
        try {
            const response = await apiRequest('/get-client-account-details', 'POST', body);
            if (response?.success) {
                setUploadedDocuments(response?.data?.documents || []);
            } else {
                message.error(response.error || 'Failed to fetch business data.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteBusinessDocument = async (label, fileName) => {
        const body = {
            email,
            clientEmail: clientData?.clientEmail,
            label,
            fileName,
        };
        try {
            const response = await apiRequest('/delete-business-document', 'POST', body);
            if (response?.success) {
                getClientBusinessData();
            } else {
                message.error(response.error || 'Failed to fetch business data.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleFile = async (e, backendKey) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files?.length) return;

        setAttachmentsError('');
        const labelExists = attachments.find(attachment => attachment[backendKey]);
        if (!labelExists) {
            setAttachments(prevAttachments => [...prevAttachments, { [backendKey]: [] }]);
        }

        const validFiles = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i].size <= 4 * 1024 * 1024) {
                validFiles.push(files[i]);
            } else {
                setAttachmentsError('File size exceeds the limit (4 MB)');
                setTimeout(() => {
                    setAttachmentsError('');
                }, 2000);
            }
        }

        if (validFiles.length > 0) {
            await uploadFiles(backendKey, validFiles);
        }
    };

    const uploadFiles = async (label, files) => {
        const filesArray = Array.from(files);
        const field = documentsFeilds.find(item => item.key === label);
        const backendKey = field?.key;

        if (!backendKey) {
            console.warn(`No mapping found for label: ${label}`);
            setAttachmentsError(`Invalid document label: ${label}`);
            return;
        }

        try {
            setLoading(true);
            const existingDocuments = uploadedDocuments[backendKey] || [];
            const duplicateFiles = filesArray.filter(file =>
                existingDocuments.some(doc => doc.name === file.name),
            );

            if (duplicateFiles.length > 0) {
                setAttachmentsError(`This file already exists.`);
                return;
            }

            const uploadedUrls = await Promise.all(
                filesArray.map(async (file, index) => {
                    const signedUrl = await getSignedUrl(file, file.name, index);
                    return {
                        name: file.name,
                        attachment: signedUrl,
                    };
                }),
            );

            const combinedDocuments = [...existingDocuments, ...uploadedUrls];
            const formattedData = { [backendKey]: combinedDocuments };
            await handleRegistrationStages('upload-documents', { documents: formattedData });
        } catch (error) {
            console.error('File upload failed:', error);
            setAttachmentsError('File upload failed.');
        } finally {
            setLoading(false);
        }
    };

    const handleFileDelete = (label, index) => {
        let attachmentsCopy = attachments;

        attachmentsCopy.map((item, index) => {
            if (item[label]) {
                if (item[label].length === 1) {
                    attachmentsCopy.splice(index, 1);
                    setAttachments(attachmentsCopy);
                }
            }
            setAttachments(prevData =>
                prevData.map(item => {
                    if (item[label]) {
                        const newArray = [...item[label]];
                        newArray.splice(index, 1);
                        return {
                            ...item,
                            [label]: newArray,
                        };
                    }
                    return item;
                }),
            );
        });
    };

    const handleViewFile = file => {
        if (typeof file === 'string') {
            window.open(file, '_blank');
            return;
        }

        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl, '_blank');
        URL.revokeObjectURL(fileUrl);
    };

    const handleDownloadFile = async (file, fileName) => {
        try {
            // For files that are already uploaded and have URLs
            if (typeof file === 'string') {
                const response = await fetch(file);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName || 'download';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                return;
            }

            // For local files that haven't been uploaded yet
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName || file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
            setAttachmentsError('Error downloading file');
            setTimeout(() => setAttachmentsError(''), 2000);
        }
    };

    const UploadInput = ({ label, backendKey, toolTipText }) => {
        const currentFiles = attachments.find(item => Object.keys(item)[0] === backendKey)?.[
            backendKey
        ];

        const documentsArr = uploadedDocuments[backendKey];

        const FileItem = ({ fileName, onView, onDownload, onDelete }) => (
            <div className="my-[12px] flex justify-between pb-3">
                <p className="text-[#0E7090] text-sm font-medium">{fileName}</p>
                <div className="flex items-center gap-6">
                    <EyeOutlined
                        className="cursor-pointer"
                        onClick={onView}
                        style={{
                            fontSize: '20px',
                            color: '#70707B',
                        }}
                    />
                    <img
                        src={`${imageBaseUrl}${Download}`}
                        onClick={onDownload}
                        alt="download"
                        className="w-5 h-5 cursor-pointer"
                    />
                    <img
                        src={`${imageBaseUrl}${Delete}`}
                        onClick={onDelete}
                        alt="delete"
                        className="w-5 h-5 cursor-pointer"
                    />
                </div>
            </div>
        );

        return (
            <>
                <p className="text-sm font-normal mb-[6px] text-gray-600 font-inter">
                    <span>{label}:</span>
                    {toolTipText && (
                        <Tooltip title={toolTipText} placement="right">
                            <span className="ml-1 cursor-pointer self-center">
                                <QuestionCircleFilled
                                    color="#70707B"
                                    sx={{
                                        color: '#70707B',
                                        fontSize: 16,
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )}
                </p>
                <div className="relative rounded-lg border-[1px] border-[#D1D1D6] px-[14px] py-[10px]">
                    <input
                        type="file"
                        id="files"
                        onChange={e => handleFile(e, backendKey)}
                        multiple
                        className="w-full cursor-pointer"
                    />
                </div>
                {(documentsArr?.length > 0 || currentFiles?.length > 0) && (
                    <div className="my-[12px]">
                        <p className="text-sm font-normal text-[#202939] pb-[6px] border-b-[1px] border-[#CDD5DF]">
                            File/Attachment name
                        </p>
                        {currentFiles?.map((file, index) => (
                            <FileItem
                                key={`${file?.name}-${index}`}
                                fileName={file.name}
                                onView={() => handleViewFile(file)}
                                onDownload={() => handleDownloadFile(file, file.name)}
                                onDelete={() => handleFileDelete(backendKey, index)}
                            />
                        ))}

                        {documentsArr?.map((file, index) => (
                            <FileItem
                                key={`${file.attachment}-${index}`}
                                fileName={file.name}
                                onView={() => handleViewFile(file.attachment)}
                                onDownload={() => handleDownloadFile(file.attachment, file.name)}
                                onDelete={() => handleDeleteBusinessDocument(backendKey, file.name)}
                            />
                        ))}
                    </div>
                )}
            </>
        );
    };

    const getSignedUrl = async (file, fileName, filecount) => {
        const payload = {
            email,
            imgcount: `file${filecount}`,
            imageType: 'userDocuments',
            fileName: fileName ?? 'images',
        };

        const index = parseInt(filecount);

        try {
            const response = await apiRequest('/get-client-presigned-url', 'POST', payload);
            if (response?.success) {
                const signedUrl = response.data?.data[0];
                await fetch(signedUrl, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': 'application/*',
                        'cache-control': 'public, max-age=0',
                    },
                });
                const imgUrl = `${imageBaseUrl}/user-documents/file${index}/${fileName}/documents`;
                return imgUrl;
            } else {
                setAttachmentsError(response?.error || 'Failed to get signed URL');
                message.error(response?.error);
            }
        } catch (e) {
            setAttachmentsError(e.message);
            console.error(e);
        }
    };

    const handleRegistrationStages = async (stage, data) => {
        const body = { stage, ...data, email: clientData?.clientEmail };
        try {
            const response = await apiRequest('/account-registration-stage', 'POST', body);

            if (response.success) {
                getClientBusinessData();
            } else {
                message.error(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="bg-[#ffffff] lg:p-0 p-[12px] flex flex-col gap-6">
            <div className="lg:p-0 lg:mt-0 bg-white"></div>
            {!loading ? (
                <>
                    {attachmentsError && <span className="text-[#f04438]">{attachmentsError}</span>}
                    <div className="flex flex-col gap-3">
                        {documentsFeilds.map(item => {
                            return (
                                <UploadInput
                                    key={item.key}
                                    backendKey={item.key}
                                    label={item.label}
                                    toolTipText={item?.toolTipText}
                                />
                            );
                        })}
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default DocumentsTab;
