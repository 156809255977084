import React from 'react';
import { LoadingParagraphSkeleton } from '../common/Loading';

const CardDesktop = ({ icon, count, color, title, onClick, loading }) => {
    if (loading) return <LoadingParagraphSkeleton />;

    return (
        <div
            className="min-w-[208px] p-5 flex flex-col justify-start gap-6 rounded-xl border border-gray-200 bg-white mb-1"
            onClick={onClick}>
            <div className="w-16 h-16 bg-[#F4F4F5] rounded-full flex justify-center items-center">
                {icon}
            </div>
            <div>
                <h3 className="text-gray-900 font-inter text-2xl font-medium">{count}</h3>
                <p style={{ color: color }} className="font-inter text-base font-medium">
                    {title}
                </p>
            </div>
        </div>
    );
};

export default CardDesktop;
