import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThemeButton from '../../common/ThemeButton';
import { Download, Edit } from '../../../utils/Images';
import TransactionActivity from './TransactionActivity';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import apiRequest from '../../../utils/api';
import moment from 'moment';
import AccountPurpose from './AccountPurpose';
import {
    getCountries,
    handleRegistrationPDFDownload,
    useCommonFunctions,
} from '../../../utils/common';
import { Input, Button, Form, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { singleClientData } from '../../../redux/actions/accountActions';

function PersonalTab() {
    const imageBaseUrl = useSelector(state => state.config?.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    const [accountDetails, setAccountDetails] = useState({});
    const email = localStorage.getItem('email');
    const { id } = useParams();
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const [form] = Form.useForm();
    const [isEditPersonal, setIsEditPersonal] = useState(false);
    const [isEditAddress, setIsEditAddress] = useState(false);
    const dispatch = useDispatch();
    const [isLoadingPersonal, setIsLoadingPersonal] = useState(false);
    const [isLoadingAddress, setIsLoadingAddress] = useState(false);
    const { getClientsDetails } = useCommonFunctions();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getClientPersonalData();
    }, []);

    const getClientPersonalData = async () => {
        const body = {
            email,
            clientEmail: clientData?.clientEmail,
        };
        try {
            const response = await apiRequest('/get-client-account-details', 'POST', body);
            if (response?.success) {
                setAccountDetails(response?.data || {});
            } else {
                message.error(response.error || 'Failed to fetch business data.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePostFetchDataAndLoading = async () => {
        if (id) {
            await getClientsDetails(id);
        }
        setIsLoadingPersonal(false);
        setIsLoadingAddress(false);
        setIsEditAddress(false);
        setIsEditPersonal(false);
    };

    const handleEditPersonal = () => {
        form.setFieldsValue({
            name: clientData.name,
            clientEmail: clientData.clientEmail,
            phoneNumber: clientData.phoneNumber,
            dob: moment(clientData.dob).format('YYYY-MM-DD'),
            country: clientData.country,
        });
        setIsEditPersonal(true);
    };
    const handleEditAddress = () => {
        form.setFieldsValue({
            address: clientData.address,
            city: clientData.city,
            state: clientData.state,
            postCode: clientData.postCode,
        });
        setIsEditAddress(true);
    };

    const handleSavePersonal = async () => {
        try {
            setIsLoadingPersonal(true);
            const formValues = form.getFieldsValue();
            const country = Array.isArray(formValues.country)
                ? formValues.country.join(', ')
                : formValues.country;
            const body = {
                email,
                userEmail: clientData.clientEmail,
                clientEmail: formValues.clientEmail,
                accountType: clientData.accountType,
                name: formValues.name,
                phoneNumber: formValues.phoneNumber,
                dob: formValues.dob,
                country: country,
            };

            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response) {
                handlePostFetchDataAndLoading();
            }
        } catch (error) {
            console.error('Error updating details:', error);
        }
    };

    const handleSaveaddress = async () => {
        try {
            setIsLoadingAddress(true);
            const formValues = form.getFieldsValue();
            const country = Array.isArray(formValues.country)
                ? formValues.country.join(', ')
                : formValues.country;
            const body = {
                email,
                userEmail: clientData.clientEmail,
                clientEmail: formValues.clientEmail,
                accountType: clientData.accountType,
                address: formValues.address,
                city: formValues.city,
                state: formValues.state,
                postCode: formValues.postCode,
                country: country,
            };

            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response) {
                handlePostFetchDataAndLoading();
            }
        } catch (error) {
            console.error('Error updating details:', error);
        }
    };
    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <div className="lg:pt-4 bg-white">
            <div className="p-4 md:p-0">
                <div className="flex items-center justify-between xs:mb-[10px] md:px-8 md:pt-2 lg:pt-4 lg:p-0 lg:mb-[10px]">
                    <h1 className="text-lg font-semibold">Personal Account</h1>

                    <div className="font-medium text-sm rounded-lg flex items-center gap-2 p-2">
                        {/* Edit Button First */}
                        <Button
                            loading={isLoadingPersonal}
                            onClick={isEditPersonal ? handleSavePersonal : handleEditPersonal}
                            icon={
                                !isEditPersonal && (
                                    <img
                                        src={`${imageBaseUrl}${Edit}`}
                                        alt=""
                                        className="w-5 h-5 "
                                    />
                                )
                            }
                            className={`h-[40px] w-[61px] gap-2 px-[14px] py-[8px] rounded-[8px] !min-w-10 !max-w-fit ${isEditPersonal ? 'bg-[#18181B] text-white' : 'bg-[#F4F4F5] text-black'}`}>
                            {isEditPersonal ? 'Save' : ''}
                        </Button>

                        {/* PDF Download Button */}
                        <ThemeButton
                            style="hidden md:inline-flex bg-[#f4f4f5] text-[#18181b] font-light !hover:bg-none"
                            textCss={'font-medium text-[#51525c]'}
                            text="PDF file"
                            icon={
                                <img
                                    src={`${imageBaseUrl}${Download}`}
                                    alt="Download"
                                    className="w-4 h-4"
                                />
                            }
                            action={() =>
                                handleRegistrationPDFDownload(
                                    email,
                                    paymentProviderId,
                                    clientData?.clientEmail,
                                )
                            }
                        />

                        {/* Mobile Version of Download Button */}
                        <div
                            className="md:hidden bg-white pr-[5px] cursor-pointer"
                            onClick={() =>
                                handleRegistrationPDFDownload(
                                    email,
                                    paymentProviderId,
                                    clientData?.clientEmail,
                                )
                            }>
                            <img src={`${imageBaseUrl}${Download}`} alt="Download" />
                        </div>
                    </div>
                </div>

                <Form form={form}>
                    <div className="flex gap-[10px] flex-col mt-2 sm:px-[10px] md:px-8 md:pt-2 md:mt-0 lg:p-0">
                        <h1 className="text-[#51525C]">Full name:</h1>
                        {isEditPersonal ? (
                            <Form.Item name="name">
                                <Input />
                            </Form.Item>
                        ) : (
                            <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                    {clientData?.name}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="flex gap-[10px] flex-col mt-[10px] lg:flex-row md:px-8 lg:pt-2 lg:p-0">
                        <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                            <h1 className="text-[#51525C]">Email:</h1>
                            {isEditPersonal ? (
                                <Form.Item name="clientEmail">
                                    <Input />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                    <p className="text-[#8b8b9b] font-normal mailto:font-inter min-h-[20px]">
                                        {clientData.clientEmail}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-[10px] flex-col lg:w-1/2 lg:p-0">
                            <h1 className="text-[#51525C]">Phone:</h1>
                            {isEditPersonal ? (
                                <Form.Item
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject(
                                                        '* Phone Number is required',
                                                    );
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        },
                                        {
                                            pattern: /^\+?[1-9]\d{0,14}$/,
                                            message: '* Please enter a valid phone number',
                                        },
                                    ]}
                                    name="phoneNumber">
                                    <Input />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                    <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                        {clientData.phoneNumber}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex gap-[10px] flex-col mt-[5px] lg:flex-row md:px-8 md:pt-2 lg:p-0">
                        <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                            <h1 className="text-[#51525C]">Date of Birth:</h1>
                            {isEditPersonal ? (
                                <Form.Item name="dob">
                                    <Input type="date" />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                    <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                        {moment(clientData.dob).format('DD/MM/YYYY')}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-[10px] flex-col lg:w-1/2">
                            <h1 className="text-[#51525C]">Country of residence:</h1>
                            {isEditPersonal ? (
                                <Form.Item name="country">
                                    <Select>
                                        {countries.length > 0 ? (
                                            countries.map((country, key) => (
                                                <Option key={key} value={country.name}>
                                                    {country.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="">Loading...</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5] h-[44px] text-[#8b8b9b] justify-between">
                                    <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                        {clientData.country}
                                    </p>
                                    <DownOutlined />
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            </div>
            <div className="p-4 md:p-0">
                <div className="flex items-center justify-between xs:mb-[10px] md:px-8 md:pt-2 lg:pt-4 lg:p-0 lg:mb-[10px]">
                    <h1 className="text-lg font-semibold">Full Address</h1>
                    <Button
                        loading={isLoadingAddress}
                        onClick={isEditAddress ? handleSaveaddress : handleEditAddress}
                        icon={
                            !isEditAddress && (
                                <img src={`${imageBaseUrl}${Edit}`} alt="" className="w-5 h-5 " />
                            )
                        }
                        className={`absolute right-0 h-8 !min-w-10 !max-w-fit ${isEditAddress ? 'bg-[#18181B] text-white' : 'bg-[#F4F4F5] text-black'}`}>
                        {isEditAddress ? 'Save' : ''}
                    </Button>
                </div>
                <Form form={form}>
                    <div className="flex gap-[10px] flex-col mt-[5px] lg:flex-row md:px-8 md:pt-2 lg:p-0">
                        <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                            <h1 className="text-[#51525C]">Personal address line*</h1>
                            {isEditAddress ? (
                                <Form.Item name="address">
                                    <Input placeholder="Enter address line" />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                    <p className="text-[#8b8b9b] font-normal mailto:font-inter min-h-[20px]">
                                        {clientData.address}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-[10px] flex-col lg:w-1/2 lg:p-0">
                            <h1 className="text-[#51525C]">City*</h1>
                            {isEditAddress ? (
                                <Form.Item name="city">
                                    <Input placeholder="Enter city" />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                    <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                        {clientData.city}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex gap-[10px] flex-col mt-[5px] lg:flex-row md:px-8 md:pt-2 lg:p-0">
                        <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                            <h1 className="text-[#51525C]">State / Province / Region*</h1>
                            {isEditAddress ? (
                                <Form.Item name="state">
                                    <Input placeholder="Enter state / province / region" />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                    <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                        {clientData.state}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-[10px] flex-col lg:w-1/2">
                            <h1 className="text-[#51525C]">ZIP / Postal Code*</h1>
                            {isEditAddress ? (
                                <Form.Item name="postCode">
                                    <Input placeholder="Enter zip / postal code" />
                                </Form.Item>
                            ) : (
                                <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5] h-[44px] text-[#8b8b9b] justify-between">
                                    <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                        {clientData.postCode}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            </div>
            <AccountPurpose
                accountPurposeData={accountDetails}
                email={email}
                clientEmail={clientData?.clientEmail}
                accountType={accountDetails?.accountType}
            />
            <TransactionActivity
                transactionActivityData={accountDetails.transactionActivity}
                email={email}
                clientEmail={clientData?.clientEmail}
                accountType={accountDetails?.accountType}
            />
        </div>
    );
}

export default PersonalTab;
