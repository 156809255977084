import React from 'react';
import { LoadingAvatarSkeleton } from '../common/Loading';

const CardMobile = ({ icon, count, color, title, onClick, loading }) => {
    if (loading) return <LoadingAvatarSkeleton />;

    return (
        <div className="" onClick={onClick}>
            <div className="border-solid border transition-all border-transparent hover:border-[#d1d1d6] p-4 lg:p-6 bg-white rounded-xl flex justify-between">
                <div className="w-16 h-16 bg-[#F4F4F5] rounded-full p-4 flex justify-center items-center">
                    {icon}
                </div>
                <div className="text-right">
                    <h3 className="text-2xl font-medium text-primary">{count}</h3>
                    <p style={{ color: color }} className="text-base font-medium">
                        {title}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CardMobile;
