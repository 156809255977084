import { Modal, Select, Spin, Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, SearchOutlined, CheckOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import { useSelector, useDispatch } from 'react-redux';
import { getBalancesData, getCryptoBalances, useCommonFunctions } from '../../utils/common';
import { cryptoTransactionTypes } from '../../utils/constant';
import SellCryptoTransactionModal from './SellCryptoTransactionModal';
import BuyCryptoTransactionModal from './BuyCryptoTransactionModal';
import { ReceiveCryptoModal } from './ReceiveCryptoModal';
import { ExchangeCryptoModal } from './ExchangeCryptoModal';
import SendCryptoTransactionModal from './SendCryptoTransactionModal';
import { FormInput } from '../forms';
import { singleClientData } from '../../redux/actions/accountActions';
import useDebounce from '../../utils/useDebounce';

const { Option } = Select;

const CryptoTransactionModal = ({ open, handleClose, onBack, clients, fetchClients }) => {
    const { clientData, cryptoBalancesData: cryptoBalances } = useSelector(state => state.account);
    const [searchClientName, setSearchClientName] = useState();
    const dispatch = useDispatch();
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const email = localStorage.getItem('email');
    const [selectedClient, setSelectedClient] = useState({});
    const [unfreezeBalances, setUnfreezeBalances] = useState([]);
    const { getUserOnboardingStatus } = useCommonFunctions();
    const isUserOnboarded = getUserOnboardingStatus(selectedClient);

    useEffect(() => {
        const filteredBalances = cryptoBalances.filter(balance => !balance.isFreeze);
        setUnfreezeBalances(filteredBalances);
    }, [cryptoBalances]);

    const openModalAction = modalType => {
        setIsModalOpen(true);
        setOpenModal(modalType);
    };
    const optionClick = item => {
        setSelectedClient(item);
        form.setFieldsValue({
            accountNickName: item.name,
            clientId: item.clientId,
        });
    };
    const getClientsDetails = async () => {
        const body = {
            email: email,
            clientId: selectedClient?.clientId,
        };
        try {
            const response = await apiRequest('/get-single-client-data', 'POST', body);
            if (response.success) {
                if (response.data) {
                    dispatch(singleClientData(response.data));
                }
            } else {
                message.error(response.error);
                return { error: response.error };
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const isSelectedClient = Object.keys(selectedClient).length > 0;
        if (isSelectedClient) {
            getClientsDetails();
        }
    }, [selectedClient]);

    const createTransaction = async body => {
        try {
            const response = await apiRequest('/create-client-transaction-crypto', 'POST', body);
            if (response.success) {
                await getCryptoBalances(dispatch, clientData?.clientId, email, setBalanceLoading);
                await getBalancesData(
                    dispatch,
                    clientData?.clientId,
                    email,
                    clientData?.clientEmail,
                    setBalanceLoading,
                );
                return { success: true };
            } else {
                message.error(response.error);
                return { error: response.error };
            }
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    const onModalClose = () => {
        setIsModalOpen(false);
        setOpenModal('');
        setSelectedClient({});
        setSearchClientName();
    };

    useEffect(() => {
        Object.keys(selectedClient).length != 0 &&
            getBalancesData(
                dispatch,
                selectedClient?.clientId,
                email,
                selectedClient?.clientEmail,
                setBalanceLoading,
            );
        getCryptoBalances(dispatch, selectedClient?.clientId, email, setBalanceLoading);
    }, [selectedClient]);
    const handleSearch = useDebounce(value => {
        fetchClients(value);
    }, 500);
    const handleChange = newValue => {
        setSearchClientName(newValue);
        fetchClients(newValue);
    };
    const onCancel = () => {
        setSelectedClient({});
        setSearchClientName();
    };

    return (
        <>
            <Modal
                className="common-mobile-view"
                width={570}
                centered
                title={
                    <div className="flex items-center">
                        <ArrowLeftOutlined
                            className="hidden lg:block text-xl mr-[12px] w-6 h-6"
                            onClick={onBack}
                        />
                        <p className="text-lg md:text-2xl font-semibold relative flex">
                            Create Crypto Transaction
                        </p>
                    </div>
                }
                open={open}
                onCancel={() => {
                    onCancel();
                    handleClose();
                }}
                handleClose={() => {
                    onCancel();
                    handleClose();
                }}
                footer={false}>
                <Form
                    form={form}
                    layout="horizontal"
                    className="h-[75vh] form-input overflow-x-auto pr-2 mt-8 hide-scrollbar"
                    colon={false}>
                    <Select
                        showSearch
                        suffixIcon={
                            <>
                                {fetching && <Spin size="small" />}
                                <SearchOutlined className="text-lg text-[#51525C]" />
                            </>
                        }
                        value={searchClientName}
                        placeholder="Search Account Id , Account name"
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        data-e2e="search-option"
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={!fetching && 'Not Found'}
                        className="w-full rounded-l h-[40px] overflow-y-auto"
                        optionLabelProp="label">
                        {clients?.map((item, index) => {
                            return (
                                <Option
                                    className={'!bg-transparent !font-light !py-3'}
                                    key={item?.clientId + '-option'}
                                    value={item?.name}
                                    label={item?.name}>
                                    <div
                                        className="flex justify-between"
                                        onClick={() => optionClick(item)}>
                                        <div>
                                            <p className="text-base" data-e2e={item?.name}>
                                                {item?.name}
                                            </p>
                                            <p className="text-xs" data-e2e={item?.clientId}>
                                                {item?.clientId}
                                            </p>
                                        </div>
                                        <p>
                                            {selectedClient.clientId === item?.clientId && (
                                                <CheckOutlined className="text-lg text-[#51525C]" />
                                            )}
                                        </p>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                    {isUserOnboarded && (
                        <div className="text-[#df4444] text-sm mt-2">
                            <sup>*</sup>
                            {isUserOnboarded}
                        </div>
                    )}
                    <FormInput
                        label="Account name:"
                        name="accountNickName"
                        value={selectedClient?.name}
                        placeholder="Enter Account Name"
                        dataE2e="enter-account-name"
                        readOnly
                    />
                    <FormInput
                        label="Account ID:"
                        name="clientId"
                        value={selectedClient?.clientId}
                        placeholder="Enter Account ID"
                        dataE2e="account-id"
                        readOnly
                    />
                    <div className="transaction-actions">
                        {cryptoTransactionTypes.map((item, index) => (
                            <button
                                disabled={
                                    balanceLoading ||
                                    Object.keys(selectedClient).length === 0 ||
                                    unfreezeBalances.length === 0 ||
                                    (item.value === 'Exchange' && unfreezeBalances.length <= 1) ||
                                    isUserOnboarded
                                }
                                className={`transaction-actions-btn disabled:opacity-50`}
                                key={index}
                                onClick={() => {
                                    openModalAction(item.value);
                                    handleClose();
                                }}>
                                <div className="transaction-actions-icon">
                                    <img
                                        data-e2e={item.value}
                                        src={`${imageBaseUrl}${item.icon}`}
                                        alt="crypto-transaction-types"
                                    />
                                </div>
                                <p className="transaction-actions-title">{item.value}</p>
                            </button>
                        ))}
                    </div>
                </Form>
            </Modal>
            {isModalOpen && (
                <SellCryptoTransactionModal
                    client={selectedClient}
                    open={isModalOpen && openModal === 'Sell'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                />
            )}
            {isModalOpen && (
                <BuyCryptoTransactionModal
                    client={selectedClient}
                    open={isModalOpen && openModal === 'Buy'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                />
            )}
            {isModalOpen && (
                <ReceiveCryptoModal
                    open={isModalOpen && openModal === 'Receive'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                    client={selectedClient}
                />
            )}
            {isModalOpen && (
                <ExchangeCryptoModal
                    client={selectedClient}
                    open={isModalOpen && openModal === 'Exchange'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                />
            )}
            {isModalOpen && (
                <SendCryptoTransactionModal
                    client={selectedClient}
                    open={isModalOpen && openModal === 'Send'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                />
            )}
        </>
    );
};

export default CryptoTransactionModal;
