import React, { useEffect, useState } from 'react';
import { Divider, Input, message, Pagination, Space, Spin, Switch, Table } from 'antd';
import apiRequest from '../../utils/api';
import { SearchOutlined } from '@ant-design/icons';
import useDebounce from '../../utils/useDebounce';
import CustomPagination from '../layout/CustomPagination';
import { useCommonFunctions } from '../../utils/common';

const CountryManagement = ({ tab }) => {
    const email = localStorage.getItem('email');
    const { getAllCountries } = useCommonFunctions();
    const [search, setSearch] = useState('');
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [switchLoading, setSwitchLoading] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });

    useEffect(() => {
        if (tab === '4') {
            getCountires(1);
        }
    }, [tab]);

    const columns = [
        {
            title: 'Country',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Enable / Disable',
            key: 'enabled',
            render: row => {
                console.log('row', row);
                return (
                    <Switch
                        onChange={checked => handleSwitchChange(row._id, checked)}
                        checked={row.enabled ? true : false}
                        loading={!!switchLoading[row._id]}
                    />
                );
            },
        },
    ];

    const getCountires = async (page, value = '') => {
        setLoading(true);
        const body = {
            page,
            limit: 10,
            searchName: value,
        };
        const response = await getAllCountries(body);
        if (response.success) {
            setData(response.data.allCountries);
            setPagination(prev => ({
                ...prev,
                total: response.data.totalCount,
                current: page,
            }));
            setLoading(false);
        } else {
            message.error(response.error || 'Failed to fetch countries');
            setLoading(false);
        }
    };

    const handleSwitchChange = async (id, checked) => {
        setSwitchLoading(prev => ({ ...prev, [id]: true }));
        const body = {
            email,
            countryId: id,
            enabled: checked,
        };
        const response = await apiRequest('/disable-country', 'POST', body);
        if (response.success) {
            message.success(response.data?.message);
            getCountires(pagination.current, search);
            setSwitchLoading(prev => ({ ...prev, [id]: false }));
        } else {
            message.error(response.error || 'Failed to update country status');
            setSwitchLoading(prev => ({ ...prev, [id]: false }));
        }
    };

    const searchCountires = useDebounce(value => {
        getCountires(1, value);
    }, 500);

    const handleTableChange = pagination => {
        const { current } = pagination;
        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));

        getCountires(current, search);
    };

    const handlePagination = page => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
        }));
        getCountires(page, search);
    };

    return (
        <>
            <div className="pt-8 lg:block hidden w-full accounts-page">
                <div className="flex items-center justify-between px-6">
                    <h2 className="page-title">Country Management</h2>
                    <Space>
                        <Input
                            value={search}
                            placeholder="Search country"
                            data-e2e="search-bar"
                            suffix={<SearchOutlined />}
                            className="w-[460px] p-2"
                            name="searchText"
                            onChange={e => {
                                setSearch(e.target.value);
                                searchCountires(e.target.value);
                            }}
                            allowClear
                        />
                    </Space>
                </div>
                <Table
                    dataSource={data}
                    loading={loading}
                    className="rounded-b-lg custom-table mt-6 settings-table"
                    columns={columns}
                    rowKey={record => record._id}
                    pagination={{
                        position: ['bottomCenter'],
                        itemRender: CustomPagination,
                        showSizeChanger: false,
                        current: pagination.current,
                        total: pagination.total,
                    }}
                    onChange={handleTableChange}
                />
            </div>
            <div className="block lg:hidden">
                <>
                    <div className="flex flex-col gap-4 m-3">
                        <Input
                            value={search}
                            placeholder="Search country"
                            data-e2e="search-bar"
                            suffix={<SearchOutlined />}
                            className="w-full p-2 h-8"
                            name="searchText"
                            onChange={e => {
                                setSearch(e.target.value);
                                searchCountires(e.target.value);
                            }}
                            allowClear
                        />
                        <h2 className="text-sm font-medium">Country</h2>
                    </div>

                    {!loading ? (
                        <>
                            {data?.map((item, key) => {
                                return (
                                    <div key={key} className="bg-white border-b border-[#E4E4E7]">
                                        <div className="flex justify-between items-center p-3 cursor-pointer">
                                            <div className="flex gap-2 items-center">
                                                <div>
                                                    <h1 className="text-sm font-medium pb-1 text-[#18181B]">
                                                        {item.name || 'N/A'}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="flex gap-4 items-center">
                                                <Switch
                                                    onChange={checked =>
                                                        handleSwitchChange(item._id, checked)
                                                    }
                                                    checked={item.enabled}
                                                    loading={!!switchLoading[item._id]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <Pagination
                                total={pagination.total}
                                responsive
                                className="py-3 flex justify-center"
                                current={pagination.current}
                                onChange={handlePagination}
                                showSizeChanger={false}
                            />
                        </>
                    ) : (
                        <div className="flex items-center justify-center h-[50vh] ">
                            <Spin />
                        </div>
                    )}
                </>
            </div>
        </>
    );
};

export default CountryManagement;
