import React from 'react';
import { Button, Modal } from 'antd';

const ConfirmationModal = ({ open, title, content, handleClose, handleConfirm }) => {
    return (
        <div>
            <Modal
                open={open}
                width={'422px'}
                height={'330px'}
                destroyOnClose
                centered
                footer={false}
                className="delete-modal"
                closable={false}>
                <div
                    className={`flex flex-col gap-4 items-center pt-12 border-t-4 rounded-t-md px-6 pb-6 `}>
                    <p className="font-semibold text-xl text-[#26272B]">{title}</p>
                    <p className="font-normal text-base items-center text-center text-[#3F3F46] self-stretch ">
                        {content}
                    </p>
                </div>

                <hr />
                <div className="flex justify-center rounded-b-3xl bg-slate-100 p-6">
                    <Button
                        onClick={handleClose}
                        data-e2e="cancel-btn"
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl !text-[#3F3F46] font-semibold !bg-[#D1D1D6] mr-3 text-sm`}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleConfirm()}
                        data-e2e="delete-btn"
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl !text-white font-semibold !bg-[#18181B]  text-sm`}>
                        I confirm
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmationModal;
