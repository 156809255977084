import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../components/layout/Sidebar';
import '../styles/notifications.css';
import { Divider, Table, message } from 'antd';
import { renderColumns } from '../const/notifications';
import CustomPagination from '../components/layout/CustomPagination';
import NotificationsDetailsList from '../components/notifications/NotificationsDetailsList';
import NotificationsMobileList from '../components/notifications/NotificationsMobileList';
import NotificationsFilters from '../components/notifications/NotificationsFilters';
import NotificationsFilterTags from '../components/notifications/NotificationsFilterTags';
import apiRequest from '../utils/api';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setNotificationCount } from '../redux/actions/notificationAction';
import { useCommonFunctions } from '../utils/common';

export default function Notifications() {
    const notificationCount = useSelector(state => state?.notification?.notificationCount);
    const [data, setData] = useState();
    const [sortConfig, setSortConfig] = useState({ sortBy: '', orderBy: '' });
    const [loading, setLoading] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [activeRecord, setActiveRecord] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [filters, setFilters] = useState({});
    const [filtersForm, setFiltersForm] = useState({});
    const [detailsLoader, setDetailsLoader] = useState(false);

    const email = localStorage.getItem('email');
    const dispatch = useDispatch();
    const { getUnreadNotificationCount } = useCommonFunctions();

    async function handleShowAdditionalInfo(record) {
        if (record?.relatedTransactionId === activeRecord?.transactionId) {
            setShowAdditionalInfo(prev => !prev);
        } else {
            setShowAdditionalInfo(true);
            try {
                const body = {
                    email,
                    transactionId: record?.relatedTransactionId,
                };
                setDetailsLoader(true);
                const response = await apiRequest(
                    '/get-single-transaction-of-client',
                    'POST',
                    body,
                );
                if (response?.success || response?.data) {
                    setActiveRecord(response?.data[0]);
                } else {
                    message.error(response.error);
                }
                setDetailsLoader(false);
            } catch (error) {
                setDetailsLoader(false);
                console.error(error);
            }
        }
    }

    const handleStatusChange = async record => {
        const body = { email, notificationId: record._id, status: 'read' };
        if (record?.status === 'unread') {
            try {
                const response = await apiRequest('/update-notification', 'POST', body);
                if (response?.success || response?.data) {
                    setData(prevData =>
                        prevData.map(item =>
                            item._id === response?.data?._id
                                ? { ...item, status: response?.data?.status }
                                : item,
                        ),
                    );
                    dispatch(setNotificationCount(notificationCount - 1));
                } else {
                    message.error(response.error);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        const { current } = newPagination;
        const { columnKey, order } = sorter;
        setPage(current);

        let sortBy, orderBy;
        if (order === 'ascend') {
            sortBy = columnKey;
            orderBy = 'asc';
        } else if (order === 'descend') {
            sortBy = columnKey;
            orderBy = 'desc';
        }
        setSortConfig({ sortBy, orderBy });
    };

    function clearQueryRender() {
        setFilters({});
        setFiltersForm({});
    }

    useEffect(() => {
        getNotifications();
        getUnreadNotificationCount();
    }, [filters, page, sortConfig]);

    const getNotifications = useCallback(async () => {
        let filtersObj = { ...filters };
        if (filters?.fromDate || filters?.toDate) {
            filtersObj.fromDate = moment(filters?.fromDate?.$d)
                .startOf('day')
                .format('YYYY-MM-DD 00:00');
            filtersObj.toDate = moment(filters?.toDate?.$d).endOf('day').format('YYYY-MM-DD 23:59');
        }

        try {
            const body = {
                email,
                sortBy: sortConfig?.sortBy,
                orderBy: sortConfig?.orderBy,
                page,
                ...filtersObj,
            };
            setLoading(true);
            const response = await apiRequest('/fetch-notifications', 'POST', body);
            if (response?.success || response?.data) {
                setData(response?.data?.notificationList);
                ``;
                setTotalPages(response?.data?.totalCount);
            } else {
                message.error(response.error);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [filters, page, sortConfig]);

    return (
        <Sidebar>
            <div className="bg-[#F7F7F7] h-full w-full settings-tab">
                <div className="hidden lg:block lg:px-[24px] h-full py-[16px] ">
                    <div
                        className={`notif desktop-page-block ${showAdditionalInfo ? 'has-details' : ''}`}>
                        <div className="notif__main">
                            <div className="p-6 pb-0">
                                <h2 className="page-title">
                                    Notifications{' '}
                                    {notificationCount > 0 && (
                                        <span className="notif__count">{notificationCount}</span>
                                    )}
                                </h2>
                                <Divider />
                                <div className="notif__filters">
                                    <NotificationsFilters
                                        showAdditionalInfo={showAdditionalInfo}
                                        setFilters={setFilters}
                                        filters={filters}
                                        open={filterModal}
                                        setOpen={setFilterModal}
                                        setPage={setPage}
                                        filtersForm={filtersForm}
                                        setFiltersForm={setFiltersForm}
                                    />
                                    <NotificationsFilterTags
                                        clearQueryRender={clearQueryRender}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                </div>
                            </div>
                            <div className="notif__table">
                                <Table
                                    dataSource={data}
                                    columns={renderColumns()}
                                    className="cursor-pointer rounded-b-lg custom-table mt-6 settings-table"
                                    scroll={true}
                                    rowKey={record => record._id}
                                    loading={loading}
                                    pagination={{
                                        position: ['bottomCenter'],
                                        itemRender: CustomPagination,
                                        showSizeChanger: false,
                                        current: page,
                                        total: totalPages,
                                    }}
                                    onChange={handleTableChange}
                                    rowClassName={record => {
                                        if (
                                            activeRecord?._id === record._id &&
                                            showAdditionalInfo
                                        ) {
                                            return 'active-row';
                                        }
                                    }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                handleShowAdditionalInfo(record);
                                                handleStatusChange(record);
                                            },
                                        };
                                    }}
                                />
                            </div>
                        </div>
                        {showAdditionalInfo && (
                            <div className="notif__details">
                                <h2 className="page-title p-6 !pb-0">Transaction</h2>
                                <Divider />
                                <div className="px-6">
                                    <NotificationsDetailsList
                                        item={activeRecord}
                                        detailsLoader={detailsLoader}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <NotificationsMobileList
                    data={data}
                    loading={loading}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    setFilterModal={setFilterModal}
                    clearQueryRender={clearQueryRender}
                    filters={filters}
                    setFilters={setFilters}
                    setFiltersForm={setFiltersForm}
                    handleStatusChange={handleStatusChange}
                    activeRecord={activeRecord}
                    detailsLoader={detailsLoader}
                    handleShowAdditionalInfo={handleShowAdditionalInfo}
                />
            </div>
        </Sidebar>
    );
}
