import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import React from 'react';
const { Option } = Select;

const EventLog = ({ logs }) => {
    const columns = [
        {
            title: 'Event Status',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Event Message',
            dataIndex: 'descriptions',
            key: 'descriptions',
        },
        {
            title: 'Event Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Admin who changes',
            dataIndex: 'admin',
            key: 'admin',
        },
    ];

    const parseAndFormatJson = jsonString => {
        try {
            const parsedObject = JSON.parse(jsonString);
            if (typeof parsedObject === 'string') {
                return parsedObject;
            } else {
                return JSON.stringify(parsedObject, null, 2);
            }
        } catch (error) {
            return jsonString;
        }
    };

    const dataSource =
        logs.length > 0
            ? logs.map((log, index) => ({
                  key: index + 1,
                  type: log.code,
                  descriptions: 'NA',
                  date: moment(log.updated).format('DD.MM.YYYY HH:mm:ss.SSS'),
                  admin: 'NA',
                  jsonData: parseAndFormatJson(log.message),
              }))
            : [];
    return (
        <div className="mt-8">
            <h1 className="text-lg font-semibold">Event Log</h1>
            <div className="flex gap-5 mt-5">
                {/* <div className="">
                    <span className="text-sm font-normal text-darkGray">Date:</span>
                    <DatePicker className="w-full p-2 mt-2" />
                </div>
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Admin action:</span>
                    <Select placeholder="Select an option" className="w-full custom-select mt-2">
                        <Option value="option1">Option 1</Option>
                        <Option value="option2">Option 2</Option>
                        <Option value="option3">Option 3</Option>
                    </Select>
                </div> */}
            </div>
            <div className="mt-10">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    expandable={{
                        expandedRowRender: record => (
                            <div className="flex justify-center">
                                <div className="whitespace-pre-wrap py-3 px-auto text-base font-mono w-fit">
                                    {record.jsonData}
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default EventLog;
