import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThemeButton from '../../common/ThemeButton';
import { Download, Edit } from '../../../utils/Images';
import TransactionActivity from './TransactionActivity';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import apiRequest from '../../../utils/api';
import AccountPurpose from './AccountPurpose';
import { Button, Divider, Input, Form, Select } from 'antd';
import DocumentsTab from '../DocumentsTab';
import {
    getCountries,
    handleRegistrationPDFDownload,
    useCommonFunctions,
} from '../../../utils/common';
import { useParams } from 'react-router-dom';
import { singleClientData } from '../../../redux/actions/accountActions';

function BusinessTab() {
    const imageBaseUrl = useSelector(state => state.config?.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    const [accountDetails, setAccountDetails] = useState({});
    const email = localStorage.getItem('email');
    const [activeTab, setActiveTab] = useState('additionalInformation');
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const [form] = Form.useForm();
    const [isEditBussiness, setIsEditBussiness] = useState(false);
    const [isEditAddress, setIsEditAddress] = useState(false);
    const dispatch = useDispatch();
    const [isLoadingBussiness, setIsLoadingBussiness] = useState(false);
    const [isLoadingAddress, setIsLoadingAddress] = useState(false);
    const { id } = useParams();
    const { getClientsDetails } = useCommonFunctions();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getClientBusinessData();
    }, []);

    const getClientBusinessData = async () => {
        const body = {
            email,
            clientEmail: clientData?.clientEmail,
        };
        try {
            const response = await apiRequest('/get-client-account-details', 'POST', body);
            if (response?.success) {
                setAccountDetails(response?.data || {});
            } else {
                message.error(response.error || 'Failed to fetch business data.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePostFetchDataAndLoading = async () => {
        if (id) {
            await getClientsDetails(id);
        }
        setIsLoadingAddress(false);
        setIsLoadingBussiness(false);
        setIsEditAddress(false);
        setIsEditBussiness(false);
    };

    const handleEditBussiness = () => {
        form.setFieldsValue({
            name: clientData.name,
            clientEmail: clientData.clientEmail,
            fullLegalEntityName: clientData.fullLegalEntityName,
            businessRepresentativeName: clientData.businessRepresentativeName,
            countryOfIncorporation: clientData.countryOfIncorporation,
            businessWebsite: clientData.businessWebsite,
        });
        setIsEditBussiness(true);
    };
    const handleEditAddress = () => {
        form.setFieldsValue({
            address: clientData.address,
            city: clientData.city,
            state: clientData.state,
            postCode: clientData.postCode,
        });
        setIsEditAddress(true);
    };

    const handleSaveBussiness = async () => {
        try {
            setIsLoadingBussiness(true);
            const formValues = form.getFieldsValue();
            const country = Array.isArray(formValues.countryOfIncorporation)
                ? formValues.countryOfIncorporation.join(', ')
                : formValues.countryOfIncorporation;

            const body = {
                email,
                userEmail: clientData.clientEmail,
                clientEmail: formValues.clientEmail,
                accountType: clientData.accountType,
                fullLegalEntityName: formValues.fullLegalEntityName,
                businessRepresentativeName: formValues.businessRepresentativeName,
                countryOfIncorporation: formValues.countryOfIncorporation,
                businessWebsite: formValues.businessWebsite,
                countryOfIncorporation: country,
            };

            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response) {
                handlePostFetchDataAndLoading();
            }
        } catch (error) {
            console.error('Error updating details:', error);
        }
    };

    const handleSaveaddress = async () => {
        try {
            setIsLoadingAddress(true);
            const formValues = form.getFieldsValue();
            const body = {
                email,
                userEmail: clientData.clientEmail,
                clientEmail: formValues.clientEmail,
                accountType: clientData.accountType,
                address: formValues.address,
                city: formValues.city,
                state: formValues.state,
                postCode: formValues.postCode,
            };

            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response) {
                handlePostFetchDataAndLoading();
            }
        } catch (error) {
            console.error('Error updating details:', error);
        }
    };

    const handleTabClick = tab => {
        setActiveTab(tab);
    };

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <div className="lg:pt-4 bg-white">
            <div className="pl-[15px] md:pl-0">
                <div className="flex items-center justify-between xs:mb-[10px] md:px-8 md:pt-2 lg:pt-4 lg:p-0 lg:mb-[10px]">
                    <h1 className="text-lg font-semibold mt-[10px]">Business Account</h1>
                </div>
                <div className=" md:px-8 lg:p-0 flex gap-4">
                    <Button
                        className={`${activeTab === 'additionalInformation' ? 'border-b-[2px] border-black' : ''} border-r-[0] border-l-[0] border-t-[0] rounded-none font-inter text-base font-medium leading-6 text-center text-[#18181B] px-0`}
                        onClick={() => handleTabClick('additionalInformation')}>
                        Additional Information
                    </Button>
                    <Button
                        className={`${activeTab === 'businessDocumentation' ? 'border-b-[2px] border-black' : ''}  border-r-[0] border-l-[0] border-t-[0] rounded-none font-inter text-base font-medium leading-6 text-center text-[#18181B] px-0`}
                        onClick={() => handleTabClick('businessDocumentation')}>
                        Business Documentation
                    </Button>
                </div>
            </div>
            <Divider className="m-0" />
            {activeTab === 'additionalInformation' && (
                <div className="p-0 md:p-0">
                    <div className="p-4 md:p-0">
                        <div className="flex items-center justify-between xs:mb-[10px] md:px-8 md:pt-2 lg:pt-4 lg:p-0 lg:mb-[10px]">
                            <h1 className="text-lg font-semibold">Business Details</h1>
                            <div className="font-medium text-sm  rounded-lg flex items-center gap-2 p-2">
                                <Button
                                    loading={isLoadingBussiness}
                                    onClick={
                                        isEditBussiness ? handleSaveBussiness : handleEditBussiness
                                    }
                                    icon={
                                        !isEditBussiness && (
                                            <img
                                                src={`${imageBaseUrl}${Edit}`}
                                                alt=""
                                                className="w-5 h-5 "
                                            />
                                        )
                                    }
                                    className={`h-[40px] w-[61px] gap-2 px-[14px] py-[8px] rounded-[8px] !min-w-10 !max-w-fit ${isEditBussiness ? 'bg-[#18181B] text-white' : 'bg-[#F4F4F5] text-black'}`}>
                                    {isEditBussiness ? 'Save' : ''}
                                </Button>

                                <ThemeButton
                                    style="hidden md:inline-flex bg-[#f4f4f5] text-[#18181b] font-light !hover:bg-none"
                                    textCss={'font-medium text-[#51525c]'}
                                    text="PDF file"
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${Download}`}
                                            alt="Download"
                                            className="w-4 h-4"
                                        />
                                    }
                                    action={() =>
                                        handleRegistrationPDFDownload(
                                            email,
                                            paymentProviderId,
                                            clientData?.clientEmail,
                                        )
                                    }
                                />
                                <div
                                    className="md:hidden bg-white pr-[5px] cursor-pointer"
                                    onClick={() =>
                                        handleRegistrationPDFDownload(
                                            email,
                                            paymentProviderId,
                                            clientData?.clientEmail,
                                        )
                                    }>
                                    <img src={`${imageBaseUrl}${Download}`} alt="Download" />
                                </div>
                            </div>
                        </div>
                        <Form form={form}>
                            <div className="flex gap-[10px] flex-col mt-[10px] lg:flex-row md:px-8 lg:pt-2 lg:p-0">
                                <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                                    <h1 className="text-[#51525C]">Business Email:</h1>
                                    {isEditBussiness ? (
                                        <Form.Item name="clientEmail">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                            <p className="text-[#8b8b9b] font-normal mailto:font-inter min-h-[20px]">
                                                {clientData.clientEmail}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex gap-[10px] flex-col lg:w-1/2 lg:p-0">
                                    <h1 className="text-[#51525C]">Full Legal Entity Name:</h1>
                                    {isEditBussiness ? (
                                        <Form.Item name="fullLegalEntityName">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                            <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                                {clientData.fullLegalEntityName}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-[10px] flex-col mt-[5px] lg:flex-row md:px-8 md:pt-2 lg:p-0">
                                <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                                    <h1 className="text-[#51525C]">
                                        Business Representatives Name:
                                    </h1>
                                    {isEditBussiness ? (
                                        <Form.Item name="businessRepresentativeName">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                            <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                                {clientData.businessRepresentativeName}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex gap-[10px] flex-col lg:w-1/2">
                                    <h1 className="text-[#51525C]">Country of incorporation:</h1>
                                    {isEditBussiness ? (
                                        <Form.Item name="countryOfIncorporation">
                                            <Select>
                                                {countries.length > 0 ? (
                                                    countries.map((country, key) => (
                                                        <Option key={key} value={country.name}>
                                                            {country.name}
                                                        </Option>
                                                    ))
                                                ) : (
                                                    <Option value="">Loading...</Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5] h-[44px] text-[#8b8b9b] justify-between">
                                            <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                                {clientData.countryOfIncorporation}
                                            </p>
                                            <DownOutlined />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-[10px] flex-col lg:mt-[5px] mt-2 sm:px-[10px] md:px-8 md:pt-2 md:mt-0 lg:p-0">
                                <h1 className="text-[#51525C]">Business Website:</h1>
                                {isEditBussiness ? (
                                    <Form.Item name="businessWebsite">
                                        <Input />
                                    </Form.Item>
                                ) : (
                                    <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                        <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                            {clientData.businessWebsite}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Form>
                    </div>
                    <div className="p-4 md:p-0">
                        <div className="flex items-center justify-between xs:mb-[10px] md:px-8 md:pt-2 lg:pt-4 lg:p-0 lg:mb-[10px]">
                            <h1 className="text-lg font-semibold">Full Address</h1>
                            <Button
                                loading={isLoadingAddress}
                                onClick={isEditAddress ? handleSaveaddress : handleEditAddress}
                                icon={
                                    !isEditAddress && (
                                        <img
                                            src={`${imageBaseUrl}${Edit}`}
                                            alt=""
                                            className="w-5 h-5 "
                                        />
                                    )
                                }
                                className={`absolute right-0 h-8 !min-w-10 !max-w-fit ${isEditAddress ? 'bg-[#18181B] text-white' : 'bg-[#F4F4F5] text-black'}`}>
                                {isEditAddress ? 'Save' : ''}
                            </Button>
                        </div>
                        <Form form={form}>
                            <div className="flex gap-[10px] flex-col mt-[5px] lg:flex-row md:px-8 md:pt-2 lg:p-0">
                                <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                                    <h1 className="text-[#51525C]">Business address line*</h1>
                                    {isEditAddress ? (
                                        <Form.Item name="address">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                            <p className="text-[#8b8b9b] font-normal mailto:font-inter min-h-[20px]">
                                                {clientData.address}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex gap-[10px] flex-col lg:w-1/2 lg:p-0">
                                    <h1 className="text-[#51525C]">City*</h1>
                                    {isEditAddress ? (
                                        <Form.Item name="city">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                            <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                                {clientData.city}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-[10px] flex-col mt-[5px] lg:flex-row md:px-8 md:pt-2 lg:p-0">
                                <div className="flex gap-[10px] flex-col lg:w-1/2 md:h-[76px]">
                                    <h1 className="text-[#51525C]">State / Province / Region*</h1>
                                    {isEditAddress ? (
                                        <Form.Item name="state">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5]">
                                            <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                                {clientData.state}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex gap-[10px] flex-col lg:w-1/2">
                                    <h1 className="text-[#51525C]">ZIP / Postal Code*</h1>
                                    {isEditAddress ? (
                                        <Form.Item name="postCode">
                                            <Input />
                                        </Form.Item>
                                    ) : (
                                        <div className="p-[10px] pl-[14px] flex items-center border border-[#D1D1D6] rounded-lg bg-[#f4f4f5] h-[44px] text-[#8b8b9b] justify-between">
                                            <p className="text-[#8b8b9b] font-normal font-inter min-h-[20px]">
                                                {clientData.postCode}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <AccountPurpose
                        accountPurposeData={accountDetails}
                        email={email}
                        clientEmail={clientData?.clientEmail}
                        accountType={accountDetails?.accountType}
                    />
                    <TransactionActivity
                        transactionActivityData={accountDetails.transactionActivity}
                        email={email}
                        clientEmail={clientData?.clientEmail}
                        accountType={accountDetails?.accountType}
                    />
                </div>
            )}
            {activeTab === 'businessDocumentation' && (
                <div>
                    <DocumentsTab />
                </div>
            )}
        </div>
    );
}

export default BusinessTab;
