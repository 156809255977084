import { Modal, Button, message } from 'antd';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { getBalanceSign, getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import { getClientTransactionStatus } from '../../utils/constant';
import moment from 'moment';
import ThemeButton from '../common/ThemeButton';

const DownloadCsvModal = ({ open, handleClose, data, name }) => {
    const getTitleAndFilename = name => {
        switch (name) {
            case 'singleClient':
            case 'allClients':
                return 'Client Details';
            case 'ledgerBalance':
                return `Ledger Balance ${data[0]?.currency}`;
            default:
                return 'Transactions';
        }
    };
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: getTitleAndFilename(name) + ':',
        filename: getTitleAndFilename(name),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        headers: ['Column 1', 'Column 2', 'col 3', 'col 4', 'col 5'],
    };

    const generateCsvData = (name, data) => {
        let csvData = [];
        switch (name) {
            case 'clientTransactions':
                csvData = transformClientTransactionData(data);
                break;
            case 'singleClient':
                csvData = transformSingleClientsData(data);
                break;
            case 'allClients':
                csvData = transformAllClientsData(data);
                break;
            case 'ledgerBalance':
                csvData = transformLedgerBalanceData(data);
                break;
            default:
        }
        return csvData;
    };

    const transformClientTransactionData = data => {
        function getAmount(data) {
            if (data.type !== 'FX') {
                return `${getBalanceSign(data)} ${data.transactionDetails[0].currency && getCurrencySymbol(data.transactionDetails[0].currency)} ${toFixedTrunc(data.transactionDetails[0].amount)}`;
            } else {
                return `${data.transactionDetails && data.transactionDetails[0].fromCurrency} - ${toFixedTrunc(data.transactionDetails[0].fromAmount)} , ${data.transactionDetails && data.transactionDetails[0].toCurrency} + ${toFixedTrunc(data.transactionDetails[0].toAmount)}`;
            }
        }

        return data.map(tr => {
            return {
                'Client Name': tr?.clientRef?.name || 'N/A',
                Type: tr?.type === 'Transfer' ? `${tr?.type} (${tr?.transferInOut})` : tr?.type,
                'Transaction Id': tr.transactionId || 'N/A',
                'Transaction Fee': tr?.transactionFee
                    ? tr.type !== 'FX'
                        ? tr?.transactionFee
                        : `${tr?.transactionDetails && tr?.transactionDetails[0]?.fromCurrency} - ${tr?.transactionFee}`
                    : 0,
                BeneficiaryName: tr?.transactionDetails[0]?.beneficiaryName || 'N/A',
                BeneficiaryAddress: tr?.transactionDetails[0]?.beneficiaryAddress || 'N/A',
                BankName: tr?.transactionDetails[0]?.bankName || 'N/A',
                AccountNumber: tr?.transactionDetails[0]?.beneficiaryAccountNumber || 'N/A',
                BankAddress: tr?.transactionDetails[0]?.bankAddress || 'N/A',
                IBAN: tr?.transactionDetails[0]?.iban || 'N/A',
                AdditionalBankDetails: tr?.transactionDetails[0]?.additionalBankDetails || 'N/A',
                Swift: tr?.transactionDetails[0]?.swift || 'N/A',
                SortCode: tr?.transactionDetails[0]?.sortCode || 'N/A',
                Reference: tr?.transactionDetails[0]?.reference || 'N/A',
                Currency:
                    tr?.transactionDetails[0]?.currency ||
                    tr?.transactionDetails[0]?.cryptoId ||
                    'N/A',
                Amount: getAmount(tr),
                Status:
                    (tr?.status &&
                        tr?.status.code &&
                        getClientTransactionStatus(tr?.status.code).toUpperCase()) ||
                    'N/A',
                'Client Email': tr?.clientRef?.clientEmail || 'N/A',
                'Client Id': tr?.clientId || 'N/A',
                'Last Updated': tr?.status.updated || 'N/A',
                'Created Date': tr?.createdDate || 'N/A',
            };
        });
    };

    const transformSingleClientsData = obj => {
        const data = obj[0];
        return (
            data &&
            data?.clientBalances &&
            data?.clientBalances.map(tr => {
                return {
                    ClientId: data?.clientId || 'N/A',
                    'Client Name': data?.name || 'N/A',
                    'Account Number': data?.accountNumber || 'N/A',
                    Asset: tr?.currency || 'N/A',
                    Balance:
                        (tr?.balance &&
                            toFixedTrunc(
                                tr?.balance?.balanceAmount,
                                tr?.currencyType === 'crypto' ? 8 : 2,
                            )) ||
                        0,
                    'Last Updated':
                        tr?.balance !== null
                            ? moment(tr?.balance?.updated).format('DD-MM-YYYY')
                            : 'N/A',
                };
            })
        );
    };
    const transformAllClientsData = data => {
        return data[0].map(tr => {
            return {
                ClientId: tr?.clientId || 'N/A',
                'Account Number': tr?.accountNumber || 'N/A',
                'Account Name': tr?.name || 'N/A',
                'Account Email': tr?.clientEmail || 'N/A',
                'Account Address': tr?.address || 'N/A',
                'Created Date': moment(tr?.createdAt).format('DD-MM-YYYY') || 'N/A',
                Status: tr?.onboardingStatus || 'N/A,',
            };
        });
    };

    const transformLedgerBalanceData = data => {
        return data.map(tr => {
            return {
                'Created Date': moment(tr?.creationDate).format('DD-MM-YYYY') || 'N/A',
                Type: tr?.type || 'N/A',
                Status:
                    (tr.transactionStatus &&
                        tr.transactionStatus &&
                        getClientTransactionStatus(tr?.transactionStatus).toUpperCase()) ||
                    'N/A',
                Amount: tr?.amount || 'N/A',
                'Balance Amount': tr?.balanceAmount || 'N/A',
                'Transaction Id': tr?.transactionId || 'N/A',
            };
        });
    };

    const csvExporter = new ExportToCsv(options);
    return (
        <Modal
            open={open}
            destroyOnClose
            width={'422px'}
            closable={false}
            centered
            className="csv-modal"
            footer={false}
            onCancel={handleClose}>
            <div className="text-center border-t-4 rounded-t-lg border-t-black">
                <h1 className="text-[#26272B] text-xl font-semibold pb-1 pt-12">Download CSV</h1>
                <p className=" text-[#3F3F46] font-normal text-base pb-6 px-6">
                    Are you sure you want to download CSV file with all the information?
                </p>
                <div className="flex justify-evenly items-center p-6 bg-[#F4F4F5] gap-3 rounded-b-lg ">
                    <Button
                        className="px-5 py-[11px] rounded-full bg-[#E4E4E7] w-full h-full text-[#3F3F46] text-base font-medium"
                        onClick={() => {
                            handleClose();
                        }}>
                        Cancel
                    </Button>
                    <ThemeButton
                        text="Download"
                        type="primary"
                        action={() => {
                            handleClose();
                            if (data && data.length > 0) {
                                const transactionList = generateCsvData(name, data);
                                if (transactionList && transactionList.length) {
                                    csvExporter.generateCsv(transactionList);
                                } else {
                                    message.error('No transactions have been made yet.');
                                }
                            }
                        }}
                        shape="round"
                        style="font-medium"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DownloadCsvModal;
