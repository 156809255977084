import Sidebar from '../components/layout/Sidebar';
import moment from 'moment';
import { toFixedTrunc } from '../utils/common';
import BalanceHistory from '../components/BalanceHistory';
import { getCurrencySymbol } from '../utils/common';
import { getStatusComponent } from '../utils/common';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';

const FiatBalanceHistory = () => {
    const columns = [
        {
            title: 'Creation Date',
            key: 'creationDate',
            render: row => <p>{moment(row?.creationDate).format('DD/MM/YYYY - HH:mm')}</p>,
            sorter: true,
        },
        {
            title: 'Transaction Type',
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: 'Status',
            render: row => (
                <div className="flex items-center gap-4">
                    <p>{getStatusComponent(row?.transactionStatus)}</p>
                    <p>
                        {' '}
                        {row?.housekeeping &&
                            (row.housekeeping.status === 'Unmatched' ? (
                                <WarningOutlined className="text-yellow-500" />
                            ) : (
                                <CheckOutlined className="text-green-500" />
                            ))}
                    </p>
                </div>
            ),
            key: 'status',
        },
        {
            title: 'Amount',
            key: 'amount',
            render: row => (
                <p>
                    {getCurrencySymbol(row?.currency)}{' '}
                    {toFixedTrunc(row?.amount, row?.currencyType === 'crypto' ? 8 : 2)}
                </p>
            ),
            sorter: true,
        },
        {
            title: 'Balance Amount',
            key: 'balanceAmount',
            render: row => (
                <p
                    data-e2e={toFixedTrunc(
                        row?.balanceAmount,
                        row?.currencyType === 'crypto' ? 8 : 2,
                    )}
                    data-e2e-currency={getCurrencySymbol(row?.currency)}>
                    {getCurrencySymbol(row?.currency)}{' '}
                    {toFixedTrunc(row?.balanceAmount, row?.currencyType === 'crypto' ? 8 : 2)}
                </p>
            ),
        },
        {
            title: 'Transaction ID',
            key: 'transactionId',
            render: row => (
                <a href={`/transactions/${row?.transactionId}`} target="_blank">
                    {row?.transactionId}
                </a>
            ),
        },
    ];

    const ExpandedRow = ({ record }) => {
        const expandedRowFields = [
            {
                label: 'Creation Date',
                value: moment(record?.creationDate).format('DD/MM/YYYY - HH:mm'),
            },
            { label: 'Transaction Type', value: record?.type || 'N/A' },
            {
                label: 'Status',
                value: (
                    <div className="flex items-center gap-1">
                        <p>{getStatusComponent(record?.transactionStatus)}</p>
                        <p>
                            {' '}
                            {record?.housekeeping &&
                                (record.housekeeping.status === 'Unmatched' ? (
                                    <WarningOutlined className="text-yellow-500" />
                                ) : (
                                    <CheckOutlined className="text-green-500" />
                                ))}
                        </p>
                    </div>
                ),
            },
            {
                label: 'Amount',
                value: `${getCurrencySymbol(record?.currency)} ${toFixedTrunc(record?.amount, 2)}`,
            },
            {
                label: 'Balance Amount',
                value: `${getCurrencySymbol(record?.currency)} ${toFixedTrunc(record?.balanceAmount, 2)}`,
            },
            {
                label: 'Transaction Id',
                value: (
                    <a
                        className="cursor-pointer"
                        href={`/transactions/${record?.transactionId}`}
                        target="_blank">
                        {record?.transactionId}
                    </a>
                ),
            },
        ];
        return (
            <div className="p-3 bg-[#FCFCFC] border rounded-lg">
                {expandedRowFields.map(({ label, value }) => (
                    <p
                        key={label}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-3">
                        <span>{label}:</span> {value}
                    </p>
                ))}
            </div>
        );
    };

    return (
        <>
            <Sidebar>
                <BalanceHistory columns={columns} ExpandedRow={ExpandedRow} />
            </Sidebar>
        </>
    );
};

export default FiatBalanceHistory;
