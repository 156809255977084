import React, { useEffect, useState } from 'react';
import { FormDOB, FormInput, FormPassword, FormSelect } from '../forms';
import { Form } from 'antd';
import { getCountries } from '../../utils/common';
import apiRequest from '../../utils/api';

const RegistrationPersonalDetailsForm = ({ form, isEdit, error }) => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [formValue, setFormValue] = useState();
    const email = localStorage.getItem('email');
    const [whiteLabelData, setWhiteLabelData] = useState([]);

    const handleChange = value => {
        form.setFieldsValue({ country: value });
        setSelectedCountry(value);
    };

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const handleSelectChange = (fieldName, value) => {
        setFormValue(prevState => {
            const updatedState = {
                ...prevState,
                [fieldName]: value,
            };

            form.setFieldsValue({ [fieldName]: value });
        });
    };
    const getWhiteLableList = async () => {
        const body = {
            email,
            filterParams: {
                limit: 100,
            },
        };

        try {
            const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);

            if (response?.success || response?.data) {
                const options = response.data?.whitelabelList?.map(
                    ({ whitelableName, whitelabelId }) => ({
                        label: whitelableName,
                        value: whitelabelId,
                    }),
                );
                setWhiteLabelData([{ label: 'None', value: 'none' }, ...options]);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getWhiteLableList();
    }, []);

    return (
        <div>
            <Form
                form={form}
                layout="horizontal"
                colon={false}
                className="h-[75vh] px-[7px] overflow-y-auto hide-scrollbar modal-form registeration-form">
                <Form.Item name="name">
                    <FormInput
                        label="Full Name:"
                        name="name"
                        placeholder="Enter Name"
                        dataE2e="enter-name"
                        formClassName="!mt-10 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Full name is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="clientEmail">
                    <FormInput
                        label="Email:"
                        name="clientEmail"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Client Email is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address!',
                            },
                        ]}
                        placeholder="Enter Email"
                        dataE2e="enter-email"
                        type="email"
                    />
                </Form.Item>

                <Form.Item name="phoneNumber">
                    <FormInput
                        label="Phone:"
                        name="phoneNumber"
                        placeholder="Enter Phone Number"
                        dataE2e="enter-phone-number"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Phone Number is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^\+?[1-9]\d{0,14}$/,
                                message: '* Please enter a valid phone number',
                            },
                        ]}
                    />
                </Form.Item>
                <FormDOB
                    label="Date of Birth:"
                    name="dob"
                    placeholder="dd/mm/yyy"
                    dataE2e="date-of-birth"
                    format="DD/MM/YYYY"
                    formClassName="errorFields"
                    rules={[
                        {
                            required: true,
                            message: 'Date of Birth is required',
                        },
                    ]}
                    onChange={value => {
                        const formattedDate = value ? value.format('YYYY-MM-DD') : null;

                        form.setFieldsValue({ dob: formattedDate });
                    }}
                    defaultValue={form.getFieldValue('dob')}
                />

                <FormSelect
                    label="Country of residence:"
                    name="country"
                    formClassName="!my-8 !mb-4 errorFields"
                    placeholder={
                        <span
                            data-e2e="Country-of-residence"
                            style={{ fontSize: '16px', color: '#bfbfbf' }}>
                            Select your country
                        </span>
                    }
                    options={[
                        { value: '', label: 'Select your country' },
                        ...countries.map((country, key) => ({
                            value: country.name,
                            label: country.name,
                            key: key,
                        })),
                    ]}
                    value={selectedCountry || form.getFieldValue('country')}
                    onChange={handleChange}
                    rules={[
                        {
                            required: true,
                            message: 'Country of residence is required',
                        },
                    ]}
                />
                {!isEdit && (
                    <Form.Item name="password">
                        <FormPassword
                            label="Password:"
                            name="password"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value || value.trim() === '') {
                                            return Promise.reject('* Please enter a Password');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                },
                            ]}
                            placeholder="Create Password"
                            dataE2e="create-password"
                            formClassName="!mt-4 !mb-0 errorFields"
                        />
                    </Form.Item>
                )}

                <Form.Item name="address">
                    <FormInput
                        label="Personal Address Line:"
                        name="address"
                        placeholder="Enter Personal Address"
                        dataE2e="enter-address"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(
                                            '* Personal Address Line is required',
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="city">
                    <FormInput
                        label="City:"
                        name="city"
                        placeholder="Enter City"
                        dataE2e="enter-city"
                        formClassName="!mt-4 !mb-0 w-max errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* City is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^[A-Za-z\s]*$/,
                                message: 'City must contain only Latin letters and spaces',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="state">
                    <FormInput
                        label="State:"
                        name="state"
                        placeholder="Enter State"
                        dataE2e="enter-state"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* State is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^[A-Za-z\s]*$/,
                                message: 'State must contain only Latin letters and spaces',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="postCode">
                    <FormInput
                        label="ZIP / Postal Code:"
                        name="postCode"
                        placeholder="Enter Post Code"
                        dataE2e="enter-post-code"
                        formClassName="!mt-4 !mb-0 errorFields"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject('* Postal Code is required');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                            {
                                pattern: /^[A-Za-z0-9\s-]+$/,
                                message: '* ZIP / Postal Code must contain only numbers',
                            },
                        ]}
                    />
                </Form.Item>
                {isEdit && (
                    <>
                        <FormSelect
                            label="Whitelabel:"
                            name="whiteLabelId"
                            placeholder="Select Whitelabel"
                            options={whiteLabelData?.map((item, key) => ({
                                value: item?.value,
                                label: item?.label,
                                key: key,
                            }))}
                            defaultValue={form?.getFieldValue('whiteLabelId')}
                            onChange={value => handleSelectChange('whiteLabelId', value)}
                        />
                        <FormSelect
                            label="Status:"
                            name="status"
                            placeholder="Select Status"
                            options={[
                                { value: true, label: 'Active' },
                                { value: false, label: 'Inactive' },
                            ]}
                            defaultValue={form?.getFieldValue('status')}
                            onChange={value => handleSelectChange('status', value)}
                        />
                    </>
                )}
            </Form>
        </div>
    );
};

export default RegistrationPersonalDetailsForm;
