import React, { Children } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { DeleteIcon } from '../../utils/Images';

const DeleteModal = ({
    open,
    title,
    content,
    handleClose,
    handleDelete,
    name,
    toDelete,
    toDeleteName,
    toDeleteEmail,
    loading,
}) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    return (
        <div>
            <Modal
                open={open}
                width={'422px'}
                height={'330px'}
                destroyOnClose
                centered
                footer={false}
                className="delete-modal"
                closable={false}>
                <div
                    className={`flex flex-col gap-4 items-center pt-12 border-t-4 rounded-t-md border-t-[#F04438] px-6 pb-6 `}>
                    <img src={`${imageBaseUrl}${DeleteIcon}`} alt="icon" />
                    <p className="font-semibold text-xl">{title}</p>
                    <p className="font-normal text-base items-center text-center self-stretch px-8">
                        {content}
                        <br className="lg:block hidden" />
                        <span className="font-semibold">{name ? name : ' '}</span>
                    </p>
                </div>
                {toDeleteName ? (
                    <div>
                        <hr />
                        <p className="font-semibold text-center text-xl pt-5">{toDeleteName}</p>
                        <p className="font-normal text-base items-center pb-5 text-center self-stretch">
                            {toDeleteEmail}
                        </p>
                    </div>
                ) : (
                    ''
                )}
                <hr />
                <div className="flex justify-center rounded-b-3xl bg-slate-100 p-6">
                    <Button
                        onClick={handleClose}
                        data-e2e="cancel-btn"
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl !text-[#3F3F46] font-semibold !bg-[#D1D1D6] mr-3 text-sm`}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDelete(toDelete)}
                        data-e2e="delete-btn"
                        loading={loading}
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl !text-white font-semibold !bg-[#F04438]  text-sm`}>
                        Delete
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default DeleteModal;
