import { combineReducers } from 'redux';
import configReducer from './configReducer';
import filtersReducer from './filtersReducer';
import dashboardFiltersReducer from './dashboardFiltersReducer';
import accountReducer from './accountReducer';
import notificationReducer from './notificationReducer';
import settingsReducer from './settingsReducer';

const rootReducer = combineReducers({
    config: configReducer,
    filters: filtersReducer,
    dashboardFilters: dashboardFiltersReducer,
    account: accountReducer,
    notification: notificationReducer,
    settings: settingsReducer,
});

export default rootReducer;
