export const TOGGLE_SIDEBAR_COLLAPSE = 'TOGGLE_SIDEBAR_COLLAPSE';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const SET_DASHBOARD_FILTERS = 'SET_DASHBOARD_FILTERS';
export const CLIENT_DATA = 'CLIENT_DATA';
export const SET_BALANCES_DATA = 'SET_BALANCES_DATA';
export const SET_ACCOUNTS_DATA = 'SET_ACCOUNTS_DATA';
export const SET_CLIENT_FEE_SETTING = 'SET_CLIENT_FEE_SETTING';
export const CLIENT_FIAT_BALANCE = 'CLIENT_FIAT_BALANCE';
export const SET_CRYPTO_BALANCES_DATA = 'SET_CRYPTO_BALANCES_DATA';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';

export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
