import React, { useState } from 'react';
import moment from 'moment';
import { toFixedTrunc } from '../utils/common';
import { cryptoOptions } from '../utils/constant';
import { formatAmount } from '../utils/formatAmount';
import { Tooltip } from 'antd';

const TransactionSummary = ({
    totalCount,
    totalUsd,
    totalFee,
    fromDate,
    toDate,
    isMobile,
    showMore,
    setShowMore,
    cryptoAmount,
}) => {
    const [showMoreCrypto, setShowMoreCrypto] = useState(false);
    const handleShow = () => {
        if (showMore === 'Show More') {
            setShowMore('Show Less');
        } else {
            setShowMore('Show More');
        }
    };

    return (
        <>
            {!isMobile ? (
                <>
                    <div className="flex w-full gap-1 mt-6">
                        <div className="h-14 w-[60%] p-4 bg-[#fcfcfc] rounded-lg border border-[#e3e3e7] flex-col justify-center items-start gap-3 inline-flex">
                            <div className="justify-start items-center gap-6 inline-flex">
                                <div className="justify-start items-center gap-2 flex">
                                    <div className="text-[#51515b] text-sm font-normal leading-tight">
                                        Number of Transactions:
                                    </div>
                                    <div className="text-[#17171b] text-base font-medium leading-normal">
                                        {totalCount}
                                    </div>
                                </div>
                                <div className="w-px h-6 relative bg-[#e3e3e7]" />
                                <div className="justify-start items-center gap-2 flex">
                                    <div className="text-[#51515b] text-sm font-normal leading-tight">
                                        Net USD Equivalent:
                                    </div>
                                    <Tooltip title={`$ ${toFixedTrunc(totalUsd || 0)}`}>
                                        <div className="text-[#17171b] text-base font-medium leading-normal">
                                            {formatAmount(totalUsd) || '-'}
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className="justify-start items-center gap-2 flex">
                                    <div className="text-[#51515b] text-sm font-normal leading-tight">
                                        Total Fees:
                                    </div>
                                    <Tooltip title={`$ ${toFixedTrunc(totalFee || 0)}`}>
                                        <div className="text-[#17171b] text-base font-medium leading-normal">
                                            {formatAmount(totalFee) || '-'}
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="h-14 w-[40%] p-4 bg-[#fcfcfc] rounded-lg border border-[#e3e3e7] justify-start items-center gap-3 inline-flex">
                            <div className="justify-start items-center gap-6 flex">
                                <div className="justify-start items-center gap-2 flex">
                                    <div className="text-[#51515b] text-sm font-normal leading-tight">
                                        Date Range From:
                                    </div>
                                    <div className="text-[#17171b] text-base font-medium leading-normal">
                                        {fromDate !== 'all'
                                            ? moment(fromDate).format('DD.MM.YYYY')
                                            : '-'}
                                    </div>
                                </div>
                                <div className="w-px h-6 relative bg-[#e3e3e7]" />
                                <div className="justify-start items-center gap-2 flex">
                                    <div className="text-[#51515b] text-sm font-normal leading-tight">
                                        Date Range To:
                                    </div>
                                    <div className="text-[#17171b] text-base font-medium leading-normal">
                                        {toDate ? moment(toDate).format('DD.MM.YYYY') : '-'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-1 rounded-lg border border-[#e3e3e7] flex-col justify-center items-start inline-flex">
                        <div className="py-4 px-4 rounded-lg bg-[#fcfcfc] w-full justify-between items-center gap-2 flex">
                            <div className="text-[#51515b] text-sm font-normal leading-tight">
                                Sum by Crypto Amount:
                            </div>
                            <div
                                onClick={() => setShowMoreCrypto(!showMoreCrypto)}
                                className="text-[#156fee] text-sm font-medium cursor-pointer hover:underline leading-none">
                                Show {showMoreCrypto ? 'less' : 'more'} information
                            </div>
                        </div>

                        {showMoreCrypto && (
                            <div className="flex px-4 border-t-[1px] bg-white w-full rounded-lg">
                                {cryptoOptions.map(crypto => (
                                    <div className="py-2 flex-1 flex justify-center border-r border-[#e3e3e7] last:border-r-0">
                                        <div>
                                            <p className="text-[#51525C] text-sm font-inter font-normal ">
                                                {crypto.label}:
                                            </p>
                                            <p className="text-primary mt-1 font-medium text-base">
                                                {(cryptoAmount &&
                                                    toFixedTrunc(cryptoAmount[crypto.value], 6)) ||
                                                    0}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="p-3 mx-3 mb-3 bg-white border-[1px] rounded-lg mt-2">
                    <div className="flex justify-between items-center w-full">
                        <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                            Number of Transactions:
                        </p>
                        <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                            {totalCount}
                        </p>
                    </div>
                    <hr className="my-3" />
                    <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                        Net USD Equivalent:
                    </p>
                    <Tooltip title={`$ ${toFixedTrunc(totalUsd || 0)}`}>
                        <p className="h-[18px] w-fit text-[#17171b] text-[13px] mt-2 font-medium leading-[18px]">
                            {formatAmount(totalUsd)}
                        </p>
                    </Tooltip>
                    <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px] mt-3">
                        Total Fees:
                    </p>
                    <Tooltip title={`$ ${toFixedTrunc(totalFee || 0)}`}>
                        <p className="h-[18px] w-fit text-[#17171b] text-[13px] mt-2 font-medium leading-[18px]">
                            {formatAmount(totalFee)}
                        </p>
                    </Tooltip>

                    {showMore === 'Show Less' && (
                        <>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center w-full my-3">
                                <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                                    Date Range From:
                                </p>
                                <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                                    {fromDate ? moment(fromDate).format('DD.MM.YYYY') : '-'}
                                </p>
                            </div>
                            <div className="flex justify-between items-center w-full my-3">
                                <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                                    Date Range To:
                                </p>
                                <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                                    {toDate ? moment(toDate).format('DD.MM.YYYY') : '-'}
                                </p>
                            </div>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center w-full my-3">
                                <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                                    Sum by Crypto amount:
                                </p>
                            </div>
                            {cryptoOptions.map(crypto => (
                                <div className="flex justify-between items-center w-full my-3">
                                    <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                                        {crypto.label}:
                                    </p>
                                    <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                                        {toFixedTrunc(
                                            cryptoAmount && cryptoAmount[crypto.value],
                                            6,
                                        ) || 0}
                                    </p>
                                </div>
                            ))}
                        </>
                    )}

                    <hr className="my-3" />
                    <div
                        className="text-[#156fee] text-sm font-medium cursor-pointer hover:underline leading-none"
                        onClick={handleShow}>
                        {showMore}
                    </div>
                </div>
            )}
        </>
    );
};

export default TransactionSummary;
