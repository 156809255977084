import { Button, Checkbox, Input, Modal, Progress, message, Divider } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import { LoadingSkeleton } from '../common/Loading';
import { getPresignedUrl } from '../../utils/common';
import ThemeButton from '../common/ThemeButton';

const MerchantAccountModal = ({
    merchantData,
    merchantModal,
    setMerchantModal,
    onSave,
    onReset,
}) => {
    const clientData = useSelector(state => state.account.clientData);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [step, setStep] = useState(merchantData ? 2 : 1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState({
        merchantName: '',
        transferInFees: '0',
        clientIds: [],
    });
    const [searchText, setSearchText] = useState('');
    const [allClients, setAllClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newMerchantLoader, setNewMerchantLoader] = useState(false);
    const email = localStorage.getItem('email');
    const [inputValue, setInputValue] = useState(`${data.transferInFees || ''}%`);
    const inputRef = useRef(null);

    const handleFileChange = info => {
        setSelectedFile(info.file);
        message.success(`${info.file.name} file selected successfully.`);
    };

    const handlePercentChange = e => {
        const { value } = e.target;
        const sanitizedValue = value.replace(/[^0-9.]/g, '');
        setData(prevState => ({
            ...prevState,
            transferInFees: sanitizedValue,
        }));
        setInputValue(sanitizedValue);
    };

    const handleFocus = () => {
        const currentValue = data.transferInFees;
        setInputValue(currentValue);
    };

    const handleBlur = () => {
        const currentValue = data.transferInFees;
        setInputValue(currentValue + '%');
    };

    const getClients = async () => {
        setLoading(true);
        let filterParams = {
            searchText,
            page: 1,
            sortBy: 'createdAt',
            orderBy: 'desc',
            limit: 200,
        };

        let payload = {
            email: email,
            filterParams: filterParams,
        };

        try {
            const res = await apiRequest('/get-clients', 'POST', payload);
            if (res.success) {
                const filteredClients = res.data.clientsData
                    .filter(item => item.clientId !== clientData.clientId)
                    .filter(item => {
                        return !merchantData?.linkedClientAccounts.some(
                            selectedItem => selectedItem._id === item._id,
                        );
                    });

                setAllClients(prevClients =>
                    searchText ? filteredClients : [...prevClients, ...filteredClients],
                );
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err.message, 'Fetch Error');
        }
    };

    useEffect(() => {
        if (open) {
            const getData = setTimeout(() => {
                getClients();
            }, 500);
            return () => {
                clearTimeout(getData);
                setData(pre => ({ ...pre, clientIds: [] }));
            };
        }
    }, [searchText, merchantModal]);

    const handleChange = event => {
        const userId = event.target.name;
        setData(prev => {
            if (event.target.checked) {
                return { ...prev, clientIds: [...prev.clientIds, userId] };
            } else {
                return { ...prev, clientIds: [...prev.clientIds.filter(id => id !== userId)] };
            }
        });
    };

    const linkClient = async () => {
        const payload = {
            clientObjId: data.clientIds,
            clientId: clientData.clientId,
            merchantId: merchantData._id,
            email,
        };
        try {
            const res = await apiRequest('/link-merchant', 'POST', payload);
            if (res.success) {
                onSave();
            } else {
                message.error(res.error);
            }
        } catch (err) {
            console.log(err.message, 'Fetch Error');
        }
    };

    const createMerchantAccount = async () => {
        setNewMerchantLoader(true);
        let imgUrl;

        if (selectedFile) {
            const payload = {
                imageType: 'junoClientMerchantLogo',
                merchantName: data.merchantName,
                email: email,
            };
            const imageUrl = `${imageBaseUrl}/juno-client-merchant-logos/${data.merchantName}/logo`;
            imgUrl = await getPresignedUrl(payload, selectedFile, imageUrl);
        }
        const payload = {
            merchantName: data.merchantName,
            transferInFees: data.transferInFees,
            merchantLogo: imgUrl,
            linkedClientAccounts: data.clientIds,
            clientObjId: clientData._id,
            clientId: clientData.clientId,
            email: email,
        };
        try {
            const res = await apiRequest('/create-merchant', 'POST', payload);
            if (res.success) {
                onSave();
            }
        } catch (err) {
            console.log(err.message, 'Fetch Error');
        } finally {
            setNewMerchantLoader(false);
        }
    };
    return (
        <Modal
            title={
                <p className="text-2xl font-semibold">
                    {merchantData ? 'Link Account' : 'Setup Merchant Account'}
                </p>
            }
            open={merchantModal}
            className={'common-mobile-view'}
            centered
            onCancel={() => setMerchantModal(false)}
            footer={
                <div className="flex justify-end w-full ">
                    <div className="flex-1 flex ">
                        {step === 2 && merchantData?.linkedClientAccounts.length > 0 && (
                            <ThemeButton
                                style="sm:!w-[140px] !w-[100px] "
                                shape="round"
                                text="Reset"
                                key="reset"
                                action={onReset}
                            />
                        )}
                    </div>

                    <div className="flex">
                        <ThemeButton
                            key="cancel"
                            text="Cancel"
                            action={() => setMerchantModal(false)}
                            style="mr-3 sm:!w-[140px] !w-[100px]"
                            autoWidth={true}
                            shape="round"
                        />
                        <div className="sm:w-[140px] w-[100px]">
                            <ThemeButton
                                style="sm:!w-[140px] !w-[100px]"
                                key="submit"
                                type="primary"
                                shape="round"
                                autoWidth={true}
                                loading={step === 2 && newMerchantLoader}
                                disabled={
                                    step === 1 && (!data.merchantName || !data.transferInFees)
                                }
                                action={() => {
                                    if (step === 1) {
                                        setStep(2);
                                    } else {
                                        if (merchantData) {
                                            linkClient();
                                        } else createMerchantAccount();
                                    }
                                }}
                                text={step === 1 ? 'Continue' : 'Save'}
                            />
                        </div>
                    </div>
                </div>
            }>
            <div className="h-[70vh] md:h-auto overflow-auto hide-scrollbar">
                <p className="text-base text-[#51525C]">
                    Select or search one of multiple accounts you would like to link to this
                    merchant.
                </p>
                {!merchantData && (
                    <>
                        <Progress
                            strokeColor={'black'}
                            showInfo={false}
                            percent={step === 1 ? 50 : 100}
                            className="mt-6"
                        />
                        <div className="flex mt-2">
                            <p className="w-1/2 text-sm text-center text-primary">
                                Merchant Details
                            </p>
                            <p className="w-1/2 text-sm text-center ">Account</p>
                        </div>
                    </>
                )}

                {step === 1 ? (
                    <div className="my-6 mt-6 max-h-[350px] overflow-auto hide-scrollbar">
                        <div className="merchant-account-form__input-container h-[54px]">
                            <p className="merchant-account-form__label">Merchant name:</p>
                            <Input
                                placeholder="Create merchant name..."
                                className="merchant-account-form__input"
                                value={data.merchantName}
                                onChange={e =>
                                    setData({
                                        ...data,
                                        merchantName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="merchant-account-form__input-container mt-[30px]">
                            <p className="merchant-account-form__label">Merchant transfer fee:</p>
                            <Input
                                ref={inputRef}
                                name="transferInFees"
                                placeholder="Transfer fee"
                                className="merchant-account-form__input"
                                value={inputValue}
                                onChange={handlePercentChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </div>

                        <Divider className="mt-[30px]" />

                        <h2 className="text-[#51525C] text-[16px] font-normal mb-3 mt-[30px]">
                            Upload a logo
                        </h2>
                        <Dragger
                            className="merchant-dragger"
                            showUploadList={false}
                            maxCount={1}
                            name="file"
                            multiple={false}
                            beforeUpload={() => false}
                            onChange={handleFileChange}>
                            {selectedFile ? (
                                <div className="text-center">
                                    <img
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="Preview"
                                        className="img-fluid mx-auto mb-3 max-h-[200px]"
                                    />
                                    <p>{selectedFile.name}</p>
                                </div>
                            ) : (
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        {/* <InboxOutlined /> */}
                                    </p>
                                    <p className="ant-upload-text">
                                        <span className="text-primary font-semibold">
                                            Click to upload
                                        </span>{' '}
                                        or drag and drop
                                    </p>
                                    <p className="ant-upload-hint">PNG or JPG (max. 800x400px)</p>
                                </div>
                            )}
                        </Dragger>
                    </div>
                ) : (
                    <div>
                        <Input
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            placeholder="Search Account ID, Account name"
                            suffix={<SearchOutlined />}
                            className=" p-2 my-6"
                        />
                        {loading ? (
                            <div className="border border-[#D1D1D6] rounded-md h-[300px] ">
                                <LoadingSkeleton />
                            </div>
                        ) : (
                            <>
                                <div className="flex mb-6 gap-2 flex-wrap">
                                    {allClients
                                        .filter(user => data.clientIds.includes(user._id))
                                        .map(u => (
                                            <div
                                                key={u._id}
                                                className="p-[3px] flex gap-1 bg-[#F4F4F5] rounded-[4px] cursor-pointer">
                                                <span>{u.name}</span>
                                                <CloseOutlined
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        setData(prev => {
                                                            return {
                                                                ...prev,
                                                                clientIds: [
                                                                    ...prev.clientIds.filter(
                                                                        id => id !== u._id,
                                                                    ),
                                                                ],
                                                            };
                                                        })
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div className="border border-[#D1D1D6] rounded-md h-[300px] overflow-scroll hide-scrollbar">
                                    {allClients.length > 0 ? (
                                        allClients.map(user => (
                                            <div
                                                key={user._id}
                                                className="flex justify-between p-2.5 pr-6 ">
                                                <p className="text-sm text-primary">
                                                    {user.name}
                                                    <span className="text-[#70707B]">
                                                        ({user.clientId})
                                                    </span>
                                                </p>
                                                <Checkbox
                                                    checked={data?.clientIds.includes(user._id)}
                                                    name={user._id}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <div className=" p-2.5 pr-6">
                                            <p className="theme-table-empty">No data found!</p>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default MerchantAccountModal;
