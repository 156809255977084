import React, { useState, useEffect } from 'react';
import CommonModal from '../modals/CommonModal';
import { ArrowLeftOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import RegistrationBusinessDetailsForm from './RegistrationBusinessDetailsForm';
import BusinessDocumentation from './BusinessDocumentation';
import AccountPurposeForm from './AccountPurposeForm';
import TransactionActivityForm from './TransactionActivityForm';
import { Form } from 'antd';
import { useCommonFunctions } from '../../utils/common';
import ThemeButton from '../common/ThemeButton';
import { documentsFeilds } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { getPresignedUrl } from '../../utils/common';

const RegistrationBusinessDetails = ({
    open,
    handleCloseAddAccountModal,
    handleOpenAddAccountModal,
    getClients,
}) => {
    const [form] = Form.useForm();
    const [accountPurposeForm] = Form.useForm();
    const [transactionActivityForm] = Form.useForm();

    const adminRole = localStorage.getItem('adminRole');
    const email = localStorage.getItem('email');
    const [clientEmail, setClientEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [headerContent, setHeaderContent] = useState('');
    const [attachmentsError, setAttachmentsError] = useState('');
    const [attachments, setAttachments] = useState([]);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [error, setError] = useState('');

    const { handleRegistrationStages } = useCommonFunctions();

    useEffect(() => {
        switch (step) {
            case 1:
                setHeaderContent('Business Details');
                break;
            case 2:
                setHeaderContent('Business Documentation');
                break;
            case 3:
                setHeaderContent('Account Purpose');
                break;
            case 4:
                setHeaderContent('Transaction Activity');
                break;
        }
    }, [step]);

    const handleBack = () => {
        if (step === 1) {
            handleOpenAddAccountModal();
            handleCloseAccountModal();
        } else {
            setStep(step - 1);
        }
        setAttachmentsError('');
    };

    const handleCloseAccountModal = async () => {
        handleCloseAddAccountModal();
        form.resetFields();
        accountPurposeForm.resetFields();
        transactionActivityForm.resetFields();
        setAttachmentsError('');
        setAttachments([]);
        setStep(1);
        if (step !== 1) {
            await getClients();
        }
    };

    const addClientAccount = async () => {
        try {
            const formValues = await form.validateFields();

            const body = {
                email: email,
                adminRole: adminRole,
                ...formValues,
                accountType: 'Business',
            };

            setLoading(true);
            try {
                const response = await apiRequest('/create-client', 'POST', body);

                if (response.success || response.data) {
                    setClientEmail(form.getFieldValue('clientEmail'));
                    form.resetFields();
                    setStep(step + 1);
                    setError('');
                } else {
                    setError(response.error);
                }
                setLoading(false);
            } catch (error) {
                setError('Something went wrong');
                setLoading(false);
            }
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };
    const submitAccountPurpose = async () => {
        try {
            const formValues = await accountPurposeForm.validateFields();

            setLoading(true);
            try {
                const response = await handleRegistrationStages('account-purpose', {
                    ...formValues,
                    email: clientEmail,
                });
                if (response.success) {
                    setStep(step + 1);
                }
            } catch (error) {
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Validation failed:', error);
        }
    };

    const submitTransactionActivity = async () => {
        try {
            const formValues = await transactionActivityForm.validateFields();
            setLoading(true);
            try {
                const payload = {
                    transactionActivity: {
                        fx: {
                            numberPerMonth: formValues.fxTransactions,
                            averageUsdValue: formValues.fxUsdValue.replace(/\$/g, '').trim(),
                            mainFxPairs: formValues.fxPairs,
                        },
                        inbound: {
                            averageUsdValue: formValues.inboundUsdValue.replace(/\$/g, '').trim(),
                            estimatedNumberPerMonth: formValues.inboundTransactions,
                            jurisdictionsReceivingFundsFrom:
                                formValues.inboundJurisdictions.join(', '),
                            mainCurrencies: formValues.inboundCurrencies,
                            topEntitiesSendingFunds: formValues.inboundEntities,
                        },
                        outbound: {
                            averageUsdValue: formValues.outboundUsdValue.replace(/\$/g, '').trim(),
                            estimatedNumberPerMonth: formValues.outboundTransactions,
                            jurisdictionsSendingFundsTo:
                                formValues.outboundJurisdictions.join(', '),
                            mainCurrencies: formValues.outboundCurrencies,
                            topBeneficiariesReceivingFunds: formValues.outboundEntities,
                        },
                    },
                };
                const response = await handleRegistrationStages('transaction-activity', {
                    ...payload,
                    email: clientEmail,
                });
                if (response.success) {
                    handleCloseAccountModal();
                    await updateClientData();
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Validation failed:', error);
        }
    };

    const updateClientData = async () => {
        try {
            const body = {
                clientEmail,
                email: localStorage.getItem('email'),
                onboardingStatus: 'AwaitingApproval',
            };

            await apiRequest('/update-single-client-data', 'POST', body);
        } catch (error) {
            console.error(error);
        }
    };

    const submitDocuments = async () => {
        // const missingFiles = documentsFeilds.filter(item => {
        //     const attachment = attachments.find(att => att.label === item.label);
        //     return !attachment || attachment.files.length === 0;
        // });

        // if (missingFiles.length > 0) {
        //     setAttachmentsError('Please upload all required documents.');
        //     return;
        // }

        setLoading(true);
        const tempAttachmentUrls = {};

        try {
            // Map each attachment to a Promise that fetches signed URLs for its files in parallel
            const uploadPromises = attachments.map(async attachment => {
                const documentField = documentsFeilds.find(
                    field => field.label === attachment.label,
                );
                if (!documentField) {
                    console.warn(`No mapping found for label: ${attachment.label}`);
                    return;
                }

                const backendKey = documentField.key;

                // Fetch signed URLs in parallel for all files in this attachment
                const signedUrlPromises = attachment.files.map((file, index) =>
                    getPresignedUrl(
                        {
                            email: localStorage.getItem('email'),
                            imgcount: `file${index}`,
                            imageType: 'userDocuments',
                            fileName: file.name,
                        },
                        file,
                        `${imageBaseUrl}/user-documents/file${index}/${file.name}/documents`,
                    ),
                );

                const signedUrls = await Promise.all(signedUrlPromises);

                tempAttachmentUrls[backendKey] = signedUrls.map((signedUrl, index) => ({
                    name: attachment.files[index].name,
                    attachment: signedUrl,
                }));
            });

            // Execute all uploads in parallel
            await Promise.all(uploadPromises);

            // Submit the documents data after all uploads are complete
            const response = await handleRegistrationStages('upload-documents', {
                documents: tempAttachmentUrls,
                email: clientEmail,
            });
            if (response?.success) {
                setStep(step + 1);
            }
        } catch (error) {
            console.error('Error:', error);
            setAttachmentsError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFinish = () => {
        if (step === 1) {
            addClientAccount();
        } else if (step === 2) {
            submitDocuments();
        } else if (step === 3) {
            submitAccountPurpose();
        } else if (step === 4) {
            submitTransactionActivity();
        }
    };

    return (
        <>
            <CommonModal
                maskClosable={false} // Prevents closing on outside click
                title={
                    <>
                        <div className="flex items-center">
                            {step === 2 ? (
                                ''
                            ) : (
                                <ArrowLeftOutlined
                                    className="text-xl mr-[12px] w-6 h-6"
                                    onClick={handleBack}
                                />
                            )}
                            <p className="text-2xl font-semibold relative flex">{headerContent}</p>
                        </div>
                        {(attachmentsError || error) && (
                            <p className="text-red-500 mt-3 text-base font-normal ml-4">
                                {attachmentsError || error}
                            </p>
                        )}
                    </>
                }
                open={open}
                handleClose={handleCloseAccountModal}
                onFinish={handleFinish}
                className={'common-mobile-view account-model-view'}
                loading={loading}
                footerText="Continue"
                footerContent={
                    step !== 1 && (
                        <ThemeButton
                            style="mr-2"
                            action={() => {
                                step === 4 ? handleCloseAccountModal() : setStep(step + 1);
                                setAttachmentsError('');
                            }}
                            text="Skip"
                            shape="round"
                            size="large"
                        />
                    )
                }>
                {step === 1 && <RegistrationBusinessDetailsForm form={form} />}
                {step === 2 && (
                    <BusinessDocumentation
                        attachmentsError={attachmentsError}
                        attachments={attachments}
                        setAttachments={setAttachments}
                        setAttachmentsError={setAttachmentsError}
                    />
                )}
                {step === 3 && <AccountPurposeForm form={accountPurposeForm} />}
                {step === 4 && <TransactionActivityForm form={transactionActivityForm} />}
            </CommonModal>
        </>
    );
};

export default RegistrationBusinessDetails;
