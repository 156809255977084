import React, { useEffect, useState } from 'react';
import { Switch, message, Divider, Dropdown, Menu, Select, Form } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import ThemeButton from '../common/ThemeButton';
import {
    RedoOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import ConfirmationModal from '../modals/ConfirmationModal';
import { statusConfig } from '../../utils/constant';
import DeleteModal from '../modals/DeleteModal';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { useCommonFunctions } from '../../utils/common';

const SettingsTabs = () => {
    const clientData = useSelector(state => state.account.clientData);
    const [cryptoTab, setCryptoTab] = useState(
        clientData ? clientData.cryptoTab === 'Enable' : false,
    );
    const [fiatTab, setFiatTab] = useState(clientData ? clientData.fiatTab === 'Enable' : true);
    const [cryptoLoading, setCryptoLoading] = useState(false);
    const [fiatLoading, setFiatLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verificationLoader, setVerificationLoader] = useState(false);
    const [rejectLoader, setRejectLoader] = useState(false);
    const email = localStorage.getItem('email');
    const { id } = useParams();
    const [confirmationModal, setConfirmationModal] = useState('');
    const navigate = useNavigate();
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [accountAccess, setAccountAccess] = useState(clientData?.status === 'Inactive');
    const [userAccessLoading, setUserAccessLoading] = useState(false);
    const [selectedWhiteLabel, setSelectedWhiteLabel] = useState(clientData?.whiteLabelId);
    const [whiteLabelLoading, setWhiteLabelLoading] = useState(false);
    const { getClientsDetails } = useCommonFunctions();

    useEffect(() => {
        setCryptoTab(clientData.cryptoTab === 'Enable');
        setFiatTab(clientData.fiatTab === 'Enable');
        setSelectedWhiteLabel(clientData?.whiteLabelId);
        setAccountAccess(clientData?.status === 'Inactive');
    }, [clientData]);

    const updateClientSetting = async (updatedField, setLoading, revertSwitch) => {
        const body = {
            email: email,
            clientId: id,
            ...updatedField,
        };
        setLoading(true);
        try {
            const response = await apiRequest('/update-client-settings', 'POST', body);

            if (response.success) {
                message.success('Settings Updated');
                getClientsDetails(id);
            } else {
                revertSwitch();
                message.error(response.error);
            }
            setLoading(false);
        } catch (error) {
            revertSwitch();
            setLoading(false);
            console.error(error);
        }
    };

    const handleCryptoSwitchChange = checked => {
        if (!checked && !fiatTab) {
            message.error('You cannot disable both Crypto and Fiat.');
            return;
        }
        setCryptoTab(checked);
        const revertSwitch = () => setCryptoTab(!checked);
        updateClientSetting(
            { cryptoTab: checked ? 'Enable' : 'Disable' },
            setCryptoLoading,
            revertSwitch,
        );
    };
    const handleAccessSwitchChange = async checked => {
        setAccountAccess(checked);
        try {
            const body = {
                clientEmail: clientData.clientEmail,
                email: email,
                status: checked ? 'Inactive' : 'Active',
            };
            setUserAccessLoading(true);
            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response.success) {
                setAccountAccess(response?.data?.updateData?.status === 'Inactive');
                getClientsDetails(id);
                message.success('User Access status updated');
            }
            setUserAccessLoading(false);
        } catch (error) {
            console.error('Error updating status:', error);
            setUserAccessLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleFiatSwitchChange = checked => {
        if (!checked && !cryptoTab) {
            message.error('You cannot disable both Crypto and Fiat.');
            return;
        }
        setFiatTab(checked);
        const revertSwitch = () => setFiatTab(!checked);
        updateClientSetting(
            { fiatTab: checked ? 'Enable' : 'Disable' },
            setFiatLoading,
            revertSwitch,
        );
    };

    const reset2FA = async checked => {
        if (typeof checked !== 'boolean') {
            setLoading(true);
        }

        const body = {
            email: email,
            clientEmail: clientData.clientEmail,
        };
        if (typeof checked === 'boolean') {
            body.enabled = checked;
        }

        const response = await apiRequest('/client-reset-2fa-token', 'POST', body);

        if (response.success) {
            message.success(response.data.msg);
            getClientsDetails(id);
        } else {
            message.error(response.error);
        }
        setLoading(false);
    };

    const statusClasses = {
        completed: 'bg-status-completed-bg text-status-completed-text',
        failed: 'bg-status-failed-bg text-status-failed-text',
        pending: 'bg-status-pending-bg text-status-pending-text',
    };

    const handleVerifyAccount = async () => {
        setVerificationLoader(true);
        const body = {
            email: email,
            clientEmail: clientData.clientEmail,
        };

        const response = await apiRequest('/update-verification-status', 'POST', body);

        if (response.success) {
            message.success('Verification status updated');
            getClientsDetails(id);
            setVerificationLoader(false);
        } else {
            setVerificationLoader(false);
            message.error(response.error);
        }
        setLoading(false);
        setConfirmationModal('');
    };

    const handleRejectAccount = async () => {
        setRejectLoader(true);
        try {
            const body = {
                clientEmail: clientData.clientEmail,
                email: email,
                onboardingStatus: 'Rejected',
                'userRegistrationState.finalVerification': true,
            };

            const response = await apiRequest('/update-single-client-data', 'POST', body);

            if (response.success) {
                message.success('Verification status updated');
                getClientsDetails(id);
            } else {
                console.error('Failed to update status:', response.message);
                message.error(response.message);
            }
            setRejectLoader(false);
        } catch (error) {
            setRejectLoader(false);

            console.error('Error updating status:', error);
            message.error('Something went wrong!');
        }
        setConfirmationModal('');
    };

    const updateStatus = {
        Reject: {
            title: 'Confirm Client Rejection',
            description:
                'This account has not completed onboarding, Please confirm you would still like to reject this client account',
            handleConfirm: handleRejectAccount,
        },
        Approve: {
            title: 'Confirm Client Approval',
            description:
                'This account has not completed onboarding, Please confirm you would still like to approve',
            handleConfirm: handleVerifyAccount,
        },
    };
    const updateKycStatus = async status => {
        const body = {
            clientEmail: clientData.clientEmail,
            email: email,
            'kyc.status': status,
        };
        try {
            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response.success) {
                getClientsDetails(id);
                message.success('KYC Status updated successfully');
            } else {
                message.error('Failed to update KYC Status');
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    };
    const menu = (
        <Menu onClick={({ key }) => updateKycStatus(key)}>
            <Menu.Item key="pending">Pending</Menu.Item>
            <Menu.Item key="completed">Completed</Menu.Item>
            <Menu.Item key="failed">Rejected</Menu.Item>
        </Menu>
    );

    const handleDeleteAccount = async () => {
        try {
            setLoading(true);
            const body = {
                email: email,
                clientEmail: clientData.clientEmail,
                client: clientData.clientId,
            };
            const response = await apiRequest('/delete-client-account', 'POST', body);
            if (response.success) {
                navigate('/accounts');
                message.success('Account deleted successfully');
            } else {
                message.error(response?.error);
            }
            setLoading(false);
            setConfirmationModal('');
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    const handleWhiteLabelChange = async value => {
        try {
            const body = {
                clientEmail: clientData.clientEmail,
                email: email,
                whiteLabelId: value,
            };
            setWhiteLabelLoading(true);
            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response.success) {
                setSelectedWhiteLabel(response?.data?.updateData?.whiteLabelId);
                getClientsDetails(id);
                message.success('white label updated');
            }
            setWhiteLabelLoading(false);
        } catch (error) {
            console.error('Error updating status:', error);
            setWhiteLabelLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleAccountTypeChange = async value => {
        try {
            const body = {
                clientEmail: clientData.clientEmail,
                email: email,
                accountType: value,
            };

            const response = await apiRequest('/update-single-client-data', 'POST', body);
            if (response.success) {
                message.success('Account type updated');
                getClientsDetails(id);
            }
        } catch (error) {
            console.error('Error updating status:', error);

            message.error('Something went wrong!');
        }
    };

    const getWhiteLableList = async () => {
        const body = {
            email,
            filterParams: {
                limit: 100,
            },
        };

        try {
            const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);

            if (response?.success || response?.data) {
                const options = response.data?.whitelabelList?.map(
                    ({ whitelableName, whitelabelId }) => ({
                        label: whitelableName,
                        value: whitelabelId,
                    }),
                );
                setWhiteLabelData([{ label: 'None', value: 'none' }, ...options]);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getWhiteLableList();
    }, []);
    return (
        <div className="bg-white lg:pt-8 py-4 px-6 lg:px-0">
            <h1 className="font-semibold lg:text-lg text-base pb-5 lg:hidden">Settings</h1>
            <div className="flex justify-between items-start w-full pb-2">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Account Type:</span>
                </div>
                <Select
                    className="white-label-css text-[#51525C] w-44"
                    value={clientData?.accountType}
                    onChange={val => handleAccountTypeChange(val)}
                    options={[
                        { value: 'Personal', label: 'Personal' },
                        { value: 'Business', label: 'Business' },
                    ]}
                />
            </div>
            <Divider className="my-2" />
            <div className="pb-2">
                <div className="flex justify-between items-start w-full pb-2 ">
                    <div className="">
                        <span className="text-sm font-normal text-darkGray self-center">
                            Journey Status:
                        </span>
                    </div>
                    <p className={`${statusConfig[clientData?.onboardingStatus]?.statusClassName}`}>
                        {statusConfig[clientData?.onboardingStatus]?.statusText}
                    </p>
                </div>
                {clientData?.userRegistrationState &&
                    !clientData?.userRegistrationState?.finalVerification && (
                        <div className="flex gap-3">
                            <div className="min-w-[150px] !mt-2">
                                <ThemeButton
                                    icon={<CloseOutlined className=" text-red-600" />}
                                    action={() =>
                                        clientData?.onboardingStatus === 'Onboarding'
                                            ? setConfirmationModal('Reject')
                                            : handleRejectAccount()
                                    }
                                    text="Reject Client"
                                    autoWidth={true}
                                    style="reset-filter-btn !h-9  p-3 rounded-lg"
                                    loading={rejectLoader}
                                />
                            </div>
                            <div className="min-w-[150px] !mt-2">
                                <ThemeButton
                                    icon={<CheckOutlined className=" text-green-600" />}
                                    action={() =>
                                        clientData?.onboardingStatus === 'Onboarding'
                                            ? setConfirmationModal('Approve')
                                            : handleVerifyAccount()
                                    }
                                    text="Approve Client"
                                    autoWidth={true}
                                    style="reset-filter-btn !h-9  p-3 rounded-lg"
                                    loading={verificationLoader}
                                />
                            </div>
                        </div>
                    )}
            </div>
            <div className="flex justify-between items-start w-full pb-3 pt-2 border-t border-grey">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">WhiteLabel:</span>
                </div>
                <Select
                    className="white-label-css text-[#51525C] !w-44"
                    suffixIcon={whiteLabelLoading ? <LoadingOutlined /> : <DownOutlined />}
                    dropdownRender={menu => <>{menu}</>}
                    // placeholder="Select Whitelabel"
                    options={whiteLabelData?.map((item, key) => ({
                        value: item?.value,
                        label: item?.label,
                        key: key,
                    }))}
                    value={selectedWhiteLabel || 'none'}
                    onChange={value => handleWhiteLabelChange(value)}
                    loading={whiteLabelLoading}
                />
            </div>
            <div className="flex justify-between items-start pt-2 w-full border-t border-grey">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">
                        Block Access to Account
                    </span>
                </div>
                <Switch
                    checked={accountAccess}
                    onChange={handleAccessSwitchChange}
                    loading={userAccessLoading}
                />
            </div>
            <Divider className="my-2" />
            <div className="flex justify-between items-start w-full pb-2">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Enable Crypto:</span>
                </div>
                <Switch
                    onChange={handleCryptoSwitchChange}
                    checked={cryptoTab}
                    loading={cryptoLoading}
                />
            </div>
            <div className="flex justify-between items-start py-2 w-full border-t border-grey">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Enable Fiat:</span>
                </div>
                <Switch onChange={handleFiatSwitchChange} checked={fiatTab} loading={fiatLoading} />
            </div>
            {clientData.accountType === 'Personal' && (
                <>
                    <div className="flex justify-between items-center py-2 w-full border-t border-grey">
                        <div className="">
                            <span className="text-sm font-normal text-darkGray">KYC Status:</span>
                        </div>
                        {clientData.kyc?.status === 'pending' ? (
                            <Dropdown
                                overlay={menu}
                                trigger={['click']}
                                className="bg-status-pending-bg text-status-pending-text rounded-full py-1  text-sm font-medium capitalize">
                                <div className="flex items-center gap-1">
                                    <p
                                        className="py-1 pl-3  text-sm font-medium capitalize "
                                        onClick={e => e.preventDefault()}>
                                        pending
                                    </p>
                                    <DownOutlined className="pr-3" />
                                </div>
                            </Dropdown>
                        ) : (
                            <p
                                className={`${statusClasses[clientData.kyc?.status]} rounded-full py-1 px-3 text-sm font-medium capitalize`}>
                                {clientData.kyc?.status}
                            </p>
                        )}
                    </div>
                    {clientData?.kyc?.inspectionId && (
                        <div className="flex justify-between items-start py-2 w-full border-t border-grey">
                            <div className="">
                                <span className="text-sm font-normal text-darkGray">KYC Data:</span>
                            </div>
                            <p
                                onClick={() =>
                                    window.open(
                                        `https://cockpit.sumsub.com/checkus/#/applicant/${clientData?.kyc?.inspectionId}`,
                                        '_blank',
                                    )
                                }
                                className={`cursor-pointer text-[#175CD3] text-sm font-medium `}>
                                Show KYC Details
                            </p>
                        </div>
                    )}
                </>
            )}
            <div className="flex justify-between items-start py-2 w-full border-t border-grey">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">2FA Settings:</span>
                </div>
                <Switch
                    onChange={reset2FA}
                    checked={clientData?.twoFA?.enabled}
                    // loading={fiatLoading}
                />
            </div>
            <div className="w-[100px]">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={() => reset2FA(false)}
                    text="Reset"
                    autoWidth={true}
                    style="reset-filter-btn !h-9 w-[100px] p-3"
                    loading={loading}
                />
            </div>
            <div className="min-w-[150px] !mt-2 border-t border-grey py-2">
                <ThemeButton
                    icon={<DeleteOutlined className=" text-red-600" />}
                    action={() => setConfirmationModal('Delete')}
                    text="Delete Account"
                    autoWidth={true}
                    style="reset-filter-btn !h-9  p-3 rounded-lg"
                />
            </div>
            {['Reject', 'Approve'].includes(confirmationModal) && (
                <ConfirmationModal
                    open={['Reject', 'Approve'].includes(confirmationModal)}
                    title={updateStatus[confirmationModal].title}
                    content={updateStatus[confirmationModal].description}
                    handleClose={() => setConfirmationModal('')}
                    handleConfirm={updateStatus[confirmationModal].handleConfirm}
                />
            )}
            <DeleteModal
                open={confirmationModal === 'Delete'}
                handleClose={() => setConfirmationModal('')}
                title="Delete Account"
                content="Are you sure you want to delete your account? This action is permanent and cannot be undone. Once deleted, you will lose access to all your account data."
                handleDelete={handleDeleteAccount}
                loading={loading}
            />
        </div>
    );
};

export default SettingsTabs;
