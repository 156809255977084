import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { Button, Divider, Tabs, Form, message, Dropdown, Menu, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Download, Edit, resetPassword } from '../utils/Images';
import { LeftOutlined, DownOutlined, FileTextOutlined } from '@ant-design/icons';
import Details from '../components/accountDetailsTabs/Details';
import { useNavigate, useParams } from 'react-router-dom';
import {
    accountDetailsNav,
    feeProfileOptions,
    statusConfig,
    thresholdProfileOptions,
} from '../utils/constant';
import apiRequest from '../utils/api';
import {
    addAuditTrail,
    CopyButton,
    formatAmount,
    getCryptoBalances,
    useCommonFunctions,
} from '../utils/common';
import {
    clientFiatBalance,
    singleClientData,
    setClientFeeSetting,
    setBalancesData,
} from '../redux/actions/accountActions';
import DownloadCsvModal from '../components/modals/DownloadCsvModal';
import CommonModal from '../components/modals/CommonModal';
import dayjs from 'dayjs';
import BreadCrumHeader from '../components/common/BreadCrumHeader';
import { FormDOB, FormInput, FormPassword, FormSelect } from '../components/forms';
import jsPDF from 'jspdf';
import moment from 'moment';

const AccountDetails = () => {
    const navigate = useNavigate();
    const clientData = useSelector(state => state.account.clientData);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const totalFiatAmount = useSelector(state => state.account.totalFiatBalance);
    const totalCryptoBalance = useSelector(state => state.account.totalCryptoBalance) || 0;
    const { getClientFeeSetting } = useCommonFunctions();

    const email = localStorage.getItem('email');
    const [data, setData] = useState([]);
    const [formValue, setFormValue] = useState();
    const [userData, setUserData] = useState({
        password: '',
        confirmPassword: '',
    });
    const [clientBalances, setClientBalances] = useState(null);
    const [downloadCsv, setDownloadCsv] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [openDesktopPopover, setOpenDesktopPopover] = useState(false);
    const [openMobilePopover, setOpenMobilePopover] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(true);

    const ActionButton = ({ icon, onClick }) => (
        <Button onClick={onClick} icon={icon} className="mr-2 bg-[#F4F4F5] w-8 h-8"></Button>
    );

    const getClientsDetails = async () => {
        const body = {
            email,
            clientId: id,
        };

        try {
            const response = await apiRequest('/get-single-client-data', 'POST', body);
            if (response.success) {
                if (response.data) {
                    setData(response.data);
                    const initialFormValue = {
                        ...response.data,
                    };
                    setFormValue(initialFormValue);
                    form.setFieldsValue(initialFormValue);
                    dispatch(singleClientData(response.data));
                }
            } else {
                message.error(response.error);
                navigate('/accounts');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const openEditModal = () => {
        setEditModal(true);

        const initialFormValue = {
            ...data,
            twoFA: data?.twoFA?.enabled || false,
            dob: data?.dob ? dayjs(data?.dob) : null,
            country: data?.country ? data.country : data.countryOfIncorporation,
        };
        setFormValue(initialFormValue);
        form.setFieldsValue(initialFormValue);
    };

    const fetchFeeSetting = async () => {
        const body = { email, clientId: id, transactionType: 'Deposit', currencyType: 'fiat' };
        try {
            await getClientFeeSetting(body);
        } catch (error) {
            console.error('Error fetching fee setting', error);
        }
    };

    const getClientsBalances = async () => {
        const body = {
            clientId: id,
            email,
        };
        try {
            const response = await apiRequest('/get-client-balances', 'POST', body);

            if (response.success) {
                if (response.data) {
                    setClientBalances(response.data);
                    dispatch(clientFiatBalance(response?.data));
                    dispatch(setBalancesData(response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getClientsDetails();
        getClientsBalances();
        getCryptoBalances(dispatch, id, email, setBalanceLoading);
        getWhiteLableList();
        fetchFeeSetting();
    }, [id]);

    const handleCloseEditModal = () => {
        setEditModal(false);
        setErrorMsg('');
        setUserData({
            password: '',
            confirmPassword: '',
        });
    };

    const updateClientDetails = async updatedState => {
        const body = {
            email,
            userEmail: data.clientEmail,
            clientId: form.getFieldValue('clientId'),
            clientEmail: form.getFieldValue('clientEmail'),
            feeProfile: updatedState.feeProfile,
            thresholdProfile: updatedState.thresholdProfile,
        };

        try {
            const response = await apiRequest('/update-single-client-data', 'POST', body);

            if (response.success) {
                if (response.data) {
                    const originalData = {};
                    const newData = {};

                    // Loop to chekck the changed fields
                    Object.keys(body).forEach(key => {
                        if (data[key] !== response.data[key]) {
                            originalData[key] = data[key] || ''; // Old value
                            newData[key] = response.data[key]; // New value
                        }
                    });
                    // Audit Trail entry on update account
                    const auditTrailBody = {
                        userId: email,
                        pageUrl: window.location.href,
                        clientId: formValue.clientId,
                        eventType: 'Update Account',
                        ipAddress: localStorage.getItem('ip') || '',
                        userAgent: window.navigator.userAgent,
                        originalData,
                        newData,
                    };

                    addAuditTrail(auditTrailBody);
                    handleCloseEditModal();
                    getClientsDetails();
                }
            } else {
                setErrorMsg(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateClientPassword = async () => {
        const formValues = await form.validateFields();

        const body = {
            email,
            clientEmail: data.clientEmail,
            newPassword: formValues.password,
        };

        try {
            const response = await apiRequest('/changePassword-client', 'POST', body);

            if (response.success) {
                if (response.data) {
                    handleCloseEditModal();
                    getClientsDetails();
                }
            } else {
                message.error(response.error);
                setErrorMsg(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTableChange = tab => {
        setUserData({
            password: '',
            confirmPassword: '',
        });
        setErrorMsg('');
        setActiveTab(tab);
    };

    const getWhiteLableList = async () => {
        const body = {
            email,
            filterParams: {
                limit: 100,
            },
        };

        try {
            const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);

            if (response.success || response.data) {
                const options = response.data.whitelabelList.map(
                    ({ whitelableName, whitelabelId }) => ({
                        label: whitelableName,
                        value: whitelabelId,
                    }),
                );
                setWhiteLabelData([{ label: 'None', value: 'none' }, ...options]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectChange = (fieldName, value, shouldUpdate = false) => {
        setFormValue(prevState => {
            const updatedState = {
                ...prevState,
                [fieldName]: value,
            };

            form.setFieldsValue({ [fieldName]: value });
            if (shouldUpdate) {
                updateClientDetails(updatedState);
            }
            return updatedState;
        });
    };

    const feeItems = (
        <Menu onClick={e => handleSelectChange('feeProfile', e.key, true)}>
            {feeProfileOptions.map(option => (
                <Menu.Item key={option.value}>{option.label}</Menu.Item>
            ))}
        </Menu>
    );

    const thresholdItems = (
        <Menu onClick={e => handleSelectChange('thresholdProfile', e.key, true)}>
            {thresholdProfileOptions.map(option => (
                <Menu.Item key={option.value}>{option.label}</Menu.Item>
            ))}
        </Menu>
    );

    const totalAmount = Number(totalCryptoBalance) + Number(totalFiatAmount);

    const generatePDF = async (isAccountConfirmation = false) => {
        try {
            const imageUrl = `${imageBaseUrl}/landingpage/junomoney-header-logo.svg`;
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.statusText}`);
            }

            const contentType = response.headers.get('content-type');

            let dataUrl;
            if (contentType.includes('svg')) {
                const svgText = await response.text();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    dataUrl = canvas.toDataURL('image/png');
                    if (isAccountConfirmation) {
                        createAccountConfirmationPDF(dataUrl, img.width, img.height);
                    } else createPDF(dataUrl, img.width, img.height);
                };

                img.onerror = () => {
                    console.error('Failed to load SVG image into the canvas');
                };

                img.src = 'data:image/svg+xml;base64,' + btoa(svgText);
            } else {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.src = imageUrl;
                img.onload = () => {
                    if (isAccountConfirmation) {
                        createAccountConfirmationPDF(img, 50, 10);
                    } else createPDF(img, 50, 10);
                };
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const createAccountConfirmationPDF = (dataUrl, imgWidth, imgHeight) => {
        const maxWidth = 50;
        const maxHeight = 10;

        let width = imgWidth;
        let height = imgHeight;

        const aspectRatio = width / height;
        if (width > maxWidth || height > maxHeight) {
            if (width / maxWidth > height / maxHeight) {
                width = maxWidth;
                height = maxWidth / aspectRatio;
            } else {
                height = maxHeight;
                width = maxHeight * aspectRatio;
            }
        }

        const pdf = new jsPDF();
        pdf.addImage(dataUrl, 'PNG', 10, 5, width, height);

        pdf.setFontSize(14);
        pdf.setDrawColor(208, 213, 221);
        pdf.line(10, 18, 200, 18);
        const currentDate = new Date(clientData.createdAt).toLocaleDateString('en-GB', {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
        pdf.setTextColor(71, 84, 103).text(currentDate, 10, 25);
        pdf.setTextColor(52, 64, 84)
            .setFont(undefined, 'bold')
            .text(clientData.name, 10, 35)
            .setFont(undefined, 'normal')
            .setTextColor(71, 84, 103);

        if (clientData.address) {
            pdf.text(clientData.address, 10, 43);
        }
        if (clientData.city) {
            pdf.text(clientData.city, 10, 50);
        }
        if (clientData.postCode) {
            pdf.text(clientData.postCode, 10, 57);
        }
        if (clientData.country) {
            pdf.text(clientData.country, 10, 64);
        }
        pdf.text(`Dear ${clientData.name},`, 10, 83);
        pdf.setTextColor(52, 64, 84)
            .setFont(undefined, 'bold')
            .text('Re: Account Confirmation Letter', 10, 92)
            .setFont(undefined, 'normal');

        const lines = pdf.splitTextToSize(
            `Juno Pay Ltd (trading as Juno money) can confirm that ${clientData.name} holds an account with our financial institution.`,
            190,
        );

        let textY = 101;
        lines.forEach((line, index) => {
            pdf.text(line, 10, textY + index * 7);
        });

        pdf.text('Account Reference: ', 10, 119)
            .setTextColor(52, 64, 84)
            .setFont(undefined, 'bold')
            .text(`${clientData.accountNumber}.`, 55, 119)
            .setFont(undefined, 'normal');

        pdf.setTextColor(71, 84, 103).text(
            'Please do not hesitate to contact our Customer Services team if you have any questions',
            10,
            131,
        );
        pdf.text('in relation to your account.', 10, 137);

        pdf.text('Yours sincerely,', 10, 148);
        pdf.setTextColor(52, 64, 84).setFont(undefined, 'bold').text('BECKY LEVERS', 10, 210);
        pdf.text('CRO / MLRO', 10, 217).setFont(undefined, 'normal');

        pdf.setTextColor(71, 84, 103).text('M: +44 7971 922 345', 10, 224);
        pdf.text('E:', 10, 231).setTextColor(0, 145, 255).text('support@junomoney.com', 17, 231);
        pdf.setTextColor(71, 84, 103).text('W: https://junomoney.com', 10, 238);

        const footerLines = pdf.splitTextToSize(
            `Juno Pay Ltd (trading as Juno Money) - (Company Number: 2024801421), with its registered office at Suite #229, 6030 88ST NW, Edmonton, Alberta, T6E6G4, Canada. Juno Pay Ltd is authorised by FINTRAC as a Money Service Business (MSB No. M23335654).`,
            220,
        );
        let footerTextY = 250;
        footerLines.forEach((line, index) => {
            pdf.setFontSize(12).text(line, 10, footerTextY + index * 7);
        });

        pdf.save('account-confirmation.pdf');
    };

    const createPDF = (dataUrl, imgWidth, imgHeight) => {
        const maxWidth = 50;
        const maxHeight = 10;

        let width = imgWidth;
        let height = imgHeight;

        const aspectRatio = width / height;
        if (width > maxWidth || height > maxHeight) {
            if (width / maxWidth > height / maxHeight) {
                width = maxWidth;
                height = maxWidth / aspectRatio;
            } else {
                height = maxHeight;
                width = maxHeight * aspectRatio;
            }
        }

        const accountData = {
            status: data.status || 'N/A',
            name: data.name || 'N/A',
            email: data.clientEmail,
            accountId: data.accountNumber || 'N/A',
            totalBalance: `${formatAmount(totalFiatAmount)}`,
            phone: data.phoneNumber || 'N/A',
            address: data.address || 'N/A',
            city: data.city || 'N/A',
            state: data.state || 'N/A',
            postalCode: data.postCode || 'N/A',
            country: data.country || 'N/A',
            registrationDate: moment(data?.createdAt).format('D.MM.YY HH:mm') || 'N/A',
        };

        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');

        pdf.addImage(dataUrl, 'PNG', 10, 5, width / 1.5, height / 1.5);

        // Divider line below the image
        const dividerY = 8 + height / 1.5; // Space 10mm below the image
        pdf.line(10, dividerY, 200, dividerY); // Horizontal line across the width of the page

        // Title
        pdf.text('Account Details', 10, dividerY + 15);

        // Placeholder Image (120x120px, gray, with border-radius)
        pdf.setFillColor(200, 200, 200); // Light gray color
        pdf.roundedRect(10, dividerY + 25, 40, 40, 5, 5, 'F'); // Positioned at (10, 25), 40x40mm with radius 5

        // Account status and user info to the right of the image
        let startX = 55;
        let startY = dividerY + 31;

        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Status:`, startX, startY);
        pdf.setFont('helvetica', 'normal');
        if (accountData.status === 'Active') {
            pdf.setTextColor(0, 128, 0); // Green for Active
        } else {
            pdf.setTextColor(0, 0, 0); // Gray for In active
        }
        pdf.text(accountData.status, startX + 17, startY);
        pdf.setTextColor(0, 0, 0); // Reset color for other text

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Name:', startX, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.name, startX + 15, startY);

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Email:', startX, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.email, startX + 15, startY);

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Account ID:', startX, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.accountId, startX + 25, startY);

        // Space and divider
        startY += 15;
        pdf.line(10, startY, 200, startY); // Horizontal line

        // Total Balance
        startY += 10;
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Total Balance:`, 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`$ ${accountData.totalBalance}`, 180, startY, { align: 'right' }); // Right-aligned at 180mm

        // Space and divider
        startY += 6;
        pdf.line(10, startY, 200, startY);

        // Additional details
        startY += 10;
        pdf.setFontSize(12);

        pdf.setFont('helvetica', 'bold');
        pdf.text('Phone:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.phone, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Address:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.address, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('City:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.city, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('ZIP / Postal code:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.postalCode, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Country:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.country, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Registration Date:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.registrationDate, 180, startY, { align: 'right' });

        // Save the PDF
        pdf.save('AccountDetails.pdf');
    };

    const generatePopoverContent = closePopover => (
        <div className="rounded-lg">
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C]"
                onClick={() => {
                    closePopover();
                    setDownloadCsv(true);
                }}>
                Balances
            </button>
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C] mt-2"
                onClick={() => {
                    closePopover();
                    generatePDF();
                }}>
                Account details
            </button>
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C] mt-2"
                onClick={() => {
                    closePopover();
                    generatePDF(true);
                }}>
                {'Account confirmation letter'}
            </button>
        </div>
    );

    const handleRegistrationDownload = async () => {
        const body = {
            email,
            paymentProviderId,
            businessEmail: data?.clientEmail,
        };
        try {
            const response = await apiRequest('/generate-registration-pdf', 'POST', body);
            if (response?.success && response?.data) {
                const pdfData = response.data;
                const newTab = window.open();
                newTab.document.write(pdfData);
            } else {
                message.error(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const accountDetailsNavNew = accountDetailsNav.filter(
        item =>
            !(clientData.accountType === 'Business'
                ? item.label === 'Personal'
                : item.label === 'Business'),
    );

    return (
        <>
            <Sidebar>
                <div className="gap-[16px] m-6 lg:block hidden h-screen">
                    <BreadCrumHeader
                        subTabs={[
                            {
                                href: '/accounts',
                                icon: 'multipleUsersIcon.svg',
                                title: 'Accounts',
                            },
                        ]}
                        currentTab={{ title: data.name }}
                    />
                    <div className="bg-white rounded-xl">
                        <div className="grid grid-cols-3 min-h-[90vh] ">
                            <div className="border-r border-[#E4E4E7] min-h-[90vh]">
                                <div className="flex justify-between items-center p-6">
                                    <h1 className="page-title">Account Details</h1>
                                    <div>
                                        <Popover
                                            placement="bottom"
                                            content={() =>
                                                generatePopoverContent(() =>
                                                    setOpenDesktopPopover(false),
                                                )
                                            }
                                            trigger="click"
                                            open={openDesktopPopover}
                                            onOpenChange={open => setOpenDesktopPopover(open)}>
                                            <ActionButton
                                                icon={
                                                    <img
                                                        src={`${imageBaseUrl}${Download}`}
                                                        alt=""
                                                        className="w-4 h-4"
                                                    />
                                                }
                                            />
                                        </Popover>
                                        <ActionButton
                                            icon={
                                                <img
                                                    src={`${imageBaseUrl}${resetPassword}`}
                                                    alt=""
                                                    className="w-4 h-4 transform scale-125"
                                                />
                                            }
                                            onClick={() => {
                                                openEditModal();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="overflow-auto px-6">
                                    <div className="flex items-center gap-4">
                                        <img
                                            className="max-w-[128px] max-h-[128px] rounded-xl object-cover"
                                            src="https://via.placeholder.com/150"
                                            alt="Avatar"
                                        />
                                        <div className="w-full">
                                            <div className="flex justify-between items-center mb-4">
                                                <p className="text-sm font-normal text-darkGray">
                                                    Status:
                                                </p>
                                                <p
                                                    className={`${statusConfig[clientData?.onboardingStatus]?.statusClassName}`}>
                                                    {
                                                        statusConfig[clientData?.onboardingStatus]
                                                            ?.statusText
                                                    }
                                                </p>
                                            </div>
                                            <h2 className="text-base font-semibold">
                                                {data.accountType === 'Personal'
                                                    ? data.name
                                                    : data.fullLegalEntityName}
                                            </h2>

                                            <p className="text-sm font-normal text-darkGray">
                                                {data.clientEmail}
                                                <CopyButton msg={data.clientEmail} />
                                            </p>
                                            <Divider className="my-2" />
                                            <p className="text-sm font-normal text-darkGray">
                                                Account ID:
                                                <span className="">{data.accountNumber}</span>
                                                <CopyButton msg={data.accountNumber} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <Divider className="mb-0 mt-6" />
                                    <div className="py-5 px-6">
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-normal text-darkGray">
                                                Juno Money:
                                            </p>
                                            <p className="text-base font-normal text-[#18181B]">
                                                {`$ ${formatAmount(totalFiatAmount) || 0}`}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center mt-2">
                                            <p className="text-sm font-normal text-darkGray">
                                                Juno X:
                                            </p>
                                            <p className="text-base font-normal text-[#18181B]">
                                                {`$ ${formatAmount(totalCryptoBalance) || 0}`}
                                            </p>
                                        </div>
                                        <Divider className="my-3" />
                                        <div className="flex justify-between items-center ">
                                            <p className="text-base font-medium text-primary">
                                                Total Balance:
                                            </p>
                                            <p className="text-base font-semibold text-primary">
                                                {`$ ${formatAmount(totalAmount)}`}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <Divider className="m-0" /> */}
                                </div>
                                <div className="">
                                    <div className="p-6">
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-normal text-darkGray">
                                                Fee Profile:
                                            </p>
                                            <div className="flex justify-center items-center gap-[1px]">
                                                <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                                    {formValue?.feeProfile}
                                                </div>
                                                <Dropdown
                                                    overlay={feeItems}
                                                    placement="bottomRight"
                                                    className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                                    trigger={['click']}>
                                                    <DownOutlined />
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center mt-2">
                                            <p className="text-sm font-normal text-darkGray">
                                                Threshold Profile:
                                            </p>
                                            <div className="flex justify-center items-center gap-[1px]">
                                                <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                                    {formValue?.thresholdProfile}
                                                </div>
                                                <Dropdown
                                                    overlay={thresholdItems}
                                                    placement="bottomRight"
                                                    className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                                    trigger={['click']}>
                                                    <DownOutlined />
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider className="mb-5 mt-0" />
                                </div>
                                <Details data={data} />
                            </div>
                            <div className="col-span-2 p-6 account-details">
                                <Tabs
                                    defaultActiveKey="1"
                                    items={accountDetailsNavNew.slice(1).filter(item => {
                                        // Render DocumentTab only for AccountType Business
                                        if (item.label === 'Documents') {
                                            return data.accountType === 'Business';
                                        }
                                        return true;
                                    })}
                                    className="mb-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:hidden bg-white">
                    <div className="flex justify-between items-center p-5 bg-[#F7F7F7]">
                        <h1
                            className="font-semibold text-base cursor-pointer"
                            onClick={() => navigate(-1)}>
                            <LeftOutlined className="mr-2" />
                            <span>Account</span>
                        </h1>
                        <div>
                            <Popover
                                placement="bottom"
                                content={() =>
                                    generatePopoverContent(() => setOpenMobilePopover(false))
                                }
                                trigger="click"
                                open={openMobilePopover}
                                onOpenChange={open => setOpenMobilePopover(open)}>
                                <Button
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${Download}`}
                                            alt="download"
                                            className="w-3 h-4"
                                        />
                                    }
                                    className="mr-2 border-0 bg-[#F7F7F7] "
                                />
                            </Popover>

                            <Button
                                icon={
                                    <img
                                        src={`${imageBaseUrl}${Edit}`}
                                        alt="edit"
                                        className="w-4 h-4"
                                    />
                                }
                                onClick={() => {
                                    openEditModal();
                                }}
                                className="border-0 bg-[#F7F7F7] "></Button>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 p-5">
                        <img
                            className="w-[88px] h-[88px] rounded-xl object-cover"
                            src="https://via.placeholder.com/150"
                            alt="Avatar"
                        />
                        <div className="w-full">
                            <div className="flex justify-between items-center mb-5">
                                <p className="text-sm font-normal text-darkGray">Status:</p>
                                <p
                                    className={`${statusConfig[clientData?.onboardingStatus]?.statusClassName}`}>
                                    {statusConfig[clientData?.onboardingStatus]?.statusText}
                                </p>
                            </div>
                            <h2 className="text-base font-semibold">
                                {data.accountType === 'Business'
                                    ? data.fullLegalEntityName
                                    : data.name}
                            </h2>

                            {/* <h2 className="text-base font-semibold">{data.name}</h2> */}
                            <p className="text-sm font-normal text-darkGray">
                                Account ID: <span className="">{data.accountNumber}</span>
                                <CopyButton msg={data.accountNumber} />
                            </p>
                        </div>
                    </div>
                    <Divider className="my-2" />
                    <div className="py-5 px-6">
                        {/* TODO: Commenting CRYPTO code */}
                        {/* <div className="flex justify-between items-center">
                                <p className="text-sm font-normal text-darkGray">Juno Money:</p>
                                <p className="text-base font-normal text-[#18181B]">
                                    {`$ ${formatAmount(totalFiatAmount)}`}
                                </p>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="text-sm font-normal text-darkGray">Juno X:</p>
                                <p className="text-base font-normal text-[#18181B]">
                                    {`$ ${formatAmount(totalCryptoBalance)}`}
                                </p>
                            </div>
                            <Divider className="my-3" /> */}
                        <div className="flex justify-between items-center ">
                            <p className="text-base font-medium text-primary">Total Balance:</p>
                            <p className="text-base font-semibold text-primary">
                                {/* TODO: Commenting CRYPTO code */}
                                {/* {`$ ${formatAmount(totalAmount)}`} */}
                                {`$ ${formatAmount(totalFiatAmount)}`}
                            </p>
                        </div>
                    </div>
                    <Divider className="m-0" />
                    {/* TODO: Commenting CRYPTO code */}
                    {/* <div className="p-6">
                            <div className="flex justify-between items-center">
                                <p className="text-sm font-normal text-darkGray">Fee Profile:</p>
                                <div className="flex justify-center items-center gap-[1px]">
                                    <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                        {formValue?.feeProfile}
                                    </div>
                                    <Dropdown
                                        overlay={feeItems}
                                        placement="bottomRight"
                                        className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                        trigger={['click']}>
                                        <DownOutlined />
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="text-sm font-normal text-darkGray">Threshold Profile:</p>
                                <div className="flex justify-center items-center gap-[1px]">
                                    <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                        {formValue?.thresholdProfile}
                                    </div>
                                    <Dropdown
                                        overlay={thresholdItems}
                                        placement="bottomRight"
                                        className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                        trigger={['click']}>
                                        <DownOutlined />
                                    </Dropdown>
                                </div>
                            </div>
                        </div> */}
                    <div className="bg-[#F7F7F7]">
                        <Tabs
                            defaultActiveKey="0"
                            items={accountDetailsNavNew}
                            className="transaction-detail account-tabs-mob hide-scrollbar"
                        />
                    </div>
                </div>
            </Sidebar>
            <DownloadCsvModal
                data={[data]}
                open={downloadCsv}
                handleClose={() => setDownloadCsv(false)}
                name="singleClient"
            />
            <CommonModal
                title="Edit Password"
                open={editModal}
                handleClose={handleCloseEditModal}
                className={'common-mobile-view account-model-view'}
                footerText={'Reset Password'}
                error={errorMsg}
                onFinish={updateClientPassword}
                form={form}>
                <div className="mt-10 lg:mt-[30px] h-[45vh] px-[7px] modal-form">
                    <Form
                        form={form}
                        layout="horizontal"
                        colon={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}>
                        <>
                            <FormPassword
                                label="New Password:"
                                name="password"
                                placeholder="Create Password"
                                dataE2e="create-password"
                                rules={[{ required: true, message: 'Please enter a password' }]}
                                onChange={value => handleSelectChange('password', value)}
                                style={{ marginBottom: '16px' }} // Adds space between fields
                            />
                            <FormPassword
                                label="Confirm Password:"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                dataE2e="confirm-password"
                                onChange={value => handleSelectChange('confirmPassword', value)}
                                rules={[
                                    { required: true, message: 'Please confirm your password' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('The two passwords do not match'),
                                            );
                                        },
                                    }),
                                ]}
                                style={{ marginBottom: '16px' }} // Adds space between fields
                            />
                        </>
                    </Form>
                </div>
            </CommonModal>
        </>
    );
};

export default AccountDetails;
