import React, { useEffect, useState } from 'react';
import { Drawer, Input, Modal, Select } from 'antd';
import { clientTransactionStatusMapping, keyMappings, statusCode } from '../../utils/constant';
import apiRequest from '../../utils/api';
import { CopyButton, getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import { CryptoTransactionInfo } from './CryptoTransactionInfo';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon, Flag } from '../../utils/Images';
import ThemeButton from '../common/ThemeButton';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';

const { TextArea } = Input;

const TransactionInfo = ({ transaction, onUpdate, statusText, statusBg }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [loading, setLoading] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [flagDrawer, setFlagDrawer] = useState({ open: false, data: null });
    const complyAdvantageUrl = useSelector(state => state.config.COMPLY_ADVANTAGE_URL);

    const transactionId = transaction?.transactionId || null;

    const fieldFormatters = {
        fromAmount: value => toFixedTrunc(value, 2),
        amount: value => toFixedTrunc(value, 2),
        toAmount: value => toFixedTrunc(value, 2),
        fxrate: value => toFixedTrunc(value, 4),
        fxFee: value => toFixedTrunc(value, 4),
        exchangeRate: value => parseFloat(value),
        feePercentage: value => `${value}%`,
    };

    let transactionDetails = [];

    if (transaction && transaction?.createdBy) {
        transactionDetails.push({
            type: 'Created By',
            value: transaction?.createdBy,
            showCopy: true,
        });
    }

    const additionalDetails = Object.entries(transaction.transactionDetails[0])
        .filter(([key]) => key !== 'transactionFee')
        .map(([key, value]) => {
            const formatter = fieldFormatters[key];
            const formattedValue = formatter ? formatter(value) : value;

            return {
                type: keyMappings[key],
                value: formattedValue,
                showCopy: true,
            };
        });
    transactionDetails = transactionDetails.concat(additionalDetails);

    if (transaction) {
        const transactionFeeValue = transaction.transactionFee || 0;

        if (transaction.feePercentage) {
            transactionDetails.push({
                type: '% Fee',
                value: `${transaction.feePercentage}%`,
                showCopy: true,
            });
        }

        transactionDetails.push({
            type: 'Transaction Fee',
            value: transactionFeeValue,
            showCopy: true,
        });

        if (transaction.transferInFee) {
            transactionDetails.push({
                type: 'Merchant Fee',
                value: transaction.transferInFee,
                showCopy: true,
            });
        }
    }

    const statusLabelMap = {
        processing: {
            label: 'Processing',
            apiCode: statusCode['processing'][0],
        },
        complete: {
            label: 'Complete',
            apiCode: statusCode['completed'][0],
        },
        rejected: {
            label: 'Reject',
            apiCode: statusCode['rejected'][0],
        },
    };

    const updateTransaction = async () => {
        const body = {
            adminRole: localStorage.getItem('adminRole'),
            email: localStorage.getItem('email'),
            transactionData: transaction,
            message,
            status: statusLabelMap[status].apiCode,
            client: transaction.clientId,
        };
        setLoading(true);
        try {
            const response = await apiRequest('/update-client-transaction-merchant', 'POST', body);

            if (response.success) {
                setConfirmationModal(false);
                setMessage('');
                await onUpdate();
            } else {
                message.error(response.error);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error posting data:', error);
        }
    };

    const formatValue = item => {
        if (item.type === 'Transaction Fee') {
            const feeRoundoff = transaction?.currencyType === 'crypto' ? 8 : 2;
            return toFixedTrunc(item.value, feeRoundoff);
        }
        return item.value || '-';
    };

    const statusConfig = {
        'On Hold': {
            statusName: 'On Hold',
            statusClassName:
                'text-sm px-5 py-1 bg-[#F4F4F5] text-center rounded-2xl text-[#3F3F46] font-medium capitalize',
        },
        Approved: {
            statusName: 'Approved',
            statusClassName:
                'text-green-800 bg-[#ECFDF3] rounded-full py-1 px-5 text-sm font-medium',
        },
        Rejected: {
            statusName: 'Rejected',
            statusClassName:
                'text-[#B54708] bg-[#FFFAEB;] rounded-full py-1 px-5 text-sm font-medium',
        },
        default: {
            statusText: 'Processing',
            statusClassName:
                'text-sm px-5 py-1 bg-[#EFF8FF] text-center rounded-2xl text-[#175CD3] font-medium capitalize',
        },
    };
    const { statusName, statusClassName } =
        statusConfig[transaction?.complyAdvantageCheck?.amlAlerts?.amlStatus] ||
        statusConfig.default;

    return (
        <>
            <div className="bg-white p-5">
                <h1 className="font-semibold text-base pb-5 lg:hidden ">Transaction Info</h1>
                {transaction.currencyType === 'fiat' ? (
                    <div className="p-3 pb-0 border rounded-lg border-[#E4E4E7] mt-5 bg-[#fcfcfc]">
                        <div className="flex justify-between items-center border-b border-[#E4E4E7] p-3">
                            <p className="text-sm font-normal text-darkGray" data-e2e="status">
                                Transaction status:
                            </p>
                            {['complete', 'rejected'].includes(
                                clientTransactionStatusMapping[transaction.status.code],
                            ) ? (
                                <>
                                    {clientTransactionStatusMapping[transaction.status.code] ===
                                    'complete' ? (
                                        <p
                                            data-e2e="completed"
                                            className="text-success bg-[#ECFDF3] rounded-full py-1 px-2 text-sm font-medium">
                                            Completed
                                        </p>
                                    ) : (
                                        <p
                                            data-e2e="rejected"
                                            className="text-warning bg-[#FFFAEB] rounded-full py-1 px-2 text-sm font-medium">
                                            Rejected
                                        </p>
                                    )}
                                </>
                            ) : transaction?.type &&
                              ['Withdrawal', 'Payment Out']?.includes(transaction.type) ? (
                                <Select
                                    suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                                    placeholder="Update status"
                                    onSelect={() => setConfirmationModal(true)}
                                    style={{ width: 170 }}
                                    data-e2e="update-status-drop-down"
                                    onChange={value => setStatus(value)}
                                    defaultValue={
                                        clientTransactionStatusMapping[transaction.status.code]
                                    }
                                    options={[
                                        {
                                            value: 'processing',
                                            label: 'Processing',
                                            disabled:
                                                clientTransactionStatusMapping[
                                                    transaction.status.code
                                                ] === 'processing',
                                        },
                                        { value: 'complete', label: 'Complete' },
                                        { value: 'rejected', label: 'Reject' },
                                    ]}
                                />
                            ) : (
                                <div className="flex">
                                    <p className={statusClassName}>{statusText}</p>
                                </div>
                            )}
                        </div>
                        {transaction?.complyAdvantageCheck?.amlAlerts?.alerts?.length > 0 && (
                            <>
                                <div className="flex justify-between items-center border-b border-[#E4E4E7] p-3">
                                    <p className="text-sm font-normal text-darkGray mr-10">
                                        Flags:
                                    </p>
                                    <p className="flex gap-2 overflow-x-auto lg:flex-wrap lg:justify-end scrollbar-hide whitespace-nowrap hide-scrollbar">
                                        {transaction?.complyAdvantageCheck?.amlAlerts?.alerts?.map(
                                            (val, index) => (
                                                <div key={index} className="flex mb-1">
                                                    <div className="flex items-center gap-2 bg-[#FEF3F2] text-[#912018] px-3 py-1 rounded-l-full w-max">
                                                        <img
                                                            src={`${imageBaseUrl}${Flag}`}
                                                            alt="flag"
                                                            className="w-3 h-3 inline"
                                                        />
                                                        <span className="text-sm font-medium">
                                                            {val?.rule?.name || 'Unnamed Rule'}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="bg-[#FEF3F2] py-1 pl-1 pr-1.5 ml-[1px] w-max rounded-r-full cursor-pointer"
                                                        onClick={() =>
                                                            setFlagDrawer({ data: val, open: true })
                                                        }
                                                        aria-label="Open Flag Details">
                                                        <RightOutlined />
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                    </p>
                                </div>
                                <div className="flex justify-between items-center flex-wrap border-b border-[#E4E4E7] p-3">
                                    <p className="text-sm font-normal text-darkGray">AML Status:</p>
                                    <div className="flex gap-2 items-center">
                                        <p className={statusClassName}>{statusName}</p>
                                        <a
                                            href={`${complyAdvantageUrl}/transactions/${transaction?.complyAdvantageCheck?.result?.internal_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <span
                                                data-e2-url={`${complyAdvantageUrl}/transactions/${transaction?.complyAdvantageCheck?.result?.internal_id}`}
                                                data-e2e="comply-advantage-icon"
                                                className="bg-[#f8f4f4] rounded-full font-bold w-6 h-6 flex items-center justify-center cursor-pointer">
                                                C
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                        {transactionId && (
                            <div className="flex justify-between items-center flex-wrap border-b border-[#E4E4E7] p-3">
                                <p className="text-sm font-normal text-darkGray">Transaction ID:</p>
                                <p className="text-sm font-normal text-darkGray">
                                    {transactionId}
                                    <CopyButton msg={transactionId} />
                                </p>
                            </div>
                        )}
                        {transactionDetails.map(
                            (item, index) =>
                                item.type && (
                                    <div
                                        className="flex justify-between items-center flex-wrap border-b border-[#E4E4E7] p-3"
                                        key={index}>
                                        <p className="text-sm font-normal text-darkGray">
                                            {item.type}:
                                        </p>
                                        <p className="text-sm font-normal text-darkGray">
                                            {formatValue(item)}
                                            {item.showCopy && <CopyButton msg={item.value} />}
                                        </p>
                                    </div>
                                ),
                        )}
                    </div>
                ) : (
                    <CryptoTransactionInfo
                        transaction={transaction}
                        statusText={statusText}
                        statusBg={statusBg}
                    />
                )}
                {confirmationModal && (
                    <Modal
                        centered
                        title={
                            <p
                                className="capitalize text-2xl font-semibold text-primary"
                                data-e2e={statusLabelMap[status].label}>
                                {statusLabelMap[status].label}
                            </p>
                        }
                        open={confirmationModal}
                        footer={null}
                        onCancel={() => {
                            setConfirmationModal(false);
                            setMessage('');
                        }}>
                        <p className="mt-10 mb-2 text-base">Note: </p>
                        <TextArea
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            className="px-4 py-3 bg-[#F4F4F5] border border-[#E4E4E7] rounded-lg"
                            rows={4}
                            placeholder="Add your note... "
                            data-e2e="add-note..."
                        />
                        <div className="mt-12 flex justify-end">
                            <div className="w-40">
                                <ThemeButton
                                    loading={loading}
                                    disabled={message === ''}
                                    type="primary"
                                    text="Update"
                                    dataE2e="update-btn"
                                    action={updateTransaction}
                                    shape="round"
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
            <Drawer
                title={false}
                closeIcon={false}
                onClose={() => setFlagDrawer({ ...flagDrawer, open: false })}
                open={flagDrawer.open}>
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-lg font-semibold">Flag Details</h1>
                    <p
                        className="text-lg font-semibold cursor-pointer"
                        onClick={() => setFlagDrawer({ ...flagDrawer, open: false })}>
                        X
                    </p>
                </div>
                <div className="flex justify-between items-center w-full">
                    <h1 className="text-sm font-normal text-[#51525C]">Reasons for Flag:</h1>
                    <p className="text-sm font-normal text-[#18181B] text-right">
                        {flagDrawer?.data?.rule?.name}
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center w-full">
                    <h1 className="text-sm font-normal text-[#51525C]">Code:</h1>
                    <p className="text-sm font-normal rounded-full px-3 py-1 text-right">
                        {flagDrawer?.data?.rule?.code}
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center w-full">
                    <h1 className="text-sm font-normal text-[#51525C]">Severity:</h1>
                    <p className="text-sm font-normal rounded-full px-3 py-1 text-[#B42318] bg-[#FEF3F2] text-right">
                        -
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center w-full">
                    <h1 className="text-sm font-normal text-[#51525C]">Current Status:</h1>
                    <p className="text-sm font-normal rounded-full px-3 py-1 text-[#175CD3] bg-[#EFF8FF] text-right">
                        {flagDrawer?.data?.action?.label}
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center w-full">
                    <h1 className="text-sm font-normal text-[#51525C]">Flagging Date:</h1>
                    <p className="text-sm font-normal text-[#18181B] text-right">
                        {moment(transaction?.complyAdvantageCheck?.result?.updated_at).format(
                            'MMMM DD ,YYYY',
                        )}
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center w-full">
                    <h1 className="text-sm font-normal text-[#51525C]">Source of Flag:</h1>
                    <p className="text-sm font-normal text-[#18181B] text-right">-</p>
                </div>
                <hr className="my-3" />
                <div className="">
                    <h1 className="text-sm font-normal text-[#51525C]">Reason Detected:</h1>
                    <ul className="list-disc pl-4">
                        <li className="text-sm font-normal text-[#18181B] pt-3">
                            {flagDrawer?.data?.message}
                        </li>
                    </ul>
                </div>
                {/* <hr className="my-3" />
                <div className="">
                    <h1 className="text-sm font-normal text-[#51525C]">Action Taken:</h1>
                    <ul className="list-disc pl-4">
                        <li className="text-sm font-normal text-[#18181B] pt-3">
                            Check number mismatch with issued sequence.
                        </li>
                        <li className="text-sm font-normal text-[#18181B]">
                            Amount unusually high compared to account activity.
                        </li>
                        <li className="text-sm font-normal text-[#18181B]">
                            Signature anomaly identified by automated verification.
                        </li>
                    </ul>
                </div> */}
            </Drawer>
        </>
    );
};

export default TransactionInfo;
