import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Divider, message } from 'antd';
import { CloudUploadOutlined, FileOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useSelector } from 'react-redux';
import { getPresignedUrl } from '../../utils/common';
import apiRequest from '../../utils/api';
import { Delete } from '../../utils/Images';
import { FormInput, FormPassword } from '../forms';
import ThemeButton from '../common/ThemeButton';

const WhiteLabelModal = ({
    open,
    handleClose,
    initialValues,
    modalType,
    getWhiteLabelUsers,
    pageNo,
    ...rest
}) => {
    const [userForm] = Form.useForm();
    const [form] = Form.useForm();
    const email = localStorage.getItem('email');
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState({});
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState('');

    useEffect(() => {
        if (modalType === 'add') {
            setData(initialValues);
            form.resetFields();
            setSelectedFile(null);
        } else if (modalType === 'edit' && initialValues) {
            setData({});
            form.setFieldsValue(initialValues);
        }
    }, [modalType, initialValues, form]);

    const handleFileChange = info => {
        setSelectedFile(info.file);
        message.success(`${info.file.name} file selected successfully.`);
    };

    const createWhiteLabel = async () => {
        const formValues = await form.validateFields();
        let imgUrl;
        if (selectedFile) {
            setLoading(true);
            const payload = {
                imageType: 'clientFiatWhiteLabelLogo',
                whiteLabelName: `${formValues.whitelableName}`,
                email,
            };
            const imageUrl = `${imageBaseUrl}/client-white-label/fiatLogo/${formValues.whitelableName}/logo`;
            imgUrl = await getPresignedUrl(payload, selectedFile, imageUrl);
            const body = {
                whitelableName: formValues.whitelableName,
                description: formValues.description,
                domainURL: formValues.domainURL,
                logo: imgUrl,
                logoWidth: formValues.logoWidth,
                primaryColor: formValues.primaryColor,
                redirectURL: formValues.redirectURL,
                email,
            };
            try {
                const res = await apiRequest('/create-juno-white-listing', 'POST', body);
                if (res.success) {
                    getWhiteLabelUsers(pageNo);
                    handleClose();
                    setSelectedFile(null);
                    message.success(`Created successfully`);
                    setLoading(false);
                    setData({});
                    form.resetFields();
                } else {
                    message.error(res.error);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err.message, 'Fetch Error');
                setLoading(false);
            }
        } else {
            setImageError('Logo is required');
        }
    };

    const updateWhiteLabel = async () => {
        const formValues = await form.validateFields();
        let imgUrl;
        if (selectedFile || initialValues?.logo) {
            setLoading(true);
            let payload = {};
            if (selectedFile) {
                payload = {
                    imageType: 'clientFiatWhiteLabelLogo',
                    whiteLabelName: `${formValues.whitelableName}`,
                    email,
                };
                const imageUrl = `${imageBaseUrl}/client-white-label/fiatLogo/${formValues.whitelableName}/logo`;
                imgUrl = await getPresignedUrl(payload, selectedFile, imageUrl);
            } else {
                imgUrl = initialValues.logo;
            }
            const body = {
                id: initialValues._id,
                whitelableName: formValues.whitelableName,
                description: formValues.description,
                domainURL: formValues.domainURL,
                logo: imgUrl,
                logoWidth: formValues.logoWidth,
                primaryColor: formValues.primaryColor,
                redirectURL: formValues.redirectURL,
                email,
            };
            try {
                const res = await apiRequest('/update-juno-white-listing', 'POST', body);
                if (res.success) {
                    getWhiteLabelUsers(pageNo);
                    handleClose();
                    setSelectedFile(null);
                    message.success(`Updated successfully`);
                    setLoading(false);
                    setData({});
                    form.resetFields();
                } else {
                    message.error(res.error);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err.message, 'Fetch Error');
                setLoading(false);
            }
        } else {
            setImageError('Logo is required');
        }
    };

    const addUser = async () => {
        const formValues = await userForm.validateFields();
        setLoading(true);
        const payload = {
            createdBy: email,
            email: email,
            password: formValues.password,
            userEmail: formValues.email,
            whitelabelRef: initialValues._id,
        };
        try {
            const res = await apiRequest('/create-admin-white-label', 'POST', payload);
            if (res.success) {
                handleClose();
                message.success(`User added successfully`);
                setLoading(false);
                setUserData({});
                userForm.resetFields();
            } else {
                message.error(res.error);
                setLoading(false);
            }
        } catch (err) {
            console.error(err.message, 'Fetch Error');
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                title={
                    <p className="sm:text-2xl text-lg sm:mt-1 sm:px-6 px-4 pt-4 font-semibold relative">
                        {modalType === 'edit' && 'Edit White Label'}
                        {modalType === 'add' && 'Create White Label'}
                        {modalType === 'addUser' && 'Add Account'}
                    </p>
                }
                open={open}
                destroyOnClose
                centered
                className="white-label-modal overflow-y-scroll hide-scrollbar"
                footer={
                    <div className="sm:p-6 p-4 border-t-2">
                        <ThemeButton
                            action={() => {
                                if (modalType === 'add') {
                                    createWhiteLabel();
                                } else if (modalType === 'edit') {
                                    updateWhiteLabel();
                                } else {
                                    addUser();
                                }
                            }}
                            dataE2e="button"
                            loading={loading}
                            type="primary"
                            shape="round"
                            text={(() => {
                                switch (modalType) {
                                    case 'edit':
                                        return 'Save changes';
                                    case 'add':
                                        return 'Create White Label';
                                    case 'addUser':
                                        return 'Create User';
                                    default:
                                        return '';
                                }
                            })()}
                            autoWidth={true}
                        />
                    </div>
                }
                onCancel={() => {
                    handleClose();
                    setSelectedFile(null);
                    form.resetFields();
                    userForm.resetFields();
                }}
                {...rest}>
                {modalType != 'addUser' ? (
                    <Form
                        form={form}
                        colon={false}
                        className="lg:mt-10 mt-5 lg:px-6 px-4 lg:pr-2 modal-form sm:h-[540px] h-[80vh] sm:max-h-[60vh] overflow-y-scroll overflow-hidden hide-scrollbar">
                        <FormInput
                            label="White Label Name:"
                            name="whitelableName"
                            placeholder="Enter white label name"
                            defaultValue={initialValues?.whitelableName}
                            rules={[
                                {
                                    required: true,
                                    message: 'White label name is required',
                                },
                            ]}
                        />
                        <FormInput
                            label="Domain URL:"
                            name="domainURL"
                            placeholder="Enter domain URL"
                            defaultValue={initialValues?.domainURL}
                            rules={[
                                {
                                    required: true,
                                    message: 'Domain URL is required',
                                },
                            ]}
                        />
                        <FormInput
                            label="Redirect URL:"
                            name="redirectURL"
                            placeholder="Enter redirect URL"
                            defaultValue={initialValues?.redirectURL}
                            rules={[
                                {
                                    required: true,
                                    message: 'Redirect URL is required',
                                },
                            ]}
                        />
                        <FormInput
                            label="Description:"
                            name="description"
                            placeholder="Enter description"
                            defaultValue={initialValues?.description}
                        />
                        <Divider />
                        <h2 className="text-sm lg:text-base font-normal mb-3 mt-6">Upload logo:</h2>

                        <Dragger
                            showUploadList={true}
                            maxCount={1}
                            name="file"
                            multiple={false}
                            beforeUpload={() => false}
                            onChange={handleFileChange}
                            className="block mb-4">
                            {selectedFile ? (
                                <div className="text-center">
                                    <img
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="Preview"
                                        className="img-fluid mx-auto mb-3 max-h-[200px]"
                                    />
                                    <p>{selectedFile.name}</p>
                                </div>
                            ) : initialValues?.logo ? (
                                <div className="text-center">
                                    <img
                                        src={initialValues?.logo}
                                        alt="Preview"
                                        className="img-fluid mx-auto mb-3 max-h-[200px]"
                                    />
                                </div>
                            ) : (
                                <div>
                                    <p className="mb-3">
                                        <CloudUploadOutlined className="text-lg bg-[#F4F4F5] rounded-3xl p-1" />
                                    </p>
                                    <div className="text-sm lg:text-base">
                                        <p className="ant-upload-text">
                                            Click to upload{' '}
                                            <span className="text-[#70707B]">or drag and drop</span>
                                        </p>
                                        <p className="ant-upload-hint">
                                            SVG, PNG, JPG or GIF (max. 800x400px)
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Dragger>
                        {imageError && !selectedFile && <p className="text-error">{imageError}</p>}

                        <FormInput
                            label="Logo width:"
                            name="logoWidth"
                            placeholder="Enter logo width"
                            defaultValue={initialValues?.logoWidth}
                        />
                        <FormInput
                            label="Primary color:"
                            name="primaryColor"
                            placeholder="Enter primary colour"
                            defaultValue={initialValues?.primaryColor}
                        />
                    </Form>
                ) : (
                    <div>
                        <Form
                            form={userForm}
                            colon={false}
                            className="lg:mt-10 mt-5 lg:px-6 px-4 lg:pr-2 modal-form overflow-y-scroll overflow-hidden hide-scrollbar">
                            <FormInput
                                label="Account Email:"
                                name="email"
                                dataE2e="enter-user-email"
                                placeholder="Enter user email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                ]}
                            />
                            <FormPassword
                                label="Password:"
                                name="password"
                                placeholder="Password"
                                dataE2e="password-field"
                                rules={[{ required: true, message: 'Please enter a password' }]}
                            />
                            <FormPassword
                                label="Confirm Password:"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                dataE2e="confirm-password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('The two passwords do not match'),
                                            );
                                        },
                                    }),
                                ]}
                            />
                        </Form>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default WhiteLabelModal;
