import React, { useState } from 'react';
import DrawerFilters from '../DrawerFilters';
import ThemeButton from '../common/ThemeButton';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import { Form, Select, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ChevronBottomIcon } from '../../utils/Images';
import { disableFutureDates } from '../../utils/common';
import moment from 'moment';
import { setDashboardFilters } from '../../redux/actions/dashboardFiltersAction';
import { getDate } from '../../utils/common';

const dateFormat = 'DD.MM.YYYY';
const { RangePicker } = DatePicker;

const DashboardFilter = ({ filterModal, setFilterModal }) => {
    const now = moment();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const dashboardFilters = useSelector(state => state.dashboardFilters);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [formValue, setFormValue] = useState({
        accountType: dashboardFilters?.accountType || null,
        dateRange: ['', ''],
        selectedDateRange: null,
    });

    const handleResetValue = () => {
        dispatch(
            setDashboardFilters({
                date: ['1D'],
                startDate: getDate('1D'),
                endDate: now.format('YYYY-MM-DDTHH:mm:ssZ'),
                accountType: null,
                selectedDateRange: null,
            }),
        );
        setFormValue({
            accountType: null,
            dateRange: ['', ''],
            selectedDateRange: null,
        });
        form.resetFields();
    };

    const handleOnApply = () => {
        const dateRange = formValue?.dateRange || [];
        const [start, end] = dateRange.length
            ? dateRange.map(dateString =>
                  moment(dateString, dateFormat, true).isValid()
                      ? moment(dateString, dateFormat).format('YYYY-MM-DDTHH:mm:ssZ')
                      : undefined,
              )
            : [undefined, undefined];

        dispatch(
            setDashboardFilters({
                date: dateRange.length && start && end ? ['range'] : dashboardFilters.date,
                startDate: start
                    ? moment(start).startOf('day').format('YYYY-MM-DD 00:00')
                    : dashboardFilters.startDate,
                endDate: end
                    ? moment(end).endOf('day').format('YYYY-MM-DD 23:59')
                    : dashboardFilters.endDate,
                accountType: formValue?.accountType || null,
                selectedDateRange: formValue.selectedDateRange || null,
            }),
        );
    };

    function DashboardFilterButtons() {
        return (
            <div className="py-[8px] sm:py-[16px] grid grid-cols-[auto_1fr] lg:mt-6 sm:flex sm:justify-end sm:items-center gap-2">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={handleResetValue}
                    text="Reset"
                    autoWidth={true}
                    className="reset-filter-btn"
                />
                <ThemeButton
                    icon={<SearchOutlined />}
                    type="primary"
                    autoWidth={true}
                    action={() => {
                        handleOnApply();
                        setFilterModal(false);
                    }}
                    text="Apply"
                />
            </div>
        );
    }

    return (
        <div>
            <DrawerFilters
                open={filterModal}
                title="Filters"
                onClose={() => setFilterModal(false)}
                form={form}
                footer={<DashboardFilterButtons />}>
                <Form.Item label={'Account Type'} name={'accountType'} className="w-full">
                    <div className="w-full custom-select">
                        <Select
                            suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                            dropdownRender={menu => <>{menu}</>}
                            placeholder="Select Account Type"
                            defaultValue={dashboardFilters?.accountType}
                            value={formValue.accountType}
                            onChange={value =>
                                setFormValue(prevState => ({
                                    ...prevState,
                                    accountType: value,
                                }))
                            }
                            options={[
                                { label: 'All', value: 'all' },
                                { label: 'Personal', value: 'Personal' },
                                { label: 'Buisness', value: 'Business' },
                            ]}
                            className="w-full custom-select"
                        />
                    </div>
                </Form.Item>
                <Form.Item label="Date" name="dateUpdated">
                    <RangePicker
                        defaultValue={dashboardFilters?.selectedDateRange}
                        value={formValue?.selectedDateRange}
                        format={dateFormat}
                        variant="outlined"
                        suffixIcon={
                            <img
                                alt="calendar"
                                src={`${imageBaseUrl}/adminUi/calenderIcon.svg`}
                                className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                            />
                        }
                        className={`p-[6px] w-full h-full filter-range-picker-selected`}
                        popupClassName="calender-range-picker"
                        disabledDate={disableFutureDates}
                        onChange={(dates, dateStrings) => {
                            setFormValue(prevState => ({
                                ...prevState,
                                dateRange: dateStrings,
                                selectedDateRange: dates,
                            }));
                        }}
                        allowClear={true}
                    />
                </Form.Item>
            </DrawerFilters>
        </div>
    );
};

export default DashboardFilter;
